import { Dispatch } from "redux";
import { UserActionTypes } from "../types/userTypes";
import { Site } from "../../API/_generated";
import client from "../../API";

export function getMySites(dispatch: Dispatch) {
  return new Promise<Site[]>((resolve, reject) => {
    client()
      .mySites()
      .then((sites) => {
        dispatch({ type: UserActionTypes.GET_SITES, payload: sites });
        resolve(sites);
      }, reject);
  });
}
