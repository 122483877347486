import { Dispatch } from "redux";
import client from "../../API";
import { AdminActionTypes } from "../types/adminTypes";
import { SiteCreate } from "../../API/_generated";

export const getSitesOfUser = (dispatch: Dispatch, id: string) => {
  client()
    .sitesOfUser(id)
    .then((sites) => {
      dispatch({ type: AdminActionTypes.GET_SITES, payload: { [id]: sites } });
    });
};

export const addSiteToUser = (
  dispatch: Dispatch,
  userId: string,
  site: SiteCreate
) => {
  client()
    .addSiteToUser(userId, site)
    .then(() => {
      getSitesOfUser(dispatch, userId);
    });
};
export const removeSiteFromUser = (
  dispatch: Dispatch,
  userId: string,
  siteId: string
) => {
  client()
    .removeSiteFromUser(siteId)
    .then(() => {
      getSitesOfUser(dispatch, userId);
    });
};
