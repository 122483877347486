import React, { useCallback, useContext, useState } from "react";
import { Button, Col, Form, Row, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { DataSeries } from "../../API/_generated";
import { STD_GUTTER } from "../../Util/constants";
import { AdminDetailsContext } from "../../Util/types";
import { id, seriesWithoutCategoryName } from "../../Util/utils";

export declare type SeriesCategory = {
  cat: string;
  series: DataSeries[];
};

export const AdminUserSeries: React.FC<{
  series: DataSeries;
  canChange: DataSeries | undefined;
  index: number;
  deleteSeries: (index: number) => void;
  changeSeries: (index: number) => void;
}> = ({ series, index, deleteSeries, changeSeries, canChange }) => {
  const { t } = useTranslation();
  const onChangeSeries = useCallback(() => {
    changeSeries(index);
  }, [changeSeries, index]);
  const onDelSeries = useCallback(() => {
    deleteSeries(index);
  }, [deleteSeries, index]);
  return (
    <>
      <Col xs={4} className="details-category-spacer" />
      <Col xs={8}>{seriesWithoutCategoryName(series)}</Col>
      <Col xs={8}>
        <Button onClick={onChangeSeries} disabled={!canChange}>
          {`${t("Change to")} ${canChange ? t(canChange.name) : ""}`}
        </Button>
      </Col>
      <Col xs={4}>
        <Button onClick={onDelSeries} type="link">
          {t("Delete Series")}
        </Button>
      </Col>
    </>
  );
};

export const AdminUserSeriesCategory: React.FC<{
  cat: SeriesCategory;
  changeSeries: (cat: string, series: string, index: number) => void;
  addSeries: (cat: string, series: string) => void;
  delSeries: (cat: string, index: number) => void;
}> = ({ cat, addSeries, changeSeries, delSeries }) => {
  const { availSeries } = useContext(AdminDetailsContext);
  const { t } = useTranslation();
  const [selectedSeries, setSelectedSeries] = useState<DataSeries>();
  const onChangeSelectedSeries = useCallback(
    (e: string) => {
      setSelectedSeries(availSeries.find((a) => id(a.series) === e)?.series);
    },
    [availSeries]
  );
  const onAddSeries = useCallback(() => {
    if (selectedSeries) {
      addSeries(cat.cat, id(selectedSeries));
    }
  }, [addSeries, cat.cat, selectedSeries]);
  const onChangeSeries = useCallback(
    (index: number) => {
      if (selectedSeries) {
        changeSeries(cat.cat, id(selectedSeries), index);
      }
    },
    [cat.cat, changeSeries, selectedSeries]
  );
  const onDelSeries = useCallback(
    (index: number) => {
      delSeries(cat.cat, index);
    },
    [cat.cat, delSeries]
  );
  return (
    <Col xs={24} className="details-category">
      <Row gutter={STD_GUTTER}>
        <Col xs={6}>
          <Typography.Title level={3}>{t(cat.cat)}</Typography.Title>
        </Col>
        <Col xs={12}>
          <Form.Item label={t("Select Series")}>
            <Select onChange={onChangeSelectedSeries}>
              <Select.Option value="-1">
                {t("Choose to add series")}
              </Select.Option>
              {availSeries.map((a) => (
                <Select.Option
                  value={id(a.series)}
                  key={id(a.series)}
                >{`${a.site} -> ${a.card} -> ${a.series.name} [${a.series.unit}]`}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={6}>
          <Button
            type="primary"
            disabled={!selectedSeries}
            onClick={onAddSeries}
          >
            {t("Add Series")}
          </Button>
        </Col>
        {cat.series.map((s, i) => (
          <AdminUserSeries
            key={`s-row${id(s)}`}
            series={s}
            changeSeries={onChangeSeries}
            deleteSeries={onDelSeries}
            canChange={selectedSeries}
            index={i}
          />
        ))}
      </Row>
    </Col>
  );
};
