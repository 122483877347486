import { notification } from "antd";
import { Dispatch } from "redux";
import { UserActionTypes } from "../types/userTypes";
import {
  ApiError,
  Credentials,
  PasswordChange,
  User,
  UserPatch,
} from "../../API/_generated";
import { fetchError } from "./helper";
import client from "../../API";

export function getClient(dispatch: Dispatch) {
  return new Promise<User>((resolve, reject) => {
    try {
      client()
        .me()
        .then(
          (user) => {
            dispatch({ type: UserActionTypes.LOGIN, payload: user });
            resolve(user);
          },
          (err) => {
            fetchError(err, UserActionTypes.LOGIN_FAILED, dispatch);
            reject(err);
          }
        );
    } catch (e) {
      fetchError(e as ApiError, UserActionTypes.LOGIN_FAILED, dispatch);
    }
  });
}

export function clientLogin(dispatch: Dispatch, payload: Credentials) {
  try {
    client()
      .login(payload)
      .then(
        (user) => {
          localStorage.setItem("enexpertUserEmail", user.email);
          localStorage.setItem("userId", user._id ?? "-1");
          notification.success({
            message: "loginSuccess",
            duration: 2,
          });
          dispatch({
            type: UserActionTypes.LOGIN,
            payload: user,
          });
          return user;
        },
        (err) => {
          notification.error({
            message: "Credentials wrong",
            duration: 5,
          });
          fetchError(err, UserActionTypes.LOGIN_FAILED, dispatch);
        }
      );
  } catch (e: any) {
    fetchError(e, UserActionTypes.LOGIN_FAILED, dispatch);
  }
}

export function changePw(dispatch: Dispatch, body: PasswordChange) {
  return new Promise<boolean>((resolve, reject) => {
    client()
      .changePw(body)
      .then(
        () => {
          resolve(true);
        },
        (err) => {
          fetchError(err, UserActionTypes.LOGIN_FAILED, dispatch);
          reject(err);
        }
      );
  });
}

export function patchMe(dispatch: Dispatch, user: UserPatch) {
  client()
    .patchMe(user)
    .then(
      (newUser) => {
        dispatch({ type: UserActionTypes.LOGIN, payload: newUser });
      },
      (err) => {
        fetchError(err, UserActionTypes.PATCH_FAILED, dispatch);
      }
    );
}
