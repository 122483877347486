import { Dispatch } from "redux";
import { CardCreate, SiteUpdate } from "../../API/_generated";
import client from "../../API";
import { getSitesOfUser } from "./adminSidebarActions";
import { id } from "../../Util/utils";
import { fetchError } from "./helper";
import { AdminActionTypes } from "../types/adminTypes";

export const saveCard = (
  dispatch: Dispatch,
  userId: string,
  siteId: string,
  card: CardCreate
) => {
  client()
    .saveCard(siteId, card)
    .then((s) => {
      getSitesOfUser(dispatch, userId);
    });
};
/*
export const updateCard = (
  dispatch: Dispatch,
  userId: string,
  cardId: string,
  card: CardUpdate
) => {
  card.graphs?.forEach((graph, index) => {
    if (card.graphs) {
      if (id(graph) === "-1") {
        card.graphs[index]._id = undefined;
      }
      graph.series.forEach((series, innerIndex) => {
        if (card.graphs) {
          if (id(series) === "-1") {
            card.graphs[index].series[innerIndex]._id = undefined;
          }
        }
      });
    }
  });
  client()
    .updateCard(cardId, card)
    .then(() => {
      getSitesOfUser(dispatch, userId);
    });
};
*/

export function updateSite(
  dispatch: Dispatch,
  userId: string,
  siteId: string,
  body: SiteUpdate
) {
  body.cards?.forEach((card) => {
    if (id(card) === "-1") card._id = undefined;
    card.graphs?.forEach((graph) => {
      if (id(graph) === "-1") {
        graph._id = undefined;
      }
      graph.series.forEach((series) => {
        if (id(series) === "-1") {
          series._id = undefined;
        }
      });
    });
  });
  client()
    .updateSite(siteId, body)
    .then(
      (site) => {
        dispatch({
          type: AdminActionTypes.PATCH_SITE,
          payload: { userId, site },
        });
      },
      (err) => {
        fetchError(err, AdminActionTypes.GET_FAILED, dispatch);
      }
    );
}
