import React, { useCallback, useState } from "react";
import { Button, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Card } from "../../API/_generated";
import CardView from "../cards/CardView";
import { STD_GUTTER } from "../../Util/constants";
import { RootState } from "../../redux/store";

const AdminGraphPreview: React.FC<{ card: Card }> = ({ card }) => {
  const { t } = useTranslation();
  /*  const [selectedDropdown, setSelectedDropdown] = useState<Dropdown>(
    dropdown[0] ?? DEFAULT_DROPDOWN
  );
  const fullDay = useMemo(
    () => selectedDropdown.start === LAST_FULL_DAY,
    [selectedDropdown.start]
  );
  const series = useMemo<DataSeriesResponse[]>(() => {
    const ser =
      graph.series.length > 0
        ? graph.series
        : [
            {
              name: "Preview",
              unit: UnitEnum.KWH,
            },
          ];
    console.log(ser);
    console.log(ser.length);
    return ser.map((s) => {
      const now = new Date();
      if (fullDay) {
        now.setHours(0, 0, 0);
      } else {
        alterTime(now, selectedDropdown.start as RangeEnum, true);
      }
      let numP = numPoints(
        selectedDropdown.start,
        selectedDropdown.granularity,
        s.groupBy
      );
      if (numP === 0) numP = 1;
      const numpArray = Array(numP).fill(0);
      console.log(numpArray);
      return {
        ...s,
        dropdown: "hi",
        fetchDate: now.getTime(),
        data: [
          {
            name: "Preview",
            response: numpArray.map(() => {
              alterTime(now, selectedDropdown.granularity);
              return {
                value: Math.random() * 10,
                timestamp: now.toISOString(),
              };
            }),
          },
        ],
      };
    });
  }, [selectedDropdown, fullDay, graph.series]);
  if (graph.type === "twoValPercent") {
    if (graph.sub_type === "circle")
      return <TwoValuePercent circled series={series} />;
    return <TwoValuePercent series={series} />;
  }
  if (graph.type === "line") {
    if (graph.sub_type === "area")
      return (
        <AntdStacked
          series={series}
          start={selectedDropdown.start}
          stop={selectedDropdown.stop}
          granularity={selectedDropdown.granularity}
          customConfig={graph.config}
          seriesSameLength={graph.seriesSameLength}
        />
      );
    return (
      <AntdLine
        series={series}
        start={selectedDropdown.start}
        stop={selectedDropdown.stop}
        granularity={selectedDropdown.granularity}
        customConfig={graph.config}
        seriesSameLength={graph.seriesSameLength}
      />
    );
  }
  if (graph.type === "doughnut") {
    return <AntdDonut series={series} />;
  }
  if (graph.type === "heatmap") {
    return (
      <CustomHeatmap
        series={series}
        start={selectedDropdown.start}
        stop={selectedDropdown.stop}
        granularity={selectedDropdown.granularity}
        customConfig={graph.config}
      />
    );
  }
  if (graph.type === "bar") {
    if (graph.sub_type === "horizontal")
      return (
        <AntdBar
          series={series}
          start={selectedDropdown.start}
          stop={selectedDropdown.stop}
          granularity={selectedDropdown.granularity}
          customConfig={graph.config}
          seriesSameLength={graph.seriesSameLength}
        />
      );
    return (
      <AntdColumn
        series={series}
        start={selectedDropdown.start}
        stop={selectedDropdown.stop}
        granularity={selectedDropdown.granularity}
        customConfig={graph.config}
        seriesSameLength={graph.seriesSameLength}
      />
    );
  }
  if (graph.type === "dualAxes")
    return (
      <AntdDualAxis
        series={series}
        start={selectedDropdown.start}
        stop={selectedDropdown.stop}
        granularity={selectedDropdown.granularity}
        customConfig={graph.config}
      />
    );
*/

  const { data } = useSelector<RootState, RootState["influxDataReducer"]>(
    (state) => state.influxDataReducer
  );

  const [loadPreview, setLoadPreview] = useState(false);
  const togglePreview = useCallback(() => {
    setLoadPreview((p) => !p);
  }, []);
  if (!loadPreview)
    return (
      <Button color="primary" onClick={togglePreview}>
        {t("Load Preview")}
      </Button>
    );
  return (
    <Row gutter={STD_GUTTER}>
      <Col xs={24}>
        <CardView card={card} />
      </Col>
      <Col xs={24}>
        <pre>{JSON.stringify(data, null, 2)}</pre>
      </Col>
    </Row>
  );
};

export default AdminGraphPreview;
