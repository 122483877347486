import {
  Button,
  Col,
  DatePicker,
  Radio,
  RadioChangeEvent,
  Row,
  TreeSelect,
  Typography,
} from "antd";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment, { Moment } from "moment";
import { SearchOutlined } from "@ant-design/icons";
import Mainlayout from "../Layout/MainLayout";
import { Card, DataSeries, Dropdown, RangeEnum } from "../API/_generated";
import { RootState } from "../redux/store";
import { categoryName, id, seriesWithoutCategoryName } from "../Util/utils";
import { getMultipleData } from "../redux";
import { AGGREGATE_DROPDOWN_OPTIONS, STD_GUTTER } from "../Util/constants";
import "../Styles/pages/Compare.css";
import CardView from "../Components/cards/CardView";

type TreeElem = {
  value: string;
  title: string;
};
type TreeNode2 = TreeElem & {
  children: TreeElem[];
};
type TreeNode = TreeElem & {
  children: TreeNode2[];
};

function determineOffsetRangeEnum(start: moment.Moment, end: moment.Moment) {
  const minutes = end.diff(start) / 60000;
  if (minutes < 2) return RangeEnum._1M;
  if (minutes < 3) return RangeEnum._2M;
  if (minutes < 6) return RangeEnum._5M;
  if (minutes < 20) return RangeEnum._15M;
  if (minutes < 40) return RangeEnum._30M;
  if (minutes < 70) return RangeEnum._1H;
  if (minutes < 130) return RangeEnum._2H;
  if (minutes < 250) return RangeEnum._4H;
  if (minutes < 370) return RangeEnum._6H;
  if (minutes < 730) return RangeEnum._12H;
  if (minutes < 1500) return RangeEnum._1D;
  if (minutes < 4500) return RangeEnum._3D;
  if (minutes < 11000) return RangeEnum._7D;
  if (minutes < 44000) return RangeEnum._30D;
  if (minutes < 131000) return RangeEnum._90D;
  return RangeEnum._365D;
}

const Compare: React.FC = () => {
  const [granularity, setGranularity] = useState<RangeEnum>(RangeEnum._1H);

  const { me, colors } = useSelector<RootState, RootState["userReducer"]>(
    (state) => state.userReducer
  );
  const mySeries = useMemo(() => me?.series ?? [], [me]);

  const { data, status } = useSelector<
    RootState,
    RootState["influxDataReducer"]
  >((state) => state.influxDataReducer);
  const [channels, setChannels] = useState<DataSeries[]>([]);

  const [start, setStart] = useState<Moment>(moment().subtract(2, "days"));
  const [end, setEnd] = useState<Moment>(moment());
  const [loading, setLoading] = useState(false);
  const startRangeEnum = useMemo(
    () => determineOffsetRangeEnum(start, end),
    [start, end]
  );
  const calculatedDropdown = useMemo<Dropdown>(
    () => ({
      start: start.toISOString(),
      stop: end.toISOString(),
      label: "",
      granularity,
      standard: false,
    }),
    [start, end, granularity]
  );
  /* const selectedData = useMemo(() => {
    const selected: DataSeriesResponse[] = [];
    channels.forEach((s) => {
      const found: DataSeriesResponse = {
        ...s,
        data: [],
      };
      s.data?.forEach((iData) => {
        const hash = hashInfluxData(
          iData,
          calculatedDropdown,
          s.customWindow,
          undefined
        );
        if (hash in data && inTime(data[hash])) {
          found.data.push({
            ...iData,
            response: data[hash].value,
          });
        }
      });
    });
    return selected;
  }, [calculatedDropdown, channels, data]);*/
  const dispatch = useDispatch();
  const getData = useCallback(() => {
    setLoading(true);
    getMultipleData(dispatch, channels, calculatedDropdown, []).then(() => {
      setLoading(false);
    });
  }, [calculatedDropdown, channels, dispatch]);
  const { t } = useTranslation();

  const treeData = useMemo(
    () =>
      mySeries.reduce<TreeNode[]>((prev, current) => {
        const cat = t(categoryName(current));
        const idx = prev.findIndex((p) => p.value === current.unit);
        if (idx !== -1) {
          const idx2 = prev[idx].children.findIndex((p) => p.value === cat);
          if (idx2 !== -1) {
            prev[idx].children[idx2].children.push({
              value: id(current),
              title: t(seriesWithoutCategoryName(current)),
            });
          } else {
            prev[idx].children.push({
              value: cat,
              title: cat,
              children: [
                {
                  value: id(current),
                  title: t(seriesWithoutCategoryName(current)),
                },
              ],
            });
          }
        } else {
          prev.push({
            value: current.unit,
            title: t(current.unit),
            children: [
              {
                value: cat,
                title: cat,
                children: [
                  {
                    value: id(current),
                    title: t(seriesWithoutCategoryName(current)),
                  },
                ],
              },
            ],
          });
        }
        return prev;
      }, []),
    [mySeries, t]
  );
  const value = useMemo<string[]>(() => channels.map((c) => id(c)), [channels]);

  const onChange = useCallback(
    (values: string[]) => {
      setChannels(
        mySeries
          .filter((s) => values.indexOf(id(s)) !== -1)
          .map((s) => ({
            ...s,
            name: seriesWithoutCategoryName(s),
            groupBy: undefined,
            availableGroupBy: undefined,
          }))
      );
    },
    [mySeries]
  );
  const onEndDateChange = useCallback((m: Moment | null) => {
    if (m) {
      setEnd(m);
    }
  }, []);
  const onStartDateChange = useCallback((m: Moment | null) => {
    if (m) {
      setStart(m);
    }
  }, []);
  const onChangeGranularity = useCallback((e: RadioChangeEvent) => {
    setGranularity(e.target.value as RangeEnum);
  }, []);
  const customCard = useMemo<Card>(
    () => ({
      title: "",
      _id: "custom",
      index: 0,
      widthSM: 24,
      widthLG: 24,
      hidden: false,
      dropdown: [
        {
          start: start.toISOString(),
          stop: end.toISOString(),
          label: "",
          granularity,
          standard: true,
        },
      ],
      graphs: [
        {
          type: "line",
          series: channels,
          _id: "customgraph",
          sub_type: "",
          seriesSameLength: true,
          config: JSON.stringify({
            smooth: true,
            gridLines: true,
            slider: true,
          }),
        },
      ],
    }),
    [channels, end, granularity, start]
  );
  return (
    <Mainlayout active="details">
      <div className="compare-container">
        <Typography.Title
          className="title"
          level={5}
          style={{ fontSize: "18px", fontWeight: 600 }}
        >
          {t("Details")}
        </Typography.Title>
        <Typography.Title
          className="sub-title"
          level={4}
          style={{ fontSize: "14px", fontWeight: 400 }}
        >
          {t("Select to compare charts")}
        </Typography.Title>
        <div className="search-boxes">
          <Row gutter={STD_GUTTER}>
            <Col xs={24} md={12}>
              <TreeSelect
                multiple
                showSearch
                maxTagCount={1}
                treeIcon={false}
                value={value}
                placeholder={t("Please select")}
                allowClear
                treeDefaultExpandAll
                onChange={onChange}
                treeData={treeData}
              />
            </Col>
            <Col xs={24} md={12}>
              <div className="date-range">
                <DatePicker
                  placeholder="Start Date"
                  onChange={onStartDateChange}
                  value={moment(start, "YYYY-MM-DD")}
                  format="YYYY-MM-DD"
                />
                <DatePicker
                  placeholder="End Date"
                  onChange={onEndDateChange}
                  value={moment(end, "YYYY-MM-DD")}
                  format="YYYY-MM-DD"
                />
                <Button
                  type="primary"
                  icon={<SearchOutlined />}
                  loading={loading}
                  onClick={getData}
                  shape="circle"
                  disabled={value.length === 0 || end.diff(start) < 0}
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="graph-filter">
          <div className="scale">
            {channels.length > 0 &&
              channels.map((itm, index) => (
                <div className="scale-flex" key={id(itm)}>
                  <div
                    className="suana"
                    style={{
                      border: `3px solid ${colors[index % colors.length]}`,
                    }}
                  />
                  <p>{t(itm.name)}</p>
                </div>
              ))}
          </div>
          <div className="filter-period">
            <Radio.Group
              options={AGGREGATE_DROPDOWN_OPTIONS}
              onChange={onChangeGranularity}
              defaultValue={granularity}
            />
          </div>
        </div>
        <div className="compare-chart">
          <CardView card={customCard} />
          {/* selectedData.length > 0 ? (
            <div className="comp_chart" style={{ position: "relative" }}>
              <AntdLine
                series={selectedData}
                granularity={granularity}
                start={startRangeEnum}
              />
            </div>
          ) (

          ) : !loading ? (
            <div style={{ height: "400px" }}>
              <Empty />
            </div>
          ) : (
            <div className="loading" style={{ height: "400px" }}>
              <Loader />
            </div>
          )  */}
        </div>
      </div>
    </Mainlayout>
  );
};
export default Compare;
