import React, { ChangeEvent, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  RadioChangeEvent,
  Row,
} from "antd";
import { Filter, InfluxData, InterpolationEnum } from "../../API/_generated";
import Loader from "../Loader";
import AdminInfluxFilter from "./AdminInfluxFilter";
import { INTERPOLATION_OPTIONS, STD_GUTTER } from "../../Util/constants";
import { sameInfluxData } from "../../Util/utils";

const AdminInfluxData: React.FC<{
  data: InfluxData;
  onSave: (data: InfluxData, index: number) => void;
  onDelete: (index: number) => void;
  index: number;
  bucketsOfUser: string[];
}> = ({ data, bucketsOfUser, onSave, onDelete, index }) => {
  const [formData, setFormData] = useState<InfluxData>(data);

  const onClickSave = useCallback(() => {
    onSave(formData, index);
  }, [onSave, formData, index]);
  const { t } = useTranslation();
  const onChangeBucket = useCallback((e: RadioChangeEvent) => {
    setFormData((fd) => ({ ...fd, bucket: e.target.value }));
  }, []);
  const onAddFilter = useCallback(() => {
    const f: Filter = {
      value: "",
      key: "",
    };
    setFormData((fd) => ({
      ...fd,
      filters: fd.filters ? fd.filters.concat([f]) : [f],
    }));
  }, []);
  const onChangeFilter = useCallback((f: Filter, idx: number) => {
    setFormData((fd) => ({
      ...fd,
      filters: fd.filters
        ? fd.filters.map((old, i) => (i === idx ? f : old))
        : [f],
    }));
  }, []);
  const onDeleteFilter = useCallback((idx: number) => {
    setFormData((fd) => ({
      ...fd,
      filters: fd.filters ? fd.filters.filter((old, i) => i !== idx) : [],
    }));
  }, []);
  const onChangeInterpolation = useCallback((e: RadioChangeEvent) => {
    setFormData((fd) => ({
      ...fd,
      interpolation: e.target.value as InterpolationEnum,
    }));
  }, []);
  const onChangeFunction = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFormData((fd) => ({ ...fd, function: e.target.value }));
  }, []);
  const handleDelete = useCallback(() => {
    if (data) onDelete(index);
  }, [onDelete, data, index]);
  const dataChanged = useMemo(
    () => !sameInfluxData(data, formData),
    [data, formData]
  );
  return (
    <Row gutter={STD_GUTTER}>
      <Col xs={24}>
        <div className="card-container">
          <Form layout="vertical">
            <Divider orientation="left">{t("Bucket")}</Divider>
            {bucketsOfUser ? (
              <Radio.Group
                value={formData.bucket}
                id="bucket"
                onChange={onChangeBucket}
              >
                <Radio.Button onChange={onChangeBucket} value="">
                  {t("none")}
                </Radio.Button>
                {bucketsOfUser.map((b) => (
                  <Radio.Button onChange={onChangeBucket} value={b} key={b}>
                    {b}
                  </Radio.Button>
                ))}
              </Radio.Group>
            ) : (
              <Loader />
            )}
            <Divider orientation="left">{t("Function")}</Divider>
            <Row gutter={STD_GUTTER} justify="space-between">
              <Col xs={11}>
                <Form.Item label={t("Function")}>
                  <Input
                    onChange={onChangeFunction}
                    value={formData.function}
                    name="function"
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label={t("Interpolation")}>
                  <Radio.Group
                    options={INTERPOLATION_OPTIONS}
                    onChange={onChangeInterpolation}
                    defaultValue={
                      formData.interpolation ?? InterpolationEnum.NONE
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>

          <Divider orientation="left">{t("Filters")}</Divider>
          <Row gutter={STD_GUTTER} justify="space-evenly">
            {!!formData.filters &&
              formData.filters.map((f, i) => {
                const key = `${i}`;
                return (
                  <Col
                    className="select-filter"
                    key={`${index}-${f.key}-${f.value}-${key}`}
                  >
                    <AdminInfluxFilter
                      onChange={onChangeFilter}
                      onDelete={onDeleteFilter}
                      bucket={formData.bucket ?? ""}
                      selected={f}
                      index={i}
                    />
                  </Col>
                );
              })}
            <Col className="select-filter-add">
              <Button type="primary" onClick={onAddFilter}>
                {t("Add Filter")}
              </Button>
            </Col>
          </Row>
          <Row gutter={STD_GUTTER} justify="space-between" align="bottom">
            <Col>
              <Button type="link" onClick={handleDelete}>
                {t("Delete Series")}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                disabled={!dataChanged}
                onClick={onClickSave}
              >
                {t("Save Influx Data")}
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default AdminInfluxData;
