import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { Card } from "../../API/_generated";
import { DEFAULT_DROPDOWN, STD_GUTTER } from "../../Util/constants";
import { AdminSiteContext } from "../../Util/types";
import { updateSite } from "../../redux";
import { id } from "../../Util/utils";

type SaveFunc<T extends keyof Card> = (prop?: T, val?: Card[T]) => void;

const AdminCardSmall: React.FC<{
  cardId: string;
  onEdit?: (data: string) => void;
}> = ({ cardId, onEdit }) => {
  const { t } = useTranslation();
  const { site, userId } = useContext(AdminSiteContext);
  const oldCards = useMemo(() => site.cards ?? [], [site]);
  const card = useMemo(
    () => oldCards.find((c) => id(c) === cardId),
    [cardId, oldCards]
  );
  const handleEdit = useCallback(() => {
    if (onEdit) onEdit(cardId);
  }, [cardId, onEdit]);
  const dispatch = useDispatch();
  const [title, setTitle] = useState<string>(card?.title ?? t("New Card"));
  const hidden = useMemo(() => card?.hidden ?? false, [card]);
  const onSave = useCallback<SaveFunc<keyof Card>>(
    (prop, val) => {
      let newCards: Card[] = [];
      if (prop && val) {
        if (card) {
          newCards = oldCards.map((c) =>
            id(c) === id(card)
              ? {
                  ...c,
                  [prop]: val,
                }
              : c
          );
        }
      } else {
        newCards = oldCards.concat({
          title,
          index: oldCards.length,
          graphs: [],
          dropdown: [DEFAULT_DROPDOWN],
          widthSM: 24,
          widthLG: 24,
          hidden,
        });
      }
      updateSite(dispatch, userId, id(site), {
        ...site,
        cards: newCards,
      });
    },
    [card, dispatch, hidden, oldCards, site, title, userId]
  );
  const handleAdd = useCallback(() => {
    onSave();
  }, [onSave]);
  const handleDelete = useCallback(() => {
    if (card) {
      updateSite(dispatch, userId, id(site), {
        ...site,
        cards: oldCards.filter((c) => id(c) !== id(card)),
      });
    }
  }, [card, dispatch, oldCards, site, userId]);
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }, []);
  const onToggleHidden = useCallback(() => {
    if (card) {
      onSave("hidden", !hidden);
    }
  }, [card, hidden, onSave]);
  const decrIndex = useCallback(() => {
    if (card) {
      onSave("index", card.index - 1);
    }
  }, [card, onSave]);
  const incrIndex = useCallback(() => {
    if (card) {
      onSave("index", card.index + 1);
    }
  }, [card, onSave]);
  const handleChangeWidthSM = useCallback(
    (value: number) => {
      if (card) onSave("widthSM", value);
    },
    [card, onSave]
  );
  const handleChangeWidthLG = useCallback(
    (value: number) => {
      if (card) onSave("widthLG", value);
    },
    [card, onSave]
  );
  const lg = useMemo(() => card?.widthLG ?? 24, [card]);
  const xs = useMemo(() => card?.widthSM ?? 24, [card]);
  const lgTitle = useMemo(() => (lg > 12 ? 8 : 16), [lg]);
  const xsTitle = useMemo(() => (lg > 12 ? 16 : 24), [lg]);
  const lgOther = useMemo(() => (lg > 12 ? 4 : 8), [lg]);
  const xsOther = useMemo(() => (lg > 12 ? 8 : 12), [lg]);
  return (
    <Col xs={xs} lg={lg} className="card-col">
      <div className="card-container">
        <Form layout="vertical">
          <Row gutter={STD_GUTTER}>
            <Col xs={xsTitle} lg={lgTitle}>
              <Form.Item label={t("Title")}>
                {card ? (
                  <span>{title}</span>
                ) : (
                  <Input name="title" value={title} onChange={handleChange} />
                )}
              </Form.Item>
            </Col>

            {card && !hidden ? (
              <Col xs={xsOther} lg={lgOther}>
                <Form.Item label={`${t("Index")}: ${card.index + 1}`}>
                  <Button onClick={decrIndex} disabled={card.index === 0}>
                    <MinusOutlined />
                  </Button>
                  <Button
                    onClick={incrIndex}
                    disabled={card.index >= oldCards.length}
                  >
                    <PlusOutlined />
                  </Button>
                </Form.Item>
              </Col>
            ) : (
              <Col xs={xsOther} lg={lgOther} />
            )}
            {card && (
              <>
                <Col xs={xsOther} lg={lgOther}>
                  <Form.Item label={t("Width Mobile")}>
                    <Select
                      value={card.widthSM}
                      id="widthSM"
                      key="widthSM"
                      onChange={handleChangeWidthSM}
                    >
                      <Select.Option value={8}>{t("Small")}</Select.Option>
                      <Select.Option value={16}>{t("Large")}</Select.Option>
                      <Select.Option value={12}>{t("Half")}</Select.Option>
                      <Select.Option value={24}>{t("Full")}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={xsOther} lg={lgOther}>
                  <Form.Item label={t("Width Desktop")}>
                    <Select
                      value={card.widthLG}
                      id="widthLG"
                      key="widthLG"
                      onChange={handleChangeWidthLG}
                    >
                      <Select.Option value={8}>{t("Small")}</Select.Option>
                      <Select.Option value={16}>{t("Large")}</Select.Option>
                      <Select.Option value={12}>{t("Half")}</Select.Option>
                      <Select.Option value={24}>{t("Full")}</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
            <Col xs={xsOther} lg={lgOther}>
              <Form.Item label={t("Hidden (onHover)")}>
                <Checkbox checked={hidden} onChange={onToggleHidden} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Row gutter={STD_GUTTER} justify="space-between">
          <Col>
            {card && (
              <Button type="link" onClick={handleDelete}>
                {t("Delete Card")}
              </Button>
            )}
          </Col>
          <Col>
            <Button type="ghost" onClick={card ? handleEdit : handleAdd}>
              {t(card ? "Edit Card" : "Save Card")}
            </Button>
          </Col>
        </Row>
      </div>
    </Col>
  );
};
AdminCardSmall.defaultProps = {
  onEdit: undefined,
};

export default AdminCardSmall;
