import { Avatar, Dropdown, Input, Layout, MenuProps } from "antd";
import { FiSearch } from "react-icons/fi";
import { TranslationOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MainMenu from "./MainMenu";
import { RootState } from "../redux/store";
import { LayoutProps } from "./types";
import { LanguageEnum } from "../API/_generated";
import { LOCAL_STORAGE_LANG } from "../Util/constants";

const logo = require("../Assets/logo2.png");

const marginLeft: React.CSSProperties = { marginLeft: 250 };
const posFixed: React.CSSProperties = { position: "fixed" };
const contentStyle: React.CSSProperties = {
  padding: "100px 40px 0 40px",
  minHeight: "100vh",
};

type SearchResult = {
  label: string;
  type: "graph" | "site" | "card";
  link: string;
};

const VerticalLayout: React.FC<PropsWithChildren<LayoutProps>> = ({
  children,
  active,
}) => {
  const { Header, Sider, Content } = Layout;
  const history = useHistory();
  const displayMask = useSelector<RootState, boolean>(
    (state: RootState) => state.userReducer.displayMask
  );
  const { me, sites } = useSelector<RootState, RootState["userReducer"]>(
    (state) => state.userReducer
  );
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const { t, i18n } = useTranslation();
  const onSearch = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const res: SearchResult[] = [];
      const q = e.key.toLowerCase();
      sites.forEach((s) => {
        if (t(s.title).toLowerCase().indexOf(q) !== -1) {
          res.push({ label: t(s.title), link: `/${s.icon}`, type: "site" });
        }
        s.cards?.forEach((c) => {
          if (t(c.title).toLowerCase().indexOf(q) !== -1) {
            res.push({ label: t(c.title), link: `/${s.icon}`, type: "card" });
          }
          c.graphs?.forEach((g) => {
            g.series?.forEach((ser) => {
              if (t(ser.name).toLowerCase().indexOf(q) !== -1) {
                res.push({
                  label: `${t(c.title)} ${t(ser.name)}`,
                  link: `/${s.icon}`,
                  type: "graph",
                });
              }
            });
          });
        });
      });
      setSearchResults(res);
    },
    [t, sites]
  );
  const onClickUsername = useCallback(() => {
    history.push("/settings");
  }, [history]);
  const changeLang = useCallback(
    (lang: LanguageEnum) => {
      i18n.changeLanguage(lang).then(() => {
        localStorage.setItem(LOCAL_STORAGE_LANG, lang);
      });
    },
    [i18n]
  );
  const langDropdown = useMemo<MenuProps>(
    () => ({
      activeKey: i18n.language,
      items: [
        {
          title: t("German"),
          label: t("German"),
          key: LanguageEnum.DE,
          onClick: () => {
            changeLang(LanguageEnum.DE);
          },
        },
        {
          title: t("Italian"),
          label: t("Italian"),
          key: LanguageEnum.IT,
          onClick: () => {
            changeLang(LanguageEnum.IT);
          },
        },
        {
          title: t("English"),
          label: t("English"),
          key: LanguageEnum.EN,
          onClick: () => {
            changeLang(LanguageEnum.EN);
          },
        },
      ],
    }),
    [changeLang, i18n.language, t]
  );
  return (
    <div className={`v-layout ${active}`}>
      <Layout hasSider>
        <Sider
          width={250}
          style={{
            maxHeight: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            height: "auto",
            borderRight: "1px solid var(--secondary)",
            zIndex: 7,
          }}
        >
          <div className="logo">
            <img src={logo} width="50%" alt="" />
          </div>
          <div className="side-bar-search">
            <Input
              placeholder={t("Search...")}
              prefix={<FiSearch />}
              onKeyUp={onSearch}
            />
          </div>
          <MainMenu active={active} />
        </Sider>
        <Layout className="site-layout" style={marginLeft}>
          <Header style={posFixed} className="layout-header">
            <div className="search-bar">
              <Input
                placeholder={t("Search...")}
                style={{ height: "42px" }}
                prefix={<FiSearch />}
                onKeyUp={onSearch}
              />
            </div>
            <div className="header-right">
              <Dropdown menu={langDropdown}>
                <Avatar
                  icon={<TranslationOutlined />}
                  style={{
                    color: "var(--text)",
                    background: "transparent",
                  }}
                />
              </Dropdown>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  role="button"
                  tabIndex={0}
                  onKeyUp={onClickUsername}
                  className="name"
                  title={t("Settings")}
                  onClick={onClickUsername}
                >
                  {me ? `${me.firstname} ${me.secondname}` : t("Login")}
                </span>
              </div>
            </div>
          </Header>
          <Content className="children" style={contentStyle}>
            {displayMask && <div className="custom-mask" />}
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default VerticalLayout;
