import React, { PropsWithChildren } from "react";
import Layout from "./Layout";
import { LayoutProps } from "./types";

const Mainlayout: React.FC<PropsWithChildren<LayoutProps>> = ({
  children,
  active,
}) => (
  <div className="layout">
    <Layout active={active}>{children}</Layout>
  </div>
);

// <MobileLayout active={active}>{children}</MobileLayout>
export default Mainlayout;
