import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import React from "react";

type LoaderProps = {
  size?: "small" | "large";
};
const Loader: React.FC<LoaderProps> = ({ size }) => {
  if (size && size === "large") {
    return (
      <div className="loader-large">
        <Spin indicator={<LoadingOutlined className="loader-spinner" spin />} />
      </div>
    );
  }
  return (
    <Spin indicator={<LoadingOutlined className="loader-spinner" spin />} />
  );
};
Loader.defaultProps = {
  size: undefined,
};

export default Loader;
