export type ModalState = {
  readonly openCard: string | undefined;
};

export enum ModalActionTypes {
  SHOW_MODAL = "@@modal/SET_MODAL",
  HIDE_MODAL = "@@modal/HIDE_MODAL",
}

export type ModalActions =
  | {
      type: ModalActionTypes.SHOW_MODAL;
      payload: string;
    }
  | {
      type: ModalActionTypes.HIDE_MODAL;
    };
