import React, { useCallback, useMemo } from "react";
import { generatePW } from "../../Util/utils";

type SwitchProps = {
  label?: string;
  name?: string;
  color?: string;
  value: boolean;
  switchHandler: (val: boolean, name?: string) => void;
};
const Switch: React.FC<SwitchProps> = ({
  label,
  value,
  color,
  switchHandler,
  name,
}) => {
  const onClick = useCallback(() => {
    switchHandler(!value, name);
  }, [name, switchHandler, value]);
  const realName = useMemo(() => {
    if (name) return name;
    return generatePW();
  }, [name]);
  return (
    <div className="switches">
      {!!label && (
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <div className="colour-dot" style={{ background: `${color}` }} />
          <div>{label}</div>
        </div>
      )}
      <label className="switch" htmlFor={realName}>
        <input
          type="checkbox"
          name={realName}
          id={realName}
          checked={value}
          onChange={onClick}
        />
        <span
          className="slider round "
          style={{
            justifyContent: `${value ? "flex-start" : "flex-end"}`,
          }}
        >
          {value ? "on" : "off"}
        </span>
      </label>
    </div>
  );
};

Switch.defaultProps = {
  color: "#37a1db",
  label: undefined,
  name: undefined,
};

export default Switch;
