import React, { useMemo } from "react";
import { Progress, Typography } from "antd";
import { BsArrowDown, BsArrowUp } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/store";
import {
  applyLambda,
  DataSeriesResponse,
  getSeriesColor,
} from "../../redux/types/influxDataTypes";
import { id, timeDiffInWords } from "../../Util/utils";
import { ModalActionTypes } from "../../redux/types/modalTypes";
import { GraphCustomConfig } from "../../Util/types";

type TwoValuePercentProps = {
  circled?: boolean;
  series: DataSeriesResponse[];
  customConfig?: string;
};
const TwoValuePercent: React.FC<TwoValuePercentProps> = ({
  series,
  circled,
  customConfig,
}) => {
  const colors = useSelector<RootState, RootState["userReducer"]["colors"]>(
    (state) => state.userReducer.colors
  );
  const configParsed = useMemo<GraphCustomConfig>(() => {
    if (!customConfig) return {};
    try {
      return JSON.parse(customConfig);
    } catch (e) {
      return {};
    }
  }, [customConfig]);
  const hideSum = useMemo(() => {
    if ("hideSum" in configParsed) return configParsed.hideSum;
    return false;
  }, [configParsed]);
  const hideSum2 = useMemo(() => {
    if ("hideSum2" in configParsed) return configParsed.hideSum2;
    return false;
  }, [configParsed]);
  const data1IsSum = useMemo(() => {
    if ("data1IsSum" in configParsed) return configParsed.data1IsSum;
    return false;
  }, [configParsed]);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [
    currentVal,
    percent,
    timeDiff,
    unitName,
    progressBarsPercent,
    progressBarsValues,
  ] = useMemo(() => {
    let unit = "kW";
    let sum = 0;
    let old = 0;
    let diff = 0;
    const single: number[] = Array(series.length).fill(0);
    const singleValues: number[] = Array(series.length).fill(0);
    series.forEach((s, idx) => {
      unit = t(s.unit);
      const data = applyLambda(s);
      if (data && data.length > 1) {
        const last = data[data.length - 1];
        const first = data[0];
        diff =
          new Date(last.timestamp).getTime() -
          new Date(first.timestamp).getTime();
        if (data1IsSum && idx === 0) {
          sum = Math.max(last.value, 0);
          old += Math.max(first.value, 0);
        }
        if (data1IsSum) {
          single[idx] += Math.max(last.value, 0);
          singleValues[idx] += Math.max(last.value, 0);
        }
        if (!data1IsSum) {
          sum += Math.max(last.value, 0);
          old += Math.max(first.value, 0);

          single[idx] += Math.max(last.value, 0);
          singleValues[idx] += Math.max(last.value, 0);
        }
      }
    });
    single.forEach((s, i) => {
      single[i] = parseFloat(((s / sum) * 100).toFixed(1));
    });
    return [sum, (sum / old) * 100 - 100, diff, unit, single, singleValues];
  }, [data1IsSum, series, t]);
  return (
    <div className="two-value-percent">
      {!hideSum && (
        <div className="two-value-percent-left">
          <Typography.Title className="sub-title" level={4}>
            {`${currentVal.toFixed(2)} ${unitName}`}
          </Typography.Title>
          <div className="percentage-wrapper">
            <span className="percentage">
              {`${percent.toFixed(1)}% `}
              {percent > 0 ? (
                <BsArrowUp style={{ strokeWidth: 2 }} />
              ) : (
                <BsArrowDown style={{ strokeWidth: 2 }} />
              )}
            </span>
            &nbsp;
            <span className="text">{t(timeDiffInWords(timeDiff))}</span>
          </div>
        </div>
      )}
      <div className={`two-value-percent-right ${circled ? "circled" : ""}`}>
        {progressBarsPercent.length > 1 &&
          progressBarsPercent.map((data, index) => (
            <div
              className={circled ? "circle-chart-wrapper" : "bar-chart-wrapper"}
              key={id(series[index])}
            >
              <span
                role="button"
                style={
                  series[index].onClickCardId
                    ? { cursor: "pointer", marginRight: "10px" }
                    : { marginRight: "10px" }
                }
                onClick={() => {
                  if (series[index].onClickCardId)
                    dispatch({
                      type: ModalActionTypes.SHOW_MODAL,
                      payload: series[index].onClickCardId,
                    });
                }}
                onKeyUp={(e) => {
                  if (e.key === "Enter" && series[index].onClickCardId)
                    dispatch({
                      type: ModalActionTypes.SHOW_MODAL,
                      payload: series[index].onClickCardId,
                    });
                }}
                tabIndex={0}
              >
                {series[index].name}
              </span>
              {circled && !hideSum2 && (
                <span>{`${progressBarsValues[index].toFixed(
                  2
                )} ${unitName}`}</span>
              )}
              <Progress
                strokeLinecap="square"
                type={circled ? "circle" : "line"}
                strokeColor={getSeriesColor(series[index].color, index, colors)}
                percent={data}
                strokeWidth={6}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

TwoValuePercent.defaultProps = {
  circled: false,
};

export default TwoValuePercent;
