/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum ScopeEnum {
    ADMIN = 'ADMIN',
    CLIENT = 'CLIENT',
}
