/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum UnitEnum {
    KWH = 'KWH',
    KW = 'KW',
    V = 'V',
    EUR = 'EUR',
    HZ = 'HZ',
    PERCENT = 'PERCENT',
    THDU = 'THDU',
}
