/* eslint-disable no-unused-vars,jsx-a11y/no-noninteractive-element-to-interactive-role */
import React, {
  ChangeEvent,
  HTMLProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  InputRef,
  notification,
  Row,
  Table,
} from "antd";

import "../../Styles/pages/servicesDashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { LanguageEnum, Translation } from "../../API/_generated";
import { deleteKeyword, getKeywords, patchKeyword } from "../../redux";
import { RootState } from "../../redux/store";
import Mainlayout from "../../Layout/MainLayout";
import { STD_GUTTER } from "../../Util/constants";

const EditableContext = React.createContext<FormInstance>(
  {} as FormInstance<any>
);

const RenderButtons: React.FC<{
  record: Keyword;
  handleDelete: (record: Keyword) => void;
}> = ({ record, handleDelete }) => {
  const { t } = useTranslation();
  const onHandleDelete = useCallback(() => {
    handleDelete(record);
  }, [record, handleDelete]);
  return (
    <Button icon={<DeleteOutlined />} onClick={onHandleDelete}>
      {t("Delete")}
    </Button>
  );
};
const EditableRow: React.FC<HTMLProps<HTMLTableRowElement>> = ({
  ...props
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell: React.FC<
  React.PropsWithChildren<
    {
      editable: boolean;
      dataIndex: LanguageEnum;
      record: Keyword;
      handleSave: (row: Translation) => void;
    } & HTMLProps<any>
  >
> = ({ editable, children, dataIndex, record, handleSave, ...restProps }) => {
  const [editing, setEditing] = useState(
    record?.key === "" &&
      dataIndex !== LanguageEnum.EN &&
      dataIndex !== LanguageEnum.IT &&
      dataIndex !== LanguageEnum.DE
  );
  const inputRef = useRef<InputRef>(null);
  const { setFieldsValue, validateFields } = useContext(EditableContext);
  useEffect(() => {
    if (editing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editing]);

  const toggleEdit = useCallback(() => {
    setEditing(!editing);
    setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  }, [dataIndex, editing, record, setFieldsValue]);

  const save = async () => {
    const values = await validateFields();
    if (values[dataIndex]) {
      toggleEdit();
      if (record.key === "") {
        if (values[dataIndex] !== "")
          handleSave({
            key: values[dataIndex],
            lang: LanguageEnum.EN,
            value: "",
          });
      } else {
        handleSave({
          key: record.key,
          value: values[dataIndex],
          lang: dataIndex,
        });
      }
    }
  };

  if (editable) {
    if (editing)
      return (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
        >
          <Input ref={inputRef} onPressEnter={save} onBlur={save} />
        </Form.Item>
      );
    return (
      <td
        {...restProps}
        tabIndex={0}
        role="button"
        className="editable-cell-value-wrap"
        onClick={toggleEdit}
        onKeyUp={toggleEdit}
      >
        {children}
      </td>
    );
  }

  return <td {...restProps}>{children}</td>;
};

type Keyword = {
  key: string;
} & Record<LanguageEnum, string>;
const AdminLanguage = () => {
  const [dataSource, setDataSource] = useState<Keyword[]>([]);
  const [searchText, setSearchText] = useState("");
  const [create, setCreate] = useState(false);
  const [newKey, setNewKey] = useState("");
  const keywords = useSelector<
    RootState,
    RootState["adminReducer"]["keywords"]
  >((state) => state.adminReducer.keywords);
  const dispatch = useDispatch();

  useEffect(() => {
    getKeywords(dispatch);
  }, [dispatch]);
  const uniqueKeys = useMemo(
    () =>
      Object.keys(keywords.en)
        .concat(Object.keys(keywords.de))
        .concat(Object.keys(keywords.it))
        .filter((val, i, array) => array.indexOf(val) === i),
    [keywords]
  );
  // manage dataSource & search
  useEffect(() => {
    const mapped = uniqueKeys.map<Keyword>((item) => ({
      key: item,
      en: keywords.en[item] ?? "",
      it: keywords.it[item] ?? "",
      de: keywords.de[item] ?? "",
    }));

    if (searchText !== "") {
      const filtered = mapped.filter(
        (item) =>
          item.key.toLowerCase().includes(searchText.toLowerCase()) ||
          item.de.toLowerCase().includes(searchText.toLowerCase()) ||
          item.en.toLowerCase().includes(searchText.toLowerCase()) ||
          item.it.toLowerCase().includes(searchText.toLowerCase())
      );

      setDataSource(filtered);
    } else {
      setDataSource(mapped);
    }
  }, [keywords, searchText, uniqueKeys]);

  const handleSearch = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  }, []);
  const { t } = useTranslation();
  const handleSave = useCallback(
    (trans: Translation) => {
      if (trans.key !== "") {
        patchKeyword(dispatch, trans).then((newTrans) => {
          setCreate(false);
          notification.success({
            message: `${t("Save sucessful")}: ${newTrans.key}`,
            duration: 2,
          });
        });
      }
    },
    [dispatch, t]
  );

  const handleSaveNew = useCallback(() => {
    patchKeyword(dispatch, {
      key: newKey,
      value: "",
      lang: LanguageEnum.DE,
    }).then((newTrans) => {
      setNewKey("");
      notification.success({
        message: `${t("Save sucessful")}: ${newTrans.key}`,
        duration: 2,
      });
    });
  }, [dispatch, newKey, t]);
  const onSetNewKey = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNewKey(e.target.value);
  }, []);
  const components = useMemo(
    () => ({
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    }),
    []
  );
  const handleDelete = useCallback(
    (keyword: Keyword) => {
      deleteKeyword(dispatch, keyword.key);
    },
    [dispatch]
  );
  const renderButtons = useCallback(
    (val: any, record: Keyword) => (
      <RenderButtons record={record} handleDelete={handleDelete} />
    ),
    [handleDelete]
  );
  const columns = useMemo<ColumnsType<Keyword>>(
    () =>
      [
        {
          title: t("Key"),
          dataIndex: "key",
          key: "key",
        },
        {
          title: t("English"),
          dataIndex: "en",
          key: "en",
          editable: true,
        },
        {
          title: t("German"),
          dataIndex: "de",
          key: "de",
          editable: true,
        },
        {
          title: t("Italian"),
          dataIndex: "it",
          key: "it",
          editable: true,
        },
        {
          title: t("Action"),
          dataIndex: "operation",
          render: renderButtons,
        },
      ].map((col) => {
        if (!col.editable) {
          return col;
        }

        return {
          ...col,
          onCell: (record: Keyword) => ({
            record,
            editable: record.key !== "",
            dataIndex: col.dataIndex,
            title: col.title,
            handleSave,
          }),
        };
      }),
    [handleSave, renderButtons, t]
  );
  const keyExtractor = useCallback((record: Keyword) => record.key, []);
  const toggleCreate = useCallback(() => {
    setCreate((c) => !c);
  }, []);
  return (
    <Mainlayout active="admin-language">
      <div className="home-main-admin-language">
        <Row gutter={STD_GUTTER}>
          <Col xs={12} md={12}>
            <Input
              placeholder="Search by column key"
              value={searchText}
              onChange={handleSearch}
              style={{ marginBottom: 16 }}
            />
          </Col>
          <Col xs={12} md={12}>
            <Button onClick={toggleCreate}>
              <PlusOutlined />
              {t("New Keyword")}
            </Button>
          </Col>
        </Row>
        {create && (
          <Row gutter={STD_GUTTER} className="mb-2">
            <Col xs={12}>
              <Input
                onPressEnter={handleSaveNew}
                value={newKey}
                onChange={onSetNewKey}
              />
            </Col>
            <Col>
              <Button
                type="primary"
                onClick={handleSaveNew}
                disabled={newKey.length === 0}
              >
                {t("Save")}
              </Button>
            </Col>
            <Col>
              <Button type="link" onClick={toggleCreate}>
                {t("Cancel")}
              </Button>
            </Col>
          </Row>
        )}
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          bordered
          dataSource={dataSource}
          rowKey={keyExtractor}
          columns={columns}
        />
      </div>
    </Mainlayout>
  );
};

export default AdminLanguage;
