import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";

const AdminRoute: React.FC<RouteProps> = ({
  component: AdminComponent,
  ...rest
}) => {
  const user = useSelector<RootState, RootState["userReducer"]["me"]>(
    (state) => state.userReducer.me
  );
  const isAdmin = useSelector<RootState, RootState["userReducer"]["isAdmin"]>(
    (state) => state.userReducer.isAdmin
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        user && isAdmin && AdminComponent ? (
          <AdminComponent {...props} />
        ) : (
          <Redirect to="/login?msg=unauthorized" />
        )
      }
    />
  );
};

export default AdminRoute;
