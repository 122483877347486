import React, { useCallback, useMemo } from "react";
import { Area, AreaConfig, PlotEvent } from "@ant-design/plots";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  determineDateTimeOptions,
  hexToRgb,
  valuePairLabel,
} from "../../Util/utils";
import { RootState } from "../../redux/store";
import {
  applyLambda,
  DataSeriesResponse,
  getSeriesColor,
  sameLength,
} from "../../redux/types/influxDataTypes";
import { GroupByEnum, RangeEnum } from "../../API/_generated";
import CustomTooltip, { TooltipData } from "./CustomTooltip";
import { GraphCustomConfig } from "../../Util/types";
import { ModalActionTypes } from "../../redux/types/modalTypes";

type StackedData = {
  unitName: string;
  timestamp: string;
  value: number;
  name: string;
  color: string;
};

type StackedProps = {
  series: DataSeriesResponse[];
  start: string;
  stop: string;
  granularity: RangeEnum;
  customConfig?: string;
  seriesSameLength: boolean | undefined;
};

const AntdStacked: React.FC<StackedProps> = ({
  series,
  seriesSameLength,
  start,
  stop,
  granularity,
  customConfig,
}) => {
  const colors = useSelector<RootState, RootState["userReducer"]["colors"]>(
    (state) => state.userReducer.colors
  );
  const configParsed = useMemo<GraphCustomConfig>(() => {
    if (!customConfig) return {};
    try {
      return JSON.parse(customConfig);
    } catch (e) {
      return {};
    }
  }, [customConfig]);
  const smooth = useMemo(() => {
    if ("smooth" in configParsed) return configParsed.smooth;
    return false;
  }, [configParsed]);
  const slider = useMemo(() => {
    if ("slider" in configParsed) return configParsed.slider;
    return false;
  }, [configParsed]);
  const gridLines = useMemo(() => {
    if ("gridLines" in configParsed) return configParsed.gridLines;
    return false;
  }, [configParsed]);
  const gridColumns = useMemo(() => {
    if ("gridColumns" in configParsed) return configParsed.gridColumns;
    return false;
  }, [configParsed]);
  const bigDots = useMemo(() => {
    if ("bigDot" in configParsed) return configParsed.bigDot;
    return false;
  }, [configParsed]);

  const stacked = useMemo(() => {
    if ("stacked" in configParsed) return configParsed.stacked;
    return false;
  }, [configParsed]);
  const dashed = useMemo(() => {
    if (
      "dashed" in configParsed &&
      configParsed.dashed &&
      Array.isArray(configParsed.dashed)
    )
      return configParsed.dashed;
    return [];
  }, [configParsed]);
  const legend = useMemo(() => {
    if ("legend" in configParsed && configParsed.legend)
      return configParsed.legend;
    return "bottom";
  }, [configParsed]);
  const { t } = useTranslation();
  const RenderCustomTooltip = useCallback(
    (title: string, data: TooltipData[]) => (
      <CustomTooltip title={title} data={data} />
    ),
    []
  );
  const dispatch = useDispatch();
  const config = useMemo<AreaConfig>(() => {
    const parsed: StackedData[] = [];
    let unitName = "";
    series.forEach((s, index) => {
      const groupBys = s.availableGroupBy ? JSON.parse(s.availableGroupBy) : {};
      const groupBy = groupBys[start] ?? s.groupBy;

      const options = determineDateTimeOptions(
        s.lambdaFn && s.lambdaFn.indexOf("onlyLastDay") !== -1
          ? GroupByEnum.HOUR
          : s.lambdaFn && s.lambdaFn.indexOf("onlyLastWeek") !== -1
          ? GroupByEnum.WEEK_DAY
          : s.lambdaFn && s.lambdaFn.indexOf("onlyLastMonth") !== -1
          ? GroupByEnum.MONTH_DAY
          : start,
        granularity,
        groupBy,
        stop
      );
      unitName = t(s.unit);
      const data = applyLambda(s);
      data.forEach((r) => {
        parsed.push({
          name: s.name,
          color: getSeriesColor(s.color, index, colors),
          timestamp: valuePairLabel(r, options, groupBy),
          unitName,
          value: r.value,
        });
      });
    });
    return {
      data: seriesSameLength ? sameLength(parsed, series.length) : parsed,
      height: 340,
      autoFit: true,
      xField: "timestamp",
      smooth,
      yField: "value",
      seriesField: "name",
      color: (a) => {
        const idx = parsed.findIndex((p) => p.name === a.name);
        if (idx === -1) return colors[0];
        return getSeriesColor(parsed[idx].color, idx, colors);
      },
      legend: {
        position: legend,
      },
      tooltip: {
        domStyles: {
          "g2-tooltip": {
            background: "transparent",
            borderRadius: 0,
            padding: "0",
            boxShadow: "none",
          },
        },
        customContent: RenderCustomTooltip,
      },
      slider: slider
        ? {
            start: 0,
            end: 1,
          }
        : undefined,
      isStack: stacked,
      xAxis: {
        line: null,
        grid: {
          line: {
            style: {
              stroke: "black",
              lineWidth: gridColumns ? 0.1 : 0,
            },
          },
          alignTick: true,
          closed: true,
        },
      },
      lineStyle: dashed.length ? { lineDash: [10, 10] } : undefined,
      point: bigDots
        ? {
            size: 7,
            shape: "circle",
            style: (datum) => {
              const idx = series.findIndex((s) => s.name === datum.name);
              return {
                fill: "white",
                stroke:
                  idx === -1
                    ? colors[0]
                    : getSeriesColor(parsed[idx].color, idx, colors),
                lineWidth: 4,
              };
            },
          }
        : undefined,
      yAxis: {
        title: {
          text: unitName,
          style: {
            stroke: "#000000",
            fontSize: 13,
            color: "#000000",
            letterSpacing: "1px",
            fontWeight: 300,
            opacity: 0.9,
            shadowBlur: 0,
            strokeOpacity: 0,
          },
        },
        grid: {
          line: {
            style: {
              stroke: "black",
              lineWidth: gridLines ? 0.1 : 0,
            },
          },
          alignTick: true,
          closed: true,
        },
      },
      areaStyle: (datum) => {
        const idx = parsed.findIndex((s) => s.name === datum.name);
        if (idx !== -1) {
          const hex = getSeriesColor(parsed[idx].color, idx, colors);
          const color = hexToRgb(hex);
          return {
            fill: `l(270) 0:rgba(${color.r},${color.g},${color.b},0.1) 0.5:rgba(${color.r},${color.g},${color.b},0.2) 1:rgba(${color.r},${color.g},${color.b},0.7)`,
          };
        }
        return undefined;
      },
    };
  }, [
    series,
    seriesSameLength,
    smooth,
    legend,
    RenderCustomTooltip,
    slider,
    stacked,
    gridColumns,
    dashed.length,
    bigDots,
    gridLines,
    start,
    granularity,
    stop,
    t,
    colors,
  ]);
  const eventHandler = useCallback(
    (ty: any, event: PlotEvent) => {
      if (event.type === "legend:click") {
        const key = event?.target?.cfg?.delegateObject?.item?.id;
        series.forEach((s) => {
          if (s.onClickCardId && key === s.name) {
            dispatch({
              type: ModalActionTypes.SHOW_MODAL,
              payload: s.onClickCardId,
            });
          }
        });
        //        console.log(event);
      }
      return event;
    },
    [dispatch, series]
  );

  return <Area {...config} onEvent={eventHandler} animation={false} />;
};

export default AntdStacked;
