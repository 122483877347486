import { Dispatch } from "redux";
import { notification } from "antd";
import i18n from "i18next";
import client from "../../API";
import { AdminActionTypes } from "../types/adminTypes";
import {
  CancelablePromise,
  User,
  UserCreate,
  UserPatch,
} from "../../API/_generated";

export const getClients = (dispatch: Dispatch) => {
  client()
    .users()
    .then((users) => {
      dispatch({ type: AdminActionTypes.GET_USERS, payload: users });
    });
};
export const getBuckets = (dispatch: Dispatch, id: string) => {
  client()
    .getBucketsOfUser(id)
    .then((buckets) => {
      dispatch({
        type: AdminActionTypes.GET_BUCKETS,
        payload: { [id]: buckets },
      });
    });
};

export const createClient = (dispatch: Dispatch, user: UserCreate) =>
  new CancelablePromise<User>((resolve, reject) => {
    client()
      .createUser(user)
      .then((newUser) => {
        dispatch({ type: AdminActionTypes.USER_PATCH, payload: newUser });
        resolve(newUser);
      }, reject);
  });
export const patchClient = (dispatch: Dispatch, id: string, user: UserPatch) =>
  new CancelablePromise<User>((resolve, reject) => {
    client()
      .patchUser(id, user)
      .then((newUser) => {
        dispatch({ type: AdminActionTypes.USER_PATCH, payload: newUser });
        resolve(newUser);
      }, reject);
  });
export function addBucketToUser(
  dispatch: Dispatch,
  userId: string,
  bucket: string
) {
  client()
    .addBucketToUser(userId, bucket)
    .then((buckets) => {
      dispatch({
        type: AdminActionTypes.GET_BUCKETS,
        payload: { [userId]: buckets },
      });
    });
}
export function removeBucketFromUser(
  dispatch: Dispatch,
  userId: string,
  bucket: string
) {
  client()
    .removeBucketFromUser(userId, bucket)
    .then((buckets) => {
      dispatch({
        type: AdminActionTypes.GET_BUCKETS,
        payload: { [userId]: buckets },
      });
    });
}
export function editBucketOfUser(
  dispatch: Dispatch,
  userId: string,
  oldBucket: string,
  newBucket: string
) {
  client()
    .removeBucketFromUser(userId, oldBucket)
    .then(() => {
      client()
        .addBucketToUser(userId, newBucket)
        .then((buckets) => {
          dispatch({
            type: AdminActionTypes.GET_BUCKETS,
            payload: { [userId]: buckets },
          });
        });
    });
}

export function changeUserPw(
  disptach: Dispatch,
  userId: string,
  password: string
) {
  return new Promise<boolean>((resolve, reject) => {
    client()
      .changeUserPw(userId, { password })
      .then(
        () => {
          resolve(true);
        },
        (err) => {
          notification.error({
            message: i18n.t("Change Password failed") as string,
            duration: 3,
          });
          reject(err);
        }
      );
  });
}
