/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum LanguageEnum {
    DE = 'de',
    EN = 'en',
    IT = 'it',
}
