import React, { useCallback, useEffect, useState } from "react";
import { Input } from "antd";

export const NEW_BUCKET_NAME = "NEW_BUCKET_LOL";
const BucketInput: React.FC<{
  record: string;
  update: boolean;
  index: number;
  onChange: (val: string, index: number) => void;
}> = ({ record, update, onChange, index }) => {
  const [val, setVal] = useState(record === NEW_BUCKET_NAME ? "" : record);
  useEffect(() => {
    setVal(record === NEW_BUCKET_NAME ? "" : record);
  }, [record, update]);
  const onValChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setVal(e.target.value);
      onChange(e.target.value, index);
    },
    [index, onChange]
  );
  return <Input value={val} onChange={onValChange} className="bucket-input" />;
};

export default BucketInput;
