/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum RangeEnum {
    _1M = '1M',
    _2M = '2M',
    _5M = '5M',
    _15M = '15M',
    _30M = '30M',
    _1H = '1H',
    _2H = '2H',
    _4H = '4H',
    _6H = '6H',
    _12H = '12H',
    _1D = '1D',
    _2D = '2D',
    _3D = '3D',
    _7D = '7D',
    _30D = '30D',
    _90D = '90D',
    _365D = '365D',
    NOW_ = 'now()',
}
