import { Reducer } from "redux";
import {
  InfluxDataActions,
  InfluxDataActionTypes,
  InfluxDataState,
} from "../types/influxDataTypes";

const initialState: InfluxDataState = {
  error: undefined,
  status: "idle",
  data: {},
};

const influxDataReducer: Reducer<InfluxDataState> = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: InfluxDataActions
) => {
  const curData = { ...state.data };

  const { type } = action;
  switch (type) {
    case InfluxDataActionTypes.GET_DATA:
      return {
        ...state,
        status: "loading",
        error: undefined,
      };
    case InfluxDataActionTypes.PURGE_DATA:
      return {
        ...state,
        status: "idle",
        error: undefined,
        data: {},
      };
    case InfluxDataActionTypes.GET_DATA_SUCCESS:
      return {
        ...state,
        status: "idle",
        data: {
          ...state.data,
          [action.payload.hash]: {
            value: action.payload.value,
            fetchDate: action.payload.fetchDate,
          },
        },
      };
    case InfluxDataActionTypes.GET_DATA_MULTIPLE:
      return {
        ...state,
        status: "idle",
        data: {
          ...state.data,
          ...action.payload,
        },
      };
    case InfluxDataActionTypes.PURGE_SOME_DATA:
      action.payload.forEach((hash) => {
        if (hash in curData) delete curData[hash];
      });
      return {
        ...state,
        status: "idle",
        curData,
      };
    case InfluxDataActionTypes.GET_DATA_FAILED:
      return {
        ...state,
        status: "failed",
        error: action.payload,
      };
    default:
      return state;
  }
};

export default influxDataReducer;
