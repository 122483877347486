import React, { useCallback, useMemo, useRef } from "react";
import { useDispatch } from "react-redux";
import HeatmapCell from "./HeatmapCell";
import { TooltipData } from "../CustomTooltip";
import { ModalActionTypes } from "../../../redux/types/modalTypes";

export type HeatMapRow = {
  onClickCardId?: string;
  name: string;
  values: TooltipData[];
};

type HeatmapRowProps = {
  uniqueId: string;
  item: HeatMapRow;
  rows: number;
  maxValue: number;
  numberOnly?: boolean;
};
const HeatmapRow: React.FC<HeatmapRowProps> = ({
  uniqueId,
  maxValue,
  numberOnly,
  item,
  rows,
}) => {
  const labelRef = useRef(null);
  const rowStyle = useMemo(() => ({ height: `calc(85%/${rows})` }), [rows]);
  const dispatch = useDispatch();
  const onClickRow = useCallback(() => {
    if (item.onClickCardId) {
      dispatch({
        type: ModalActionTypes.SHOW_MODAL,
        payload: item.onClickCardId,
      });
    }
  }, [item, dispatch]);

  return (
    <div className="heatmap-row" style={rowStyle}>
      <div
        tabIndex={0}
        role="button"
        onKeyUp={onClickRow}
        ref={labelRef}
        className="label"
        onClick={onClickRow}
      >
        {item.name}
      </div>
      <div className="heatmap-cells">
        {item.values.map((itemC) => (
          <HeatmapCell
            title={item.name}
            item={itemC}
            maxValue={maxValue}
            numberOnly={numberOnly}
            key={`${uniqueId}-cell-${
              "start" in itemC ? itemC.start : itemC.timestamp
            }-${itemC.value}`}
          />
        ))}
      </div>
    </div>
  );
};

HeatmapRow.defaultProps = {
  numberOnly: false,
};

export default HeatmapRow;
