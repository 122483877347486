import React, { useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Graph } from "../../API/_generated";
import AntdStacked from "./AntdStacked";
import { RootState } from "../../redux/store";
import {
  DataSeriesResponse,
  hashInfluxData,
  inTime,
  MeasurementHash,
} from "../../redux/types/influxDataTypes";
import Loader from "../Loader";
import TwoValuePercent from "./TwoValuePercent";
import AntdBar from "./AntdBar";
import AntdLine from "./AntdLine";
import AntdColumn from "./AntdColumn";
import CustomHeatmap from "./CustomHeatmap/CustomHeatmap";
import { getMultipleData } from "../../redux";
import AntdDonut from "./AntdDonut";
import AntdDualAxis from "./AntdDualAxis";
import { id } from "../../Util/utils";

type GraphProps = {
  graph: Graph;
  dropdown: Dropdown;
};
const GraphView: React.FC<GraphProps> = ({ graph, dropdown }) => {
  const dispatch = useDispatch();
  const { data, status, error } = useSelector<
    RootState,
    RootState["influxDataReducer"]
  >((state) => state.influxDataReducer);
  const hashed = useRef<MeasurementHash[]>([]);
  const selectedData = useMemo(() => {
    const selected: DataSeriesResponse[] = [];
    graph.series.forEach((s) => {
      const groupBys = s.availableGroupBy ? JSON.parse(s.availableGroupBy) : {};
      let groupBy = groupBys[dropdown.start] ?? s.groupBy;
      if (groupBy === "") groupBy = undefined;
      const found: DataSeriesResponse = {
        ...s,
        data: [],
      };
      s.data?.forEach((iData) => {
        const hash = hashInfluxData(
          iData,
          dropdown,
          s.customWindow,
          groupBy,
          id(s)
        );
        if (hash in data && inTime(data[hash])) {
          hashed.current.push(hash);
          found.data.push({
            ...iData,
            response: data[hash].value,
          });
        }
      });
      if (found.data.length === s.data?.length) selected.push(found);
    });
    return selected;
  }, [data, dropdown, graph.series]);
  const amountNeededData = useMemo(
    () =>
      graph.series.reduce<number>((prev, s) => prev + (s.data?.length ?? 0), 0),
    [graph.series]
  );
  const amountSelectedData = useMemo(
    () => selectedData.length,
    [selectedData.length]
  );
  /* useEffect(() => {
    dispatch({
      type: InfluxDataActionTypes.PURGE_SOME_DATA,
      payload: selectedData,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdown]);*/
  useEffect(() => {
    getMultipleData(dispatch, graph.series, dropdown, hashed.current).then();
  }, [dispatch, graph.series, dropdown, amountNeededData, amountSelectedData]);
  if (selectedData.length !== graph.series.length) return <Loader />;
  if (graph.type === "twoValPercent") {
    if (graph.sub_type === "circle")
      return (
        <TwoValuePercent
          circled
          series={selectedData}
          customConfig={graph.config}
        />
      );
    return (
      <TwoValuePercent series={selectedData} customConfig={graph.config} />
    );
  }
  if (graph.type === "line") {
    if (graph.sub_type === "area")
      return (
        <AntdStacked
          series={selectedData}
          start={dropdown.start}
          stop={dropdown.stop}
          granularity={dropdown.granularity}
          customConfig={graph.config}
          seriesSameLength={graph.seriesSameLength}
        />
      );
    return (
      <AntdLine
        series={selectedData}
        start={dropdown.start}
        stop={dropdown.stop}
        granularity={dropdown.granularity}
        customConfig={graph.config}
        seriesSameLength={graph.seriesSameLength}
      />
    );
  }
  if (graph.type === "doughnut") {
    return <AntdDonut series={selectedData} />;
  }
  if (graph.type === "heatmap") {
    return (
      <CustomHeatmap
        series={selectedData}
        start={dropdown.start}
        stop={dropdown.stop}
        granularity={dropdown.granularity}
        customConfig={graph.config}
      />
    );
  }
  if (graph.type === "bar") {
    if (graph.sub_type === "horizontal")
      return (
        <AntdBar
          series={selectedData}
          start={dropdown.start}
          stop={dropdown.stop}
          granularity={dropdown.granularity}
          customConfig={graph.config}
          seriesSameLength={graph.seriesSameLength}
        />
      );
    return (
      <AntdColumn
        series={selectedData}
        start={dropdown.start}
        stop={dropdown.stop}
        granularity={dropdown.granularity}
        customConfig={graph.config}
        seriesSameLength={graph.seriesSameLength}
      />
    );
  }
  if (graph.type === "dualAxes")
    return (
      <AntdDualAxis
        series={selectedData}
        start={dropdown.start}
        stop={dropdown.stop}
        granularity={dropdown.granularity}
        customConfig={graph.config}
      />
    );
  return null;
};
export default GraphView;
