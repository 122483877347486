import { Dispatch } from "redux";
import client from "../../API";
import { AdminActionTypes } from "../types/adminTypes";
import {
  CancelablePromise,
  LanguageEnum,
  Translation,
  TranslationUpdate,
} from "../../API/_generated";

export const getKeywords = (dispatch: Dispatch) => {
  client()
    .getKeywords(LanguageEnum.DE)
    .then((de) => {
      client()
        .getKeywords(LanguageEnum.IT)
        .then((it) => {
          client()
            .getKeywords(LanguageEnum.EN)
            .then((en) => {
              dispatch({
                type: AdminActionTypes.GET_KEYWORDS,
                payload: { de, it, en },
              });
            });
        });
    });
};
export const patchKeyword = (dispatch: Dispatch, body: TranslationUpdate) =>
  new CancelablePromise<Translation>((resolve, reject) => {
    client()
      .patchKeyword(body)
      .then((payload) => {
        dispatch({ type: AdminActionTypes.PATCH_KEYWORD, payload });
        resolve(payload);
      }, reject);
  });

export const deleteKeyword = (dispatch: Dispatch, key: string) => {
  client()
    .deleteKeyword(key)
    .then(() => {
      getKeywords(dispatch);
    });
};
