import { Action, Dispatch } from "redux";
import * as Sentry from "@sentry/react";
import { notification } from "antd";
import { ApiError } from "../../API/_generated";
import { ServerActionTypes } from "../types/serverTypes";
import { UserActionTypes } from "../types/userTypes";

export function fetchError(
  error: ApiError,
  actionType: string,
  dispatch: Dispatch<Action>
): void {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.log("\x1b[31m%s\x1b[0m", `ERROR on ${actionType}`);
    console.log("\x1b[31m%O\x1b[0m", error);
    console.log("\x1b[31m%O\x1b[0m", error.body);
  }
  const errorKey =
    error.status === 422 ||
    error.status === 401 ||
    error.status === 400 ||
    error.status === 403
      ? "credentialsWrong"
      : error.status === 500 || error.status === 502 || error.status === 503
      ? "serverError"
      : error.status === 404
      ? ""
      : "noConnection";
  // 400 bad request
  // 401 Unauthorized lei wenn token eps fahlt
  // 403 Forbidden
  dispatch({
    type: actionType,
    payload: errorKey,
  });
  if (error.status >= 500 && error.status < 505) {
    Sentry.captureException(error);
    dispatch({ type: ServerActionTypes.GONE });
    notification.error({
      message: error?.message || "Server Error",
      duration: 3,
    });
  }
  if (error.status === 401) {
    dispatch({ type: UserActionTypes.UNAUTHORIZED });
    notification.error({
      message: error?.message || "Unauthorized",
      duration: 3,
    });
  }
}
