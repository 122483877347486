import React, { useCallback, useMemo, useRef, useState } from "react";
import CustomTooltip, { TooltipData } from "../CustomTooltip";

export type HeatmapLabelType = { label: string; values: TooltipData[] };

type HeatmapLabelProps = {
  item: HeatmapLabelType;
};

const HeatmapLabel: React.FC<HeatmapLabelProps> = ({ item }) => {
  const ref = useRef(null);
  const [annotation, setAnnotation] = useState<
    false | React.MouseEvent<HTMLDivElement>
  >(false);
  const onMouseOver = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (!annotation) setAnnotation(e);
    },
    [annotation]
  );
  const onMouseOut = useCallback(() => {
    setAnnotation(false);
  }, []);
  const annotationStyle = useMemo<React.CSSProperties>(() => {
    if (!annotation) return {};
    return {
      left: annotation.clientX,
      top: annotation.clientY,
    };
  }, [annotation]);
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        onMouseEnter={onMouseOver}
        onMouseLeave={onMouseOut}
        ref={ref}
        className="heatmap-label"
      >
        <span>{item.label}</span>
      </div>
      {annotation && (
        <div className="heatmap-tooltip" style={annotationStyle}>
          <CustomTooltip title={item.label} data={item.values} />
        </div>
      )}
    </>
  );
};
export default HeatmapLabel;
