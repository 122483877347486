/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { UserCreate } from '../models/UserCreate';
import type { UserPatch } from '../models/UserPatch';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * Get User Me
     * @returns User Successful Response
     * @throws ApiError
     */
    public static getUserMeUserMeGet(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/me',
        });
    }

    /**
     * Patch User Me
     * @param requestBody 
     * @returns User Successful Response
     * @throws ApiError
     */
    public static patchUserMeUserMePatch(
requestBody: UserPatch,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user/me',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get User
     * @param userId 
     * @returns User Successful Response
     * @throws ApiError
     */
    public static getUserUserUserIdGet(
userId: string,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Patch User
     * @param userId 
     * @param requestBody 
     * @returns User Successful Response
     * @throws ApiError
     */
    public static patchUserUserUserIdPatch(
userId: string,
requestBody: UserPatch,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/user/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Users
     * @param length 
     * @param offset 
     * @returns User Successful Response
     * @throws ApiError
     */
    public static getUsersUserGet(
length: number = 100,
offset?: number,
): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/user/',
            query: {
                'length': length,
                'offset': offset,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create User
     * @param requestBody 
     * @returns User Successful Response
     * @throws ApiError
     */
    public static createUserUserPost(
requestBody: UserCreate,
): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/user/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
