import { LanguageEnum, Site, Translation, User } from "../../API/_generated";
import { TempFilter } from "../../Util/types";

export type AdminState = {
  readonly users: User[];
  readonly bucketsOfUser: Record<string, string[]>;
  readonly filterOfBuckets: Record<string, TempFilter[]>;
  readonly status: "idle" | "failed";
  readonly keywords: Record<LanguageEnum, Record<string, string>>;
  readonly sitesOfUser: Record<string, Site[]>;
  readonly currentSiteModifying: Site | undefined;
};

export enum AdminActionTypes {
  GET_USERS = "@@admin/GET_USERS",
  USER_PATCH = "@@admin/USER_PATCH",
  GET_FAILED = "@@admin/GET_FAILED",
  GET_BUCKETS = "@@admin/GET_BUCKETS",
  GET_KEYWORDS = "@@admin/GET_KEYWORDS",
  PATCH_KEYWORD = "@@admin/PATCH_KEYWORD",
  GET_SITES = "@@admin/GET_SITES",
  GET_FILTERS = "@@admin/GET_FILTERS",
  PATCH_SITE = "@@admin/PATCH_SITE",
  SET_MODIFYING_SITE = "@@admin/SET_MODIFYING_SITE",
}

export type AdminActions =
  | {
      type: AdminActionTypes.GET_USERS;
      payload: User[];
    }
  | {
      type: AdminActionTypes.SET_MODIFYING_SITE;
      payload: Site | undefined;
    }
  | {
      type: AdminActionTypes.GET_SITES;
      payload: Record<string, Site[]>;
    }
  | {
      type: AdminActionTypes.PATCH_SITE;
      payload: { userId: string; site: Site };
    }
  | {
      type: AdminActionTypes.GET_KEYWORDS;
      payload: Record<LanguageEnum, Record<string, string>>;
    }
  | {
      type: AdminActionTypes.PATCH_KEYWORD;
      payload: Translation;
    }
  | {
      type: AdminActionTypes.USER_PATCH;
      payload: User;
    }
  | {
      type: AdminActionTypes.GET_BUCKETS;
      payload: Record<string, string[]>;
    }
  | {
      type: AdminActionTypes.GET_FILTERS;
      payload: Record<string, TempFilter[]>;
    }
  | { type: AdminActionTypes.GET_FAILED };
