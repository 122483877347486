import * as React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Login from "./Pages/Login";
import Profile from "./Pages/Profile";
import Compare from "./Pages/Compare";
import PrivateRoutes from "./PrivateRoutes";
import AdminRoute from "./AdminRoutes";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import AdminSidebar from "./Pages/Admin/AdminSidebar";
import AdminLanguage from "./Pages/Admin/AdminLanguageManager";
import AdminSites from "./Pages/Admin/AdminSites";
import SiteView from "./Pages/SiteView";
import AdminDetails from "./Pages/Admin/AdminDetails";

const Routes: React.FC = () => (
  <Router>
    <Switch>
      <PrivateRoutes path="/" exact component={SiteView} />
      <Route path="/login" exact component={Login} />
      <PrivateRoutes path="/settings" exact component={Profile} />

      <AdminRoute path="/admin" exact component={AdminDashboard} />
      <AdminRoute path="/admin-dashboard" exact component={AdminDashboard} />
      <AdminRoute path="/admin-sidebar" exact component={AdminSidebar} />
      <AdminRoute path="/admin-language" exact component={AdminLanguage} />
      <AdminRoute path="/admin-sites" exact component={AdminSites} />
      <AdminRoute path="/admin-details" exact component={AdminDetails} />
      <AdminRoute
        path="/admin-sites/:userId/:siteId"
        exact
        component={AdminSites}
      />
      <PrivateRoutes path="/details" exact component={Compare} />
      <PrivateRoutes path="/:siteId" exact component={SiteView} />
      <PrivateRoutes path="/analysis" exact component={SiteView} />
      <PrivateRoutes path="/load-peak" exact component={SiteView} />
      <PrivateRoutes path="/live" exact component={SiteView} />
      <PrivateRoutes path="/energy-production" exact component={SiteView} />
      <PrivateRoutes path="/emobility" exact component={SiteView} />
      <PrivateRoutes path="/co2emission" exact component={SiteView} />
    </Switch>
  </Router>
);
export default Routes;
