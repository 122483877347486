import React, { useCallback, useMemo } from "react";
import { DualAxes, DualAxesConfig, PlotEvent } from "@ant-design/plots";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { GroupByEnum, RangeEnum } from "../../API/_generated";
import { RootState } from "../../redux/store";
import { determineDateTimeOptions, valuePairLabel } from "../../Util/utils";
import {
  applyLambda,
  DataSeriesResponse,
} from "../../redux/types/influxDataTypes";
import CustomTooltip, { TooltipData } from "./CustomTooltip";
import { GraphCustomConfig } from "../../Util/types";
import { ModalActionTypes } from "../../redux/types/modalTypes";

type ColumnChartProps = {
  series: DataSeriesResponse[];
  start: string;
  stop: string;
  granularity: RangeEnum;
  customConfig?: string;
};
const AntdDualAxisChart: React.FC<ColumnChartProps> = ({
  series,
  start,
  stop,
  granularity,
  customConfig,
}) => {
  const colors = useSelector<RootState, RootState["userReducer"]["barColors"]>(
    (state) => state.userReducer.barColors
  );
  const { t } = useTranslation();
  const RenderCustomTooltip = useCallback(
    (title: string, data: TooltipData[]) => (
      <CustomTooltip title={title} data={data} />
    ),
    []
  );
  const configParsed = useMemo<GraphCustomConfig>(() => {
    if (!customConfig) return {};
    try {
      return JSON.parse(customConfig);
    } catch (e) {
      return {};
    }
  }, [customConfig]);
  const gridLines = useMemo(() => {
    if ("gridLines" in configParsed) return configParsed.gridLines;
    return false;
  }, [configParsed]);
  const gridColumns = useMemo(() => {
    if ("gridColumns" in configParsed) return configParsed.gridColumns;
    return false;
  }, [configParsed]);
  const stacked = useMemo(() => {
    if ("stacked" in configParsed) return configParsed.stacked;
    return false;
  }, [configParsed]);
  const dualAxesType = useMemo(() => {
    if ("dualAxesType" in configParsed && configParsed.dualAxesType)
      return configParsed.dualAxesType;
    return {};
  }, [configParsed]);
  const legend = useMemo(() => {
    if ("legend" in configParsed && configParsed.legend)
      return configParsed.legend;
    return "bottom";
  }, [configParsed]);
  const smooth = useMemo(() => {
    if ("smooth" in configParsed) return configParsed.smooth;
    return false;
  }, [configParsed]);
  const dashed = useMemo(() => {
    if (
      "dashed" in configParsed &&
      configParsed.dashed &&
      Array.isArray(configParsed.dashed)
    )
      return configParsed.dashed;
    return [];
  }, [configParsed]);
  const config = useMemo<DualAxesConfig>(() => {
    const parsed: TooltipData[][] = [[], []];
    let unitName: string[] = [];

    series.forEach((s, index) => {
      const groupBys = s.availableGroupBy ? JSON.parse(s.availableGroupBy) : {};
      const groupBy = groupBys[start] ?? s.groupBy;
      let idx = 0;
      if (s.name in dualAxesType && dualAxesType[s.name] === "line") idx = 1;

      const options = determineDateTimeOptions(
        s.lambdaFn && s.lambdaFn.indexOf("onlyLastDay") !== -1
          ? GroupByEnum.HOUR
          : s.lambdaFn && s.lambdaFn.indexOf("onlyLastWeek") !== -1
          ? GroupByEnum.WEEK_DAY
          : s.lambdaFn && s.lambdaFn.indexOf("onlyLastMonth") !== -1
          ? GroupByEnum.MONTH_DAY
          : start,
        granularity,
        groupBy,
        stop
      );
      const data = applyLambda(s);
      unitName.push(t(s.unit));
      unitName = unitName.filter((n, uidx, arr) => arr.indexOf(n) === uidx);
      data.forEach((r) => {
        parsed[idx].push({
          name: s.name,
          color: s.color ?? colors[index % colors.length],
          timestamp: valuePairLabel(r, options, groupBy),
          unitName: t(s.unit),
          value: r.value,
        });
      });
    });

    return {
      data: parsed,
      xField: "timestamp",
      height: 340,
      yField: ["value", "value"],
      geometryOptions: [
        {
          geometry: "column",
          columnWidthRatio: 0.4,
          seriesField: "name",
          isStack: stacked,
          isGroup: !stacked,
          groupField: "name",
        },
        {
          geometry: "line",
          seriesField: "name",
          smooth,
          isStack: stacked,
          lineStyle: (datum) =>
            dashed.indexOf(datum.name) === -1
              ? undefined
              : { lineDash: [10, 10] },
        },
      ],
      autoFit: true,
      tooltip: {
        domStyles: {
          "g2-tooltip": {
            background: "transparent",
            borderRadius: 0,
            padding: "0",
            boxShadow: "none",
          },
        },
        customContent: RenderCustomTooltip,
      },
      xAxis: {
        grid: {
          line: {
            style: {
              stroke: "black",
              lineWidth: gridColumns ? 0.1 : 0,
            },
          },
          alignTick: true,
          closed: true,
        },
      },

      yAxis: [
        {
          title: {
            text: unitName[0] ?? "",
            style: {
              stroke: "#000000",
              fontSize: 13,
              color: "#000000",
              letterSpacing: "1px",
              fontWeight: 300,
              opacity: 0.9,
              shadowBlur: 0,
              strokeOpacity: 0,
            },
          },
          grid: {
            line: {
              style: {
                stroke: "black",
                lineWidth: gridLines ? 0.1 : 0,
              },
            },
            alignTick: true,
            closed: true,
          },
        },
        {
          title: {
            text: unitName[1] ?? unitName[0] ?? "",
            style: {
              stroke: "#000000",
              fontSize: 13,
              color: "#000000",
              letterSpacing: "1px",
              fontWeight: 300,
              opacity: 0.9,
              shadowBlur: 0,
              strokeOpacity: 0,
            },
          },
        },
      ],
      padding: "auto",
      legend: {
        position: legend,
      },
    };
  }, [
    series,
    stacked,
    smooth,
    RenderCustomTooltip,
    gridColumns,
    gridLines,
    legend,
    start,
    dualAxesType,
    granularity,
    stop,
    t,
    colors,
    dashed,
  ]);
  const dispatch = useDispatch();
  const eventHandler = useCallback(
    (_: any, event: PlotEvent) => {
      if (event.type === "legend:click") {
        const key = event?.target?.cfg?.delegateObject?.item?.id;
        series.forEach((s) => {
          if (s.onClickCardId && key === s.name) {
            dispatch({
              type: ModalActionTypes.SHOW_MODAL,
              payload: s.onClickCardId,
            });
          }
        });
      }
    },
    [dispatch, series]
  );
  return <DualAxes {...config} animation={false} onEvent={eventHandler} />;
};

export default AntdDualAxisChart;
