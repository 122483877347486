/* eslint-disable array-callback-return */
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../../Styles/pages/adminDynamicSwitches.css";
import { Col, Divider, Form, notification, Row, Select } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { id } from "../../Util/utils";
import { RootState } from "../../redux/store";
import { Site, User } from "../../API/_generated";
import { getClients, getSitesOfUser } from "../../redux";
import { AdminSiteContext, AdminSiteContextType } from "../../Util/types";
import AdminCard from "../../Components/admin/AdminCard";
import AdminCardSmall from "../../Components/admin/AdminCardSmall";
import Mainlayout from "../../Layout/MainLayout";
import { AdminActionTypes } from "../../redux/types/adminTypes";
import { STD_GUTTER } from "../../Util/constants";

const { Option } = Select;

const AdminSiteContextWrapper: React.FC<
  PropsWithChildren<{ user: User; site: Site }>
> = ({ site, user, children }) => {
  const contextVal = useMemo<AdminSiteContextType>(
    () => ({
      userId: id(user),
      userSeries: user.series ?? [],
      site,
    }),
    [site, user]
  );
  return (
    <AdminSiteContext.Provider value={contextVal}>
      {children}
    </AdminSiteContext.Provider>
  );
};

const selectWidth = { width: 200 };
const AdminSites: React.FC<RouteComponentProps> = ({ match }) => {
  const { t } = useTranslation();
  const users = useSelector<RootState, RootState["adminReducer"]["users"]>(
    (state) => state.adminReducer.users
  );
  const sitesOfUsers = useSelector<
    RootState,
    RootState["adminReducer"]["sitesOfUser"]
  >((state) => state.adminReducer.sitesOfUser);
  const selectedSite = useSelector<
    RootState,
    RootState["adminReducer"]["currentSiteModifying"]
  >((state) => state.adminReducer.currentSiteModifying);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [editingCard, setEditingCard] = useState<string>();
  const editingCardVal = useMemo(() => {
    if (selectedSite && selectedSite.cards) {
      return selectedSite.cards.find((c) => id(c) === editingCard);
    }
    return undefined;
  }, [selectedSite, editingCard]);
  const isSaving = useRef(false);
  useEffect(() => {
    if (isSaving.current) {
      isSaving.current = false;
      notification.success({
        message: t("Save sucessful"),
        duration: 2,
      });
    }
  }, [sitesOfUsers, t]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (users.length === 0) getClients(dispatch);
  }, [dispatch, users]);
  const selectedSiteId = useMemo(
    () => (selectedSite ? id(selectedSite) : "-1"),
    [selectedSite]
  );
  const sites = useMemo(() => {
    if (!selectedUser) return undefined;
    if (id(selectedUser) in sitesOfUsers) {
      return sitesOfUsers[id(selectedUser)];
    }
    return undefined;
  }, [sitesOfUsers, selectedUser]);
  useEffect(() => {
    if (match.params && "userId" in match.params) {
      const { userId } = match.params;
      setSelectedUser(users.find((us) => id(us) === userId));
    }
  }, [match, users]);
  useEffect(() => {
    if ("siteId" in match.params && selectedUser && sites) {
      const { siteId } = match.params;
      const site = sites.find((s) => id(s) === siteId);
      dispatch({ type: AdminActionTypes.SET_MODIFYING_SITE, payload: site });
    }
  }, [dispatch, match, selectedUser, sites, users]);
  const setSelectedSiteId = useCallback(
    (siteId: string) => {
      if (selectedUser && sites) {
        const site = sites.find((s) => id(s) === siteId);
        dispatch({ type: AdminActionTypes.SET_MODIFYING_SITE, payload: site });
      }
    },
    [dispatch, selectedUser, sites]
  );
  const handleChangeUser = useCallback(
    (value: string) => {
      setSelectedUser(users.find((u) => id(u) === value));
    },
    [users]
  );
  useEffect(() => {
    if (selectedUser) {
      getSitesOfUser(dispatch, id(selectedUser));
    }
  }, [selectedUser, dispatch]);
  const onCloseCard = useCallback(() => {
    setEditingCard(undefined);
  }, []);
  const selectedCards = useMemo(() => {
    if (!selectedSite) return [];
    if (!selectedSite.cards) return [];
    const c = [...selectedSite.cards];
    return c.sort(
      (a, b) =>
        (a.hidden ? 100 + a.index : a.index) -
        (b.hidden ? 100 + b.index : b.index)
    );
  }, [selectedSite]);

  return (
    <Mainlayout active="admin-sites">
      <div className="admin-container-switches">
        <Row gutter={STD_GUTTER}>
          <Form layout="horizontal">
            <Col xs={6}>
              <Form.Item label={`${t("Client")}:`}>
                <Select
                  value={selectedUser ? id(selectedUser) : "-1"}
                  style={selectWidth}
                  onChange={handleChangeUser}
                >
                  <Option value="-1">{t("Choose...")}</Option>
                  {users.map((user) => (
                    <Option value={id(user)} key={`client-${id(user)}`}>
                      {user.firstname} {user.secondname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {!!sites && (
              <Col xs={6}>
                <Form.Item label={t("Site")}>
                  <Select
                    value={selectedSiteId}
                    style={selectWidth}
                    onChange={setSelectedSiteId}
                  >
                    <Option value="-1">{t("Choose...")}</Option>
                    {sites.map((site) => (
                      <Option value={id(site)} key={id(site)}>
                        {t(site.title)}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Form>
        </Row>
        {selectedUser && selectedSite && (
          <AdminSiteContextWrapper site={selectedSite} user={selectedUser}>
            <Row gutter={STD_GUTTER}>
              {editingCardVal ? (
                <AdminCard
                  card={editingCardVal}
                  onClose={onCloseCard}
                  maxIndex={selectedCards.length}
                />
              ) : (
                <>
                  {selectedCards.map((card) => (
                    <AdminCardSmall
                      cardId={id(card)}
                      key={id(card)}
                      onEdit={setEditingCard}
                    />
                  ))}
                  <Divider orientation="left">{t("New Card")}</Divider>
                  <AdminCardSmall cardId="-1" />
                </>
              )}
            </Row>
          </AdminSiteContextWrapper>
        )}
      </div>
    </Mainlayout>
  );
};

export default AdminSites;
