import { Reducer } from "redux";
import {
  ModalActions,
  ModalActionTypes,
  ModalState,
} from "../types/modalTypes";

const initialState: ModalState = {
  openCard: undefined,
};

const modalReducer: Reducer<ModalState> = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: ModalActions
) => {
  const { type } = action;
  switch (type) {
    case ModalActionTypes.SHOW_MODAL:
      return {
        ...state,
        openCard: action.payload,
      };
    case ModalActionTypes.HIDE_MODAL:
      return {
        ...state,
        openCard: undefined,
      };
    default:
      return state;
  }
};

export default modalReducer;
