/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Card } from '../models/Card';
import type { CardCreate } from '../models/CardCreate';
import type { CardUpdate } from '../models/CardUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CardService {

    /**
     * Get Card
     * @param cardId 
     * @returns Card Successful Response
     * @throws ApiError
     */
    public static getCardCardCardIdGet(
cardId: string,
): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/card/{card_id}',
            path: {
                'card_id': cardId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Card
     * @param cardId 
     * @param requestBody 
     * @returns Card Successful Response
     * @throws ApiError
     */
    public static updateCardCardCardIdPut(
cardId: string,
requestBody: CardUpdate,
): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/card/{card_id}',
            path: {
                'card_id': cardId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Card
     * @param cardId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteCardCardCardIdDelete(
cardId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/card/{card_id}',
            path: {
                'card_id': cardId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Card To Site
     * @param siteId 
     * @param requestBody 
     * @returns Card Successful Response
     * @throws ApiError
     */
    public static addCardToSiteCardPost(
siteId: string,
requestBody: CardCreate,
): CancelablePromise<Card> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/card/',
            query: {
                'site_id': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
