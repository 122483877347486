/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthResponse } from '../models/AuthResponse';
import type { Body_get_token_auth__post } from '../models/Body_get_token_auth__post';
import type { Credentials } from '../models/Credentials';
import type { PasswordChange } from '../models/PasswordChange';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {

    /**
     * Get Token
     * @param formData 
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static getTokenAuthPost(
formData: Body_get_token_auth__post,
): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/',
            formData: formData,
            mediaType: 'application/x-www-form-urlencoded',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Login
     * @param requestBody 
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static loginAuthLoginPost(
requestBody: Credentials,
): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/login',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Refresh Token
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static refreshTokenAuthRefreshPost(): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/refresh',
        });
    }

    /**
     * Change Password
     * @param requestBody 
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static changePasswordAuthChangePasswordPost(
requestBody: PasswordChange,
): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/change_password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Reset Password
     * @returns AuthResponse Successful Response
     * @throws ApiError
     */
    public static resetPasswordAuthResetPasswordPost(): CancelablePromise<AuthResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/reset_password',
        });
    }

    /**
     * Change User Password
     * @param userId 
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static changeUserPasswordAuthChangeUserPasswordUserIdPost(
userId: string,
requestBody: PasswordChange,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/auth/change_user_password/{user_id}',
            path: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
