import React, { useCallback, useEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Row } from "antd";
import { RootState } from "../redux/store";
import CardView from "../Components/cards/CardView";
import Loader from "../Components/Loader";
import { getMySites } from "../redux";
import { id } from "../Util/utils";
import Mainlayout from "../Layout/MainLayout";
import { STD_GUTTER } from "../Util/constants";
import { ModalActionTypes } from "../redux/types/modalTypes";

const modalStyle: React.CSSProperties = {
  bottom: "0px",
  top: "unset",
  maxWidth: "100%",
  maxHeight: "100%",
  width: "100%",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
  justifyContent: "flex-end",
  paddingBottom: 0,
};
const bodyStyle: React.CSSProperties = {
  bottom: "0px",
  width: "100%",
  maxWidth: "100%",
};

const SiteView: React.FC<RouteComponentProps> = ({ match }) => {
  const { sites, me } = useSelector<RootState, RootState["userReducer"]>(
    (state) => state.userReducer
  );
  const { openCard } = useSelector<RootState, RootState["modalReducer"]>(
    (state) => state.modalReducer
  );
  const site = useMemo(() => {
    if (match.params && "siteId" in match.params) {
      const { siteId } = match.params;
      return sites.find((s) => s.icon === siteId);
    }
    return sites.find((s) => s.icon === "dashboard");
  }, [sites, match]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!site && me) {
      getMySites(dispatch).then();
    }
  }, [site, me, dispatch]);
  const selectedCards = useMemo(() => {
    if (!site) return [];
    if (!site.cards) return [];
    const c = [...site.cards];
    return c.sort((a, b) => a.index - b.index).filter((car) => !car.hidden);
  }, [site]);
  const hiddenCards = useMemo(() => {
    if (!site) return [];
    if (!site.cards) return [];
    const c = [...site.cards];
    return c.filter((car) => !!car.hidden);
  }, [site]);
  const modalOpen = useMemo(
    () => hiddenCards.find((c) => id(c) === openCard),
    [openCard, hiddenCards]
  );
  const closeModal = useCallback(() => {
    dispatch({ type: ModalActionTypes.HIDE_MODAL });
  }, [dispatch]);

  if (!site) return <Loader />;

  return (
    <Mainlayout active={site.icon}>
      <div className={site.title}>
        <Row gutter={STD_GUTTER} align="stretch">
          {selectedCards.map((card) => (
            <CardView card={card} key={id(card)} />
          ))}
        </Row>
      </div>
      <Modal
        open={!!modalOpen}
        style={modalStyle}
        bodyStyle={bodyStyle}
        onCancel={closeModal}
        width="100%"
        footer={null}
      >
        <div className="card-modal">
          {modalOpen && <CardView card={modalOpen} />}
        </div>
      </Modal>
    </Mainlayout>
  );
};

export default SiteView;
