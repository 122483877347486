import React from "react";
import { useTranslation } from "react-i18next";

export declare type TooltipData = {
  unitName: string;
  timestamp: string;
  value: number;
  name: string;
  color: string;
  accumulatedSum?: number;
};

const pStyle = (col: string, opacity?: number) => ({
  background: col,
  opacity: Math.max(0.2, opacity ?? 1),
});
const CustomTooltipLine: React.FC<{
  opacity?: number;
  obj: TooltipData;
  unitName?: string;
}> = ({ obj, unitName, opacity }) => (
  <div className="custom-tooltip-line">
    <p style={pStyle(obj.color, opacity)} className="custom-tooltip-circle" />
    <p className="custom-tooltip-series">{obj.name}</p>
    <p className="custom-tooltip-value">
      {parseFloat(String(obj.value)).toFixed(2)} {unitName}
    </p>
  </div>
);

CustomTooltipLine.defaultProps = {
  unitName: undefined,
};

const CustomTooltip: React.FC<{
  title: string;
  data: TooltipData[];
  unitName?: string;
  opacity?: number;
}> = ({ title, opacity, unitName, data }) => {
  const { t } = useTranslation();
  return (
    <div className="custom-tooltip-container">
      <div className="custom-tooltip-polygon" />

      <h4 className="custom-tooltip-title">{title}</h4>

      {data.map((obj) => (
        <CustomTooltipLine
          obj={obj}
          unitName={unitName}
          opacity={opacity}
          key={`line-${obj.timestamp}-${obj.name}`}
        />
      ))}
      {data.length > 1 && (
        <div className="custom-tooltip-line">
          <p className="custom-tooltip-series">
            <span className="custom-tooltip-circle" />
            {t("Total")}
          </p>

          <p className="custom-tooltip-value">
            {`${data
              .reduce(
                (previousValue, currentValue) =>
                  previousValue + Number(currentValue.value),
                0
              )
              .toFixed(2)} ${unitName ?? ""}`}
          </p>
        </div>
      )}
    </div>
  );
};

CustomTooltip.defaultProps = {
  unitName: undefined,
};

export default CustomTooltip;
