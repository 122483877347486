/* eslint-disable array-callback-return */
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import "../../Styles/pages/adminDynamicSwitches.css";
import {
  Button,
  Col,
  Form,
  Input,
  notification,
  Row,
  Select,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { categoryName, id } from "../../Util/utils";
import { RootState } from "../../redux/store";
import { DataSeries } from "../../API/_generated";
import { getClients, getSitesOfUser, patchClient } from "../../redux";
import Mainlayout from "../../Layout/MainLayout";
import { STD_GUTTER } from "../../Util/constants";
import {
  AdminUserSeriesCategory,
  SeriesCategory,
} from "../../Components/admin/AdminUserSeries";
import {
  AdminDetailsContext,
  AdminDetailsContextType,
  CustomSeries,
} from "../../Util/types";

const { Option } = Select;

const selectWidth = { width: 200 };
const AdminDetails: React.FC<RouteComponentProps> = ({ match }) => {
  const { t } = useTranslation();
  const [selectedUserId, setSelectedUserId] = useState<string>("-1");
  const [newCategories, setNewCategories] = useState<string[]>([]);
  const [newCategory, setNewCategory] = useState<string>("");
  const users = useSelector<RootState, RootState["adminReducer"]["users"]>(
    (state) => state.adminReducer.users
  );
  const sitesOfUsers = useSelector<
    RootState,
    RootState["adminReducer"]["sitesOfUser"]
  >((state) => state.adminReducer.sitesOfUser);
  const selectedUser = useMemo(
    () => users.find((u) => id(u) === selectedUserId),
    [selectedUserId, users]
  );
  const sites = useMemo(() => {
    if (!selectedUser) return undefined;
    if (id(selectedUser) in sitesOfUsers) {
      return sitesOfUsers[id(selectedUser)];
    }
    return undefined;
  }, [sitesOfUsers, selectedUser]);
  const availSeries = useMemo(
    () =>
      sites
        ? sites.reduce<CustomSeries[]>(
            (prev, cur) =>
              prev.concat(
                cur.cards
                  ? cur.cards.reduce<CustomSeries[]>(
                      (prevInner, curInner) =>
                        prevInner.concat(
                          curInner.graphs
                            ? curInner.graphs.reduce<CustomSeries[]>(
                                (prevInnerInner, curInnerInner) =>
                                  prevInnerInner.concat(
                                    curInnerInner.series.map((s) => ({
                                      series: s,
                                      site: cur.icon,
                                      card: curInner.title,
                                    }))
                                  ),
                                []
                              )
                            : []
                        ),
                      []
                    )
                  : []
              ),
            []
          )
        : [],
    [sites]
  );
  const setNewCategoryCont = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setNewCategory(e.target.value);
  }, []);
  const saveNewCategory = useCallback(() => {
    setNewCategories((nc) => nc.concat([newCategory]));
    setNewCategory("");
  }, [newCategory]);
  const categorys = useMemo(
    () =>
      newCategories.concat(
        selectedUser && selectedUser.series
          ? selectedUser.series.map(categoryName)
          : []
      ),
    [newCategories, selectedUser]
  );
  const isSaving = useRef(false);
  useEffect(() => {
    if (isSaving.current) {
      isSaving.current = false;
      notification.success({
        message: t("Save sucessful"),
        duration: 2,
      });
    }
  }, [sitesOfUsers, t]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (users.length === 0) getClients(dispatch);
  }, [dispatch, users]);

  useEffect(() => {
    if (match.params && "userId" in match.params) {
      const { userId } = match.params;
      setSelectedUserId((userId as string) ?? "");
    }
  }, [match, users]);

  const handleChangeUser = useCallback((value: string) => {
    setSelectedUserId(value);
  }, []);
  useEffect(() => {
    if (selectedUser) {
      getSitesOfUser(dispatch, id(selectedUser));
    }
  }, [selectedUser, dispatch]);
  const series = useMemo(() => {
    if (!selectedUser) return [];
    return selectedUser.series ?? [];
  }, [selectedUser]);
  const selectedSeries = useMemo(() => {
    const retval: SeriesCategory[] = [];
    series.forEach((s) => {
      const n = categoryName(s);
      const idx = retval.findIndex((cat) => cat.cat === n);
      if (idx === -1) {
        retval.push({ cat: n, series: [s] });
      } else {
        retval[idx].series.push(s);
      }
    });
    categorys.forEach((cat) => {
      const idx = retval.findIndex((c) => c.cat === cat);
      if (idx === -1) {
        retval.push({ cat, series: [] });
      }
    });
    return retval;
  }, [categorys, series]);
  const contextVal = useMemo<AdminDetailsContextType>(
    () => ({
      availSeries,
    }),
    [availSeries]
  );
  const save = useCallback(
    (newS: DataSeries[]) => {
      if (selectedUser) {
        patchClient(dispatch, id(selectedUser), {
          series: newS,
        });
      }
    },
    [dispatch, selectedUser]
  );
  const onChangeSeries = useCallback(
    (cat: string, sId: string, index: number) => {
      const newCustom = availSeries.find((s) => id(s.series) === sId);
      const oldSeries = selectedSeries.find((s) => s.cat === cat)?.series[
        index
      ];
      if (newCustom && oldSeries) {
        const newSeries = series.map((s) =>
          id(s) === id(oldSeries)
            ? ({
                ...newCustom.series,
                _id: undefined,
                name: `${cat}#:${newCustom.series.name}`,
              } as DataSeries)
            : s
        );
        save(newSeries);
      }
    },
    [availSeries, save, selectedSeries, series]
  );
  const onAddSeries = useCallback(
    (cat: string, sId: string) => {
      const newCustom = availSeries.find((s) => id(s.series) === sId);
      if (newCustom) {
        const newSeries: DataSeries = { ...newCustom.series };
        newSeries._id = undefined;
        newSeries.name = `${cat}#:${newSeries.name}`;
        save(series.concat([newSeries]));
      }
    },
    [availSeries, save, series]
  );
  const onDelSeries = useCallback(
    (cat: string, index: number) => {
      const oldSeries = selectedSeries.find((s) => s.cat === cat)?.series[
        index
      ];
      if (oldSeries) {
        const newSeries = series.filter((s) => id(s) !== id(oldSeries));
        save(newSeries);
      }
    },
    [save, selectedSeries, series]
  );
  return (
    <Mainlayout active="admin-sites">
      <div className="admin-container-switches">
        <Form layout="horizontal">
          <Row gutter={STD_GUTTER}>
            <Col xs={24}>
              <Form.Item label={`${t("Client")}:`}>
                <Select
                  value={selectedUser ? id(selectedUser) : "-1"}
                  style={selectWidth}
                  onChange={handleChangeUser}
                >
                  <Option value="-1">{t("Choose...")}</Option>
                  {users.map((user) => (
                    <Option value={id(user)} key={`client-${id(user)}`}>
                      {user.firstname} {user.secondname}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Form.Item label={t("New Category")}>
                <Input
                  type="text"
                  value={newCategory}
                  onChange={setNewCategoryCont}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
              <Button onClick={saveNewCategory} type="primary">
                {t("save")}
              </Button>
            </Col>
          </Row>
          {selectedUser && (
            <AdminDetailsContext.Provider value={contextVal}>
              {selectedSeries.map((s) => (
                <AdminUserSeriesCategory
                  cat={s}
                  key={`cat-${s.cat}`}
                  changeSeries={onChangeSeries}
                  addSeries={onAddSeries}
                  delSeries={onDelSeries}
                />
              ))}
              {selectedSeries.length === 0 && (
                <Col xs={24}>
                  <Typography.Text type="danger">
                    {t("No Dataseries for Details")}
                  </Typography.Text>
                </Col>
              )}
            </AdminDetailsContext.Provider>
          )}
        </Form>
      </div>
    </Mainlayout>
  );
};

export default AdminDetails;
