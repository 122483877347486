/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggregateValuePairList } from '../models/AggregateValuePairList';
import type { EkosSensor } from '../models/EkosSensor';
import type { Filter } from '../models/Filter';
import type { GroupByEnum } from '../models/GroupByEnum';
import type { ValuePair } from '../models/ValuePair';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class MeasurmentService {

    /**
     * Get Aggregate Values
     * @param bucket 
     * @param start 
     * @param stop 
     * @param aggregateWindow 
     * @param _function 
     * @param name 
     * @param requestBody 
     * @param createEmpty 
     * @param customWindow 
     * @param groupBy 
     * @returns AggregateValuePairList Successful Response
     * @throws ApiError
     */
    public static getAggregateValuesMeasurmentGetAggregateValuesPost(
bucket: string,
start: string,
stop: string,
aggregateWindow: string,
_function: string,
name: string,
requestBody: Array<Filter>,
createEmpty: boolean = false,
customWindow: boolean = false,
groupBy?: GroupByEnum,
): CancelablePromise<AggregateValuePairList> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/measurment/get_aggregate_values',
            query: {
                'bucket': bucket,
                'start': start,
                'stop': stop,
                'aggregate_window': aggregateWindow,
                'function': _function,
                'name': name,
                'create_empty': createEmpty,
                'custom_window': customWindow,
                'group_by': groupBy,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Nearest Value
     * @param node 
     * @param measurement 
     * @param timestamp 
     * @returns ValuePair Successful Response
     * @throws ApiError
     */
    public static getNearestValueMeasurmentGetNearestValueGet(
node: string,
measurement: string,
timestamp?: string,
): CancelablePromise<ValuePair> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/measurment/get_nearest_value',
            query: {
                'node': node,
                'measurement': measurement,
                'timestamp': timestamp,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Last Ekos Value
     * @param token 
     * @returns EkosSensor Successful Response
     * @throws ApiError
     */
    public static getLastEkosValueMeasurmentLastEkosValueTokenGet(
token: string,
): CancelablePromise<Array<EkosSensor>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/measurment/last_ekos_value/{token}',
            path: {
                'token': token,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
