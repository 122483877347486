import { combineReducers } from "redux";

import userReducer from "./reducers/userReducer";
import homeReducer from "./reducers/homeReducer";
import serverReducer from "./reducers/serverReducer";
import adminReducer from "./reducers/adminReducer";
import influxDataReducer from "./reducers/influxDataReducer";
import modalReducer from "./reducers/modalReducer";

const rootReducer = combineReducers({
  userReducer,
  homeReducer,
  serverReducer,
  adminReducer,
  influxDataReducer,
  modalReducer,
});

export default rootReducer;
