import React from "react";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./redux/store";
import register from "./registerServiceWorker";

Sentry.init({
  dsn: "https://cda8629332b8491a9030e862daad73b5@sentry.kivi.bz.it/39",
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 0.1,
});
const root = document.getElementById("root");
if (root) {
  createRoot(root).render(
    <Provider store={store}>
      <App />
    </Provider>
  );
}
register();
reportWebVitals();
