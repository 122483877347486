/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum InterpolationEnum {
    NONE = 'NONE',
    LINEAR = 'LINEAR',
    CUBIC = 'CUBIC',
    POLYNOMIAL = 'POLYNOMIAL',
}
