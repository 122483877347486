import { Reducer } from 'redux';
import { ServerActions, ServerActionTypes, ServerState } from '../types/serverTypes';

const initialState: ServerState = {
  available: true,
};

// eslint-disable-next-line default-param-last
const serverReducer: Reducer<ServerState> = (state = initialState, action: ServerActions) => {
  const { type } = action;
  switch (type) {
    case ServerActionTypes.GONE:
      return {
        ...state,
        available: false,
      };
    case ServerActionTypes.AVAILABLE: {
      return {
        ...state,
        available: true,
      };
    }

    default:
      return state;
  }
};

export default serverReducer;
