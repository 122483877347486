import React, { useEffect, useState } from "react";
import { initReactI18next } from "react-i18next";
import { useDispatch } from "react-redux";
import { notification } from "antd";
import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import Routes from "./Routes";
import "antd/dist/antd.less";
import { switchTheme } from "./defaultTheme";
import "./Styles/styles.css";
import "moment-timezone";
import { getClient, getMySites } from "./redux";
import {
  LOCAL_STORAGE_LANG,
  LOCAL_STORAGE_THEME,
  LOCAL_STORAGE_TOKEN,
  PROD_ENDPOINT,
} from "./Util/constants";
// eslint-disable-next-line import/extensions
import "intl/locale-data/jsonp/en-GB.js";
// eslint-disable-next-line import/extensions
import "intl/locale-data/jsonp/de-AT.js";
// eslint-disable-next-line import/extensions
import "intl/locale-data/jsonp/it-IT.js";
import Loader from "./Components/Loader";

function App() {
  const dispatch = useDispatch();
  const [langInitialized, setLangInitialized] = useState(false);
  const [userInitialized, setUserInitialized] = useState(false);
  useEffect(() => {
    i18n
      .use(Backend)
      .use(LanguageDetector)
      .use(initReactI18next)
      .init({
        fallbackLng: "en",
        debug: true,
        react: {
          useSuspense: false,
        },
        interpolation: {
          escapeValue: false,
        },
        backend: {
          crossDomain: true,
          loadPath: `https://${PROD_ENDPOINT}/translation/{{lng}}`,
        },
      })
      .then(() => {
        const lang = localStorage.getItem(LOCAL_STORAGE_LANG);
        if (lang) {
          i18n.changeLanguage(lang).then(() => {
            setLangInitialized(true);
          });
        } else {
          setLangInitialized(true);
        }
      });
    if (!navigator.onLine) {
      notification.error({
        message: "No or Slow Internet Connection",
        duration: 5,
      });
    }
    const theme = localStorage.getItem(LOCAL_STORAGE_THEME);
    if (theme) {
      try {
        const themeConfig = JSON.parse(theme);
        if (themeConfig) {
          switchTheme(themeConfig.mode);
          return;
        }
      } catch (e) {
        /*
         * Stay Default Theme
         */
      }
    }
    switchTheme();
  }, []);

  useEffect(() => {
    if (localStorage.getItem(LOCAL_STORAGE_TOKEN) && langInitialized)
      getClient(dispatch).then(
        () => {
          getMySites(dispatch).then(() => {
            setUserInitialized(true);
          });
        },
        () => {
          setUserInitialized(true);
        }
      );
    else if (langInitialized) {
      setUserInitialized(true);
    }
  }, [dispatch, langInitialized]);

  if (!langInitialized || !userInitialized) return <Loader size="large" />;
  return <Routes />;
}

export default App;
