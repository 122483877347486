import { Action, ActionCreator, applyMiddleware } from "redux";
import thunk, { ThunkAction } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";

const store = configureStore({
  reducer: rootReducer,
  enhancers: [composeWithDevTools(applyMiddleware(thunk))],
});
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ActionCreator<
  ThunkAction<void, RootState, null, Action<string>>
>;

export default store;
