import { Site, User } from "../../API/_generated";

export type Restrictions = {
  live: boolean;
  energyProduction: boolean;
  loadPeak: boolean;
  digitalization: boolean;
  eMobility: boolean;
  analysis: boolean;
  co2emission: boolean;
  details: boolean;
};

export type UserState = {
  readonly me: User | null;
  readonly status: "idle" | "loading" | "failed";
  readonly colors: string[];
  readonly popupGraph: string | null;
  readonly barColors: string[];
  readonly restrictions: Restrictions | null;
  readonly livePageConfig: null;
  readonly displayMask: boolean;
  readonly displayAnnotation: boolean;
  readonly displayAnnotationSub: boolean;
  readonly measNodes: string[];
  readonly sites: Site[];
  readonly isAdmin: boolean;
};

export enum UserActionTypes {
  LOGIN = "@@user/LOGIN",
  LOGIN_FAILED = "@@user/LOGIN_FAILED",
  PATCH_FAILED = "@@user/PATCH_FAILED",
  UNAUTHORIZED = "@@user/UNAUTHORIZED",
  DISPLAY_MASK = "@@user/DISPLAY_MASK",
  SET_USER_COLORS = "SET_USER_COLORS",
  SET_USER_RESTRICTIONS = "SET_USER_RESTRICTIONS",
  SET_BAR_COLORS = "SET_BAR_COLORS",
  SET_LIVE_PAGE_CONFIG = "SET_LIVE_PAGE_CONFIG",
  GET_ANALYSIS_DATA = "GET_ANALYSIS_DATA",
  SET_DISPLAY_ANNOTATION = "SET_DISPLAY_ANNOTATION",
  SET_DISPLAY_ANNOTATION_SUB = "SET_DISPLAY_ANNOTATION_SUB",
  SET_MEAN_NODES = "SET_MEAN_NODES",
  SET_SUBNODES_VALUES_FROM_INFLUX = "SET_SUBNODES_VALUES_FROM_INFLUX",
  GET_SITES = "@@user/GET_SITES",
  SET_POPUP_GRAPH = "@@user/SET_POPUP_GRAPH",
}

export type UserActions =
  | {
      type:
        | UserActionTypes.DISPLAY_MASK
        | UserActionTypes.LOGIN_FAILED
        | UserActionTypes.PATCH_FAILED;
    }
  | {
      type: UserActionTypes.SET_POPUP_GRAPH;
      payload: string | null;
    }
  | {
      type: UserActionTypes.SET_USER_COLORS | UserActionTypes.SET_BAR_COLORS;
      payload: string[];
    }
  | {
      type: UserActionTypes.SET_USER_RESTRICTIONS;
      payload: Restrictions;
    }
  | {
      type: UserActionTypes.GET_SITES;
      payload: Site[];
    }
  | {
      type:
        | UserActionTypes.SET_DISPLAY_ANNOTATION_SUB
        | UserActionTypes.SET_DISPLAY_ANNOTATION;
      payload: boolean;
    }
  | {
      type: UserActionTypes.SET_LIVE_PAGE_CONFIG;
      payload: null;
    }
  | {
      type: UserActionTypes.SET_MEAN_NODES;
      payload: [];
    }
  | {
      type: UserActionTypes.LOGIN;
      payload: User;
    };
