import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { RangeEnum } from "../../../API/_generated";
import { dateLabel, determineDateTimeOptions, id } from "../../../Util/utils";
import { RootState } from "../../../redux/store";
import {
  applyLambda,
  DataSeriesResponse,
  getSeriesColor,
} from "../../../redux/types/influxDataTypes";
import HeatmapRow, { HeatMapRow } from "./HeatmapRow";
import HeatmapLabel, { HeatmapLabelType } from "./HeatmapLabel";
import { GraphCustomConfig } from "../../../Util/types";

/*
type AnnotationProps = {
  item: HeatMapCell;
  opacity: number;
  title: string;
  clientX: number;
  clientY: number;
  color: string;
  options: Intl.DateTimeFormatOptions;
};

const Annotation: React.FC<AnnotationProps> = ({
  item,
  title,
  options,
  color,
  clientX,
  opacity,
  clientY,
}) => (
  <div
    style={{
      filter: "drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5))",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flex: 1,
      height: 65,
      width: 240,
      boxShadow: "rgb(174 174 174) 0px 0px 10px",
      transition:
        "left 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s, top 0.4s cubic-bezier(0.23, 1, 0.32, 1) 0s",
      color: "rgb(89, 89, 89)",
      backgroundColor: "white",
      fontFamily: "Poppins",
      // color: "white",
      //   // left: clientX,
      left: clientX - 100,
      top: clientY - 100,
      position: "fixed",
      borderRadius: "4px",
      padding: "0 20px",
      fontSize: "10px",
      paddingTop: "5px",
      opacity: 1,
      zIndex: 100000,
    }}
  >
    <div
      className="tooltip-position"
      style={{ bottom: "-59px", marginLeft: "-15px" }}
    />
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      {valuePairLabel(item, options)}
    </div>
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <p
        style={{
          background: color,
          width: 11,
          height: 11,
          borderRadius: "50%",
          opacity,
          marginBottom: 0,
          marginRight: 10,
        }}
      />
      <p
        style={{
          marginBottom: 0,
          width: "45%",
          fontWeight: "500",
          fontSize: "13px",
          color: "black",
        }}
      >
        {title}
      </p>
      <p
        style={{
          color: "black",
          fontWeight: 400,
          // color: "#7E84A3",
          fontSize: "12px",
          marginBottom: 0,
        }}
      >
        {item.value.toLocaleString("de-DE")} {item.unitName}
      </p>
    </div>
  </div>
);
*/

type HeatmapProps = {
  series: DataSeriesResponse[];
  start: string;
  stop: string;
  granularity: RangeEnum;
  customConfig?: string;
};

const CustomHeatmap: React.FC<HeatmapProps> = ({
  series,
  start,
  stop,
  granularity,
  customConfig,
}) => {
  const colors = useSelector<RootState, RootState["userReducer"]["barColors"]>(
    (state) => state.userReducer.barColors
  );
  const uniqueId = useMemo(
    () =>
      series.reduce<string>((prev, current) => `${prev}-${id(current)}`, ""),
    [series]
  );
  const groupBy = useMemo(() => {
    if (series.length) {
      if (series[0].availableGroupBy) {
        const groupBys = JSON.parse(series[0].availableGroupBy);
        return groupBys[start] ?? series[0].groupBy;
      }
      return series[0].groupBy;
    }
    return undefined;
  }, [series, start]);
  const options = useMemo(
    () => determineDateTimeOptions(start, granularity, groupBy),
    [start, granularity, groupBy]
  );

  const configParsed = useMemo<GraphCustomConfig>(() => {
    if (!customConfig) return {};
    try {
      return JSON.parse(customConfig);
    } catch (e) {
      return {};
    }
  }, [customConfig]);

  const seriesSameColor = useMemo(() => {
    if ("seriesSameColor" in configParsed) return configParsed.seriesSameColor;
    return true;
  }, [configParsed]);
  const [data, maxValue] = useMemo(() => {
    const res: HeatMapRow[] = [];
    let max = 0;
    series.forEach((s, index) => {
      res.push({
        name: s.name,
        onClickCardId: s.onClickCardId,
        values: [],
      });
      const applied = applyLambda(s);
      applied.forEach((r) => {
        if (r.value > max) max = r.value;
        res[res.length - 1].values.push({
          name: s.name,
          unitName: s.unit,
          value: r.value,
          color: getSeriesColor(s.color, seriesSameColor ? 0 : index, colors),
          timestamp: dateLabel(r.timestamp, options),
        });
      });
    });
    return [res, max];
  }, [colors, options, series, seriesSameColor]);

  const labelValues = useMemo<HeatmapLabelType[]>(() => {
    if (data.length === 0) return [];
    const eachIdx = Math.floor(data[0].values.length / 10);
    return data[0].values.reduce<HeatmapLabelType[]>((prev, current, index) => {
      if (index % eachIdx === Math.floor(eachIdx / 2))
        return prev.concat([
          {
            label: current.timestamp,
            values: data.map((d) => d.values[index]),
          },
        ]);
      return prev;
    }, []);
  }, [data]);

  const legend = useMemo(() => {
    if ("legend" in configParsed && configParsed.legend)
      return configParsed.legend;
    return "bottom";
  }, [configParsed]);
  return (
    <div className="heatmap-container">
      {legend === "top" && data.length !== 0 && (
        <div className="heatmap-row">
          <div className="label"> </div>
          <div className="heatmap-cells">
            {labelValues.map((label) => (
              <HeatmapLabel
                item={label}
                key={`${uniqueId}-row-${label.label}-label`}
              />
            ))}
          </div>
        </div>
      )}
      {data.map((item) => (
        <HeatmapRow
          uniqueId={uniqueId}
          item={item}
          rows={data.length}
          maxValue={maxValue}
          key={`${uniqueId}-row-${item.name}`}
        />
      ))}
      {legend === "bottom" && data.length !== 0 && (
        <div className="heatmap-row">
          <div className="label"> </div>
          <div className="heatmap-cells">
            {labelValues.map((label) => (
              <HeatmapLabel
                item={label}
                key={`${uniqueId}-row-${label.label}-label-${label.values
                  .map((v) => v.value)
                  .join("-")}`}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
// TODO Heatmap labels depending on window width
export default CustomHeatmap;
