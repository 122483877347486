export type ServerState = {
    readonly available: boolean;
};

export enum ServerActionTypes {
    GONE = '@@server/GONE',
    AVAILABLE = '@@server/AVAILABLE',
}

export type ServerActions = {
    type: ServerActionTypes.GONE|ServerActionTypes.AVAILABLE
};
