import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row } from "antd";
import { Filter } from "../../API/_generated";
import { STD_GUTTER } from "../../Util/constants";
import { sameFilter } from "../../Util/utils";

type InfluxFilterProps = {
  bucket: string;
  selected: Filter;
  onChange: (val: Filter, index: number) => void;
  onDelete: (index: number) => void;
  index: number;
};

const AdminInfluxFilter: React.FC<InfluxFilterProps> = ({
  selected,
  bucket,
  onChange,
  onDelete,
  index,
}) => {
  /* const filters = useSelector<
      RootState,
      RootState["adminReducer"]["filterOfBuckets"]
    >((state) => state.adminReducer.filterOfBuckets);
    const [selectedFilter, setSelectedFilter] = useState<TempFilter>();
    const filtersOfBucket = useMemo(() => {
      if (bucket in filters) {
        setSelectedFilter(
          selected
            ? filters[bucket].find((f) => f.key === selected.key)
            : filters[bucket][0]
        );
        return filters[bucket];
      }
      return undefined;
    }, [bucket, filters]);

    const dispatch = useDispatch();
    useEffect(() => {
      if (!filtersOfBucket) {
        getFilters(dispatch, bucket);
      }
    }, [filtersOfBucket, bucket, dispatch]);



    const setFilter = useCallback(
      (e: ChangeEvent<HTMLSelectElement>) => {
        setSelectedFilter(filtersOfBucket?.find((f) => f.key === e.target.value));
      },
      [filtersOfBucket]
    );
    const onChangeFilter = useCallback(
      (e: RadioChangeEvent) => {
        if (selectedFilter)
          onChange({ key: selectedFilter.key, value: e.target.value }, index);
      },
      [onChange, selectedFilter]
    );
    if (!filtersOfBucket) {
      return <Loader />;
    }
    return (
      <Col xs={3} className="select-filter">
        <Select id="key" onChange={setFilter}>
          {filtersOfBucket.map((f) => (
            <Select.Option value={f.key} key={f.key}>
              {f.key}
            </Select.Option>
          ))}
        </Select>
        {!!selectedFilter &&
          selectedFilter.value.map((val) => (
            <Radio
              key={selectedFilter.key}
              value={val}
              onChange={onChangeFilter}
            />
          ))}
      </Col>
    );*/
  const [formData, setFormData] = useState<Filter>({
    key: selected?.key ?? "",
    value: selected?.value ?? "",
  });
  const { t } = useTranslation();
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((fd) => ({ ...fd, [e.target.name]: e.target.value }));
  }, []);
  const handleDelete = useCallback(() => {
    onDelete(index);
  }, [onDelete, index]);
  const handleSubmit = useCallback(() => {
    onChange(formData, index);
    if (!selected) {
      setFormData({ key: "", value: "" });
    }
  }, [onChange, formData, index, selected]);
  const dataChanged = useMemo(
    () => !sameFilter(selected, formData),
    [selected, formData]
  );
  return (
    <Form layout="vertical" onFinish={handleSubmit}>
      <Row gutter={STD_GUTTER}>
        <Col xs={24}>
          <Form.Item label={t("Filter Key")}>
            <Input
              name="key"
              placeholder="_measurement"
              value={formData.key}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={STD_GUTTER}>
        <Col xs={24}>
          <Form.Item label={t("Filter Value")}>
            <Input
              name="value"
              placeholder="pv-production"
              value={formData.value}
              onChange={handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={STD_GUTTER} justify="center">
        <Col>
          <Button type="primary" disabled={!dataChanged} onClick={handleSubmit}>
            {t("Save Filter")}
          </Button>
        </Col>
      </Row>
      <Row gutter={STD_GUTTER} justify="center">
        <Col>
          <Button type="link" onClick={handleDelete}>
            {t("Delete Filter")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AdminInfluxFilter;
