import React, { useCallback, useMemo } from "react";
import { Column, ColumnConfig, PlotEvent } from "@ant-design/plots";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { GroupByEnum, RangeEnum } from "../../API/_generated";
import { RootState } from "../../redux/store";
import { determineDateTimeOptions, valuePairLabel } from "../../Util/utils";
import {
  applyLambda,
  DataSeriesResponse,
  sameLength,
} from "../../redux/types/influxDataTypes";
import CustomTooltip, { TooltipData } from "./CustomTooltip";
import { GraphCustomConfig } from "../../Util/types";
import { ModalActionTypes } from "../../redux/types/modalTypes";

type ColumnChartProps = {
  series: DataSeriesResponse[];
  start: string;
  stop: string;
  granularity: RangeEnum;
  customConfig?: string;
  seriesSameLength: boolean | undefined;
};
const ColumnChart: React.FC<ColumnChartProps> = ({
  series,
  start,
  stop,
  granularity,
  seriesSameLength,
  customConfig,
}) => {
  const colors = useSelector<RootState, RootState["userReducer"]["barColors"]>(
    (state) => state.userReducer.barColors
  );
  const { t } = useTranslation();
  const RenderCustomTooltip = useCallback(
    (title: string, data: TooltipData[]) => (
      <CustomTooltip title={title} data={data} />
    ),
    []
  );
  const configParsed = useMemo<GraphCustomConfig>(() => {
    if (!customConfig) return {};
    try {
      return JSON.parse(customConfig);
    } catch (e) {
      return {};
    }
  }, [customConfig]);
  const gridLines = useMemo(() => {
    if ("gridLines" in configParsed) return configParsed.gridLines;
    return false;
  }, [configParsed]);
  const gridColumns = useMemo(() => {
    if ("gridColumns" in configParsed) return configParsed.gridColumns;
    return false;
  }, [configParsed]);
  const stacked = useMemo(() => {
    if ("stacked" in configParsed) return configParsed.stacked;
    return false;
  }, [configParsed]);
  const legend = useMemo(() => {
    if ("legend" in configParsed && configParsed.legend)
      return configParsed.legend;
    return "bottom";
  }, [configParsed]);
  const config = useMemo<ColumnConfig>(() => {
    const parsed: TooltipData[] = [];
    let unitName = "";

    series.forEach((s, index) => {
      const groupBys = s.availableGroupBy ? JSON.parse(s.availableGroupBy) : {};
      const groupBy = groupBys[start] ?? s.groupBy;

      const options = determineDateTimeOptions(
        s.lambdaFn && s.lambdaFn.indexOf("onlyLastDay") !== -1
          ? GroupByEnum.HOUR
          : s.lambdaFn && s.lambdaFn.indexOf("onlyLastWeek") !== -1
          ? GroupByEnum.WEEK_DAY
          : s.lambdaFn && s.lambdaFn.indexOf("onlyLastMonth") !== -1
          ? GroupByEnum.MONTH_DAY
          : start,
        granularity,
        groupBy,
        stop
      );
      const data = applyLambda(s);
      unitName = t(s.unit);
      data.forEach((r) => {
        parsed.push({
          name: s.name,
          color: s.color ?? colors[index % colors.length],
          timestamp: valuePairLabel(r, options, groupBy),
          unitName,
          value: r.value,
        });
      });
    });

    return {
      data: seriesSameLength ? sameLength(parsed, series.length) : parsed,
      isStack: stacked,
      isGroup: !stacked,
      xField: "timestamp",
      height: 340,
      yField: "value",
      color: (a) => parsed.find((p) => p.name === a.name)?.color ?? colors[0],
      seriesField: "name",
      groupField: "name",
      autoFit: true,
      tooltip: {
        domStyles: {
          "g2-tooltip": {
            background: "transparent",
            borderRadius: 0,
            padding: "0",
            boxShadow: "none",
          },
        },
        customContent: RenderCustomTooltip,
      },
      xAxis: {
        grid: {
          line: {
            style: {
              stroke: "black",
              lineWidth: gridColumns ? 0.1 : 0,
            },
          },
          alignTick: true,
          closed: true,
        },
      },

      yAxis: {
        title: {
          text: unitName,
          style: {
            stroke: "#000000",
            fontSize: 13,
            color: "#000000",
            letterSpacing: "1px",
            fontWeight: 300,
            opacity: 0.9,
            shadowBlur: 0,
            strokeOpacity: 0,
          },
        },
        grid: {
          line: {
            style: {
              stroke: "black",
              lineWidth: gridLines ? 0.1 : 0,
            },
          },
          alignTick: true,
          closed: true,
        },
      },
      padding: "auto",

      legend: {
        position: legend,
      },
    };
  }, [
    series,
    seriesSameLength,
    stacked,
    RenderCustomTooltip,
    gridColumns,
    gridLines,
    legend,
    start,
    granularity,
    stop,
    t,
    colors,
  ]);
  const dispatch = useDispatch();
  const eventHandler = useCallback(
    (_: any, event: PlotEvent) => {
      if (event.type === "legend:click") {
        const key = event?.target?.cfg?.delegateObject?.item?.id;
        series.forEach((s) => {
          if (s.onClickCardId && key === s.name) {
            dispatch({
              type: ModalActionTypes.SHOW_MODAL,
              payload: s.onClickCardId,
            });
          }
        });
      }
    },
    [dispatch, series]
  );
  return <Column {...config} animation={false} onEvent={eventHandler} />;
};

export default ColumnChart;
