import { Gutter } from "antd/es/grid/row";
import {
  Dropdown,
  GroupByEnum,
  InterpolationEnum,
  RangeEnum,
} from "../API/_generated";

export const PROD_ENDPOINT = "api2.en-expert.com";

export const LOCAL_STORAGE_LANG = "enexpert-lang";
export const LOCAL_STORAGE_TOKEN = "enexpert-token";
export const LOCAL_STORAGE_REFRESH_TOKEN = "enexpert-refresh-token";
export const LOCAL_STORAGE_THEME = "enexpert-theme2";

export const DEFAULT_DROPDOWN: Dropdown = {
  start: RangeEnum._1D,
  stop: "now()",
  label: "1 hour",
  granularity: RangeEnum._1H,
  standard: true,
};
export const LAST_FULL_DAY = "Last full day";
export const LAST_FULL_WEEK = "Last full week";
export const LAST_FULL_MONTH = "Last full month";
export const LAST_FULL_YEAR = "Last full year";
export const THIS_DAY = "This day";
export const THIS_WEEK = "This week";
export const THIS_MONTH = "This month";
export const THIS_YEAR = "This year";
export const AVERAGE_DAY = "Average day";
export const AVERAGE_WEEK = "Average week";
export const AVERAGE_MONTH = "Average month";
export const AVERAGE_YEAR = "Average year";
export const RANGE_DROPDOWN_OPTIONS = [
  { label: RangeEnum._2H, value: RangeEnum._2H },
  { label: RangeEnum._6H, value: RangeEnum._6H },
  { label: RangeEnum._12H, value: RangeEnum._12H },
  { label: RangeEnum._1D, value: RangeEnum._1D },
  { label: RangeEnum._2D, value: RangeEnum._2D },
  { label: RangeEnum._3D, value: RangeEnum._3D },
  { label: RangeEnum._7D, value: RangeEnum._7D },
  { label: RangeEnum._30D, value: RangeEnum._30D },
  { label: RangeEnum._90D, value: RangeEnum._90D },
  { label: RangeEnum._365D, value: RangeEnum._365D },
  { label: LAST_FULL_DAY, value: LAST_FULL_DAY },
  { label: LAST_FULL_WEEK, value: LAST_FULL_WEEK },
  { label: LAST_FULL_MONTH, value: LAST_FULL_MONTH },
  { label: LAST_FULL_YEAR, value: LAST_FULL_YEAR },
  { label: THIS_DAY, value: THIS_DAY },
  { label: THIS_WEEK, value: THIS_WEEK },
  { label: THIS_MONTH, value: THIS_MONTH },
  { label: THIS_YEAR, value: THIS_YEAR },
];
export const AGGREGATE_DROPDOWN_OPTIONS = [
  { label: RangeEnum._1M, value: RangeEnum._1M },
  { label: RangeEnum._2M, value: RangeEnum._2M },
  { label: RangeEnum._5M, value: RangeEnum._5M },
  { label: RangeEnum._15M, value: RangeEnum._15M },
  { label: RangeEnum._30M, value: RangeEnum._30M },
  { label: RangeEnum._1H, value: RangeEnum._1H },
  { label: RangeEnum._2H, value: RangeEnum._2H },
  { label: RangeEnum._4H, value: RangeEnum._4H },
  { label: RangeEnum._6H, value: RangeEnum._6H },
  { label: RangeEnum._12H, value: RangeEnum._12H },
  { label: RangeEnum._1D, value: RangeEnum._1D },
  { label: RangeEnum._2D, value: RangeEnum._2D },
  { label: RangeEnum._3D, value: RangeEnum._3D },
  { label: RangeEnum._7D, value: RangeEnum._7D },
  { label: RangeEnum._30D, value: RangeEnum._30D },
  { label: RangeEnum._90D, value: RangeEnum._90D },
];
export const GROUP_BY_OPTIONS = [
  { label: "none", value: "" },
  { label: GroupByEnum.HOUR, value: GroupByEnum.HOUR },
  { label: GroupByEnum.MONTH_DAY, value: GroupByEnum.MONTH_DAY },
  { label: GroupByEnum.WEEK_DAY, value: GroupByEnum.WEEK_DAY },
  { label: GroupByEnum.MONTH, value: GroupByEnum.MONTH },
];

export const GRANULARITY = {
  [RangeEnum._2H]: RangeEnum._1H,
  [RangeEnum._6H]: RangeEnum._5M,
  [RangeEnum._12H]: RangeEnum._15M,
  [RangeEnum._1D]: RangeEnum._30M,
  [RangeEnum._2D]: RangeEnum._1H,
  [RangeEnum._3D]: RangeEnum._1H,
  [RangeEnum._7D]: RangeEnum._12H,
  [RangeEnum._30D]: RangeEnum._1D,
  [RangeEnum._365D]: RangeEnum._7D,
  [RangeEnum.NOW_]: RangeEnum._1H,
  [LAST_FULL_DAY]: RangeEnum._1H,
  [LAST_FULL_WEEK]: RangeEnum._1D,
  [LAST_FULL_MONTH]: RangeEnum._3D,
  [LAST_FULL_YEAR]: RangeEnum._30D,
  [AVERAGE_DAY]: RangeEnum._1H,
  [AVERAGE_WEEK]: RangeEnum._1D,
  [AVERAGE_MONTH]: RangeEnum._3D,
  [AVERAGE_YEAR]: RangeEnum._30D,
  [THIS_DAY]: RangeEnum._1H,
  [THIS_WEEK]: RangeEnum._1D,
  [THIS_MONTH]: RangeEnum._3D,
  [THIS_YEAR]: RangeEnum._30D,
};

export const INTERPOLATION_OPTIONS = [
  { label: InterpolationEnum.NONE, value: InterpolationEnum.NONE },
  { label: InterpolationEnum.LINEAR, value: InterpolationEnum.LINEAR },
  { label: InterpolationEnum.CUBIC, value: InterpolationEnum.CUBIC },
  { label: InterpolationEnum.POLYNOMIAL, value: InterpolationEnum.POLYNOMIAL },
];

export const STD_GUTTER: [Gutter, Gutter] = [16, 16];
export const STD_GUTTER_SMALL: [Gutter, Gutter] = [16, 8];
