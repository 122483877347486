import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Button, Col, Form, Input, Row, Table } from "antd";
import "../../Styles/pages/servicesDashboard.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined } from "@ant-design/icons";
import { ScopeEnum, User, UserCreate } from "../../API/_generated";
import {
  addBucketToUser,
  changeUserPw,
  createClient,
  editBucketOfUser,
  getBuckets,
  patchClient,
  removeBucketFromUser,
} from "../../redux";
import { RootState } from "../../redux/store";
import { id } from "../../Util/utils";
import BucketInput, { NEW_BUCKET_NAME } from "./BucketInput";
import { STD_GUTTER } from "../../Util/constants";

type Bucket = {
  name: string;
};
type CreateClientModalProps = {
  handleClose: () => void;
  data?: User;
};

const CreateClientModal: React.FC<CreateClientModalProps> = ({
  data,
  handleClose,
}) => {
  const [formData, setFormData] = useState<UserCreate & { _id: string }>({
    _id: "",
    email: "",
    firstname: "",
    secondname: "",
    password: "",
    scopes: [ScopeEnum.CLIENT],
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const newToggle = useRef<boolean>(false);
  useEffect(() => {
    if (data) {
      setFormData((fd) => ({
        ...fd,
        firstname: data.firstname,
        secondname: data.secondname,
        email: data.email,
        _id: data._id ?? "-1",
      }));
    }
  }, [data]);
  const { bucketsOfUser } = useSelector<RootState, RootState["adminReducer"]>(
    (state) => state.adminReducer
  );
  const editedBuckets = useRef<Bucket[]>([]);

  const buckets = useMemo<Bucket[]>(() => {
    const newArray = [{ name: NEW_BUCKET_NAME }];
    if (data) {
      const userId = id(data);
      if (userId in bucketsOfUser) {
        const determinedBuckets = newArray.concat(
          bucketsOfUser[userId].map((name) => ({ name }))
        );
        editedBuckets.current = determinedBuckets;
        return determinedBuckets;
      }
    }
    editedBuckets.current = newArray;
    return newArray;
  }, [data, bucketsOfUser]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((fd) => ({ ...fd, [e.target.name]: e.target.value }));
  }, []);
  const onChangeBucket = useCallback((name: string, idx: number) => {
    if (editedBuckets.current.length > idx) {
      editedBuckets.current[idx] = { name };
    }
  }, []);

  useEffect(() => {
    if (data) getBuckets(dispatch, id(data));
  }, [data, dispatch]);
  const onSaveBucket = useCallback(
    (idx: number) => {
      if (
        data &&
        editedBuckets.current.length > idx &&
        editedBuckets.current[idx].name !== ""
      ) {
        newToggle.current = !newToggle.current;
        addBucketToUser(dispatch, id(data), editedBuckets.current[idx].name);
      }
    },
    [data, dispatch]
  );
  const onEditBucket = useCallback(
    (idx: number) => {
      if (
        data &&
        buckets.length > idx &&
        editedBuckets.current.length > idx &&
        editedBuckets.current[idx].name !== ""
      ) {
        const newB = editedBuckets.current[idx];
        const oldB = buckets[idx];
        editBucketOfUser(dispatch, id(data), oldB.name, newB.name);
      }
    },
    [buckets, data, dispatch]
  );
  const onDeleteBucket = useCallback(
    (idx: number) => {
      if (data && buckets.length > idx)
        removeBucketFromUser(dispatch, id(data), buckets[idx].name);
    },
    [buckets, data, dispatch]
  );

  const columns: ColumnsType<Bucket> = useMemo(
    () => [
      {
        title: t("Bucket Name"),
        dataIndex: "name",
        key: "name",
        render: (_, record, index) => (
          <BucketInput
            record={record.name}
            index={index}
            onChange={onChangeBucket}
            update={newToggle.current}
          />
        ),
      },
      {
        title: t("Action"),
        dataIndex: "operation",
        key: "name",
        render: (_, record, index) => (
          <>
            <Button
              onClick={() => {
                if (record.name === NEW_BUCKET_NAME) onSaveBucket(index);
                else onEditBucket(index);
              }}
            >
              {t("Save")}
            </Button>
            {record.name !== NEW_BUCKET_NAME && (
              <Button
                onClick={() => {
                  onDeleteBucket(index);
                }}
              >
                <DeleteOutlined />
                {t("Delete")}
              </Button>
            )}
          </>
        ),
      },
    ],
    [onChangeBucket, onDeleteBucket, onEditBucket, onSaveBucket, t]
  );
  const handleSubmit = useCallback(() => {
    createClient(dispatch, {
      scopes: formData.scopes,
      email: formData.email,
      firstname: formData.firstname,
      secondname: formData.secondname,
      password: formData.password,
    }).then(
      () => {
        handleClose();
      },
      () => {
        handleClose();
      }
    );
  }, [dispatch, formData, handleClose]);

  const updateHandler = useCallback(() => {
    if (data && data._id)
      patchClient(dispatch, id(data), {
        firstname: formData.firstname,
        secondname: formData.secondname,
      }).then(
        () => {
          if (formData.password.length > 3) {
            changeUserPw(dispatch, id(data), formData.password).then(
              () => {
                handleClose();
              },
              () => {
                handleClose();
              }
            );
          } else {
            handleClose();
          }
        },
        () => {
          handleClose();
        }
      );
  }, [data, dispatch, formData, handleClose]);
  const keyExtractor = useCallback(
    (b: Bucket) => `table-row-bucket-${b.name}`,
    []
  );
  return (
    <div
      className="custom-scrollBar"
      style={{ overflow: "auto", maxHeight: "70vh", overflowX: "hidden" }}
    >
      <Form layout="vertical" onFinish={handleSubmit}>
        <Row gutter={STD_GUTTER}>
          <Col span={12}>
            <Form.Item label={t("First Name")}>
              <Input
                name="firstname"
                value={formData?.firstname}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t("Last Name")}>
              <Input
                name="secondname"
                value={formData?.secondname}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={STD_GUTTER}>
          <Col span={12}>
            <Form.Item label={t("E-Mail")}>
              <Input
                name="email"
                value={formData?.email}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={t(data ? "Change Password" : "Password")}>
              <Input.Password
                name="password"
                value={formData?.password}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={STD_GUTTER} justify="end">
          <Col>
            {data ? (
              <Button type="primary" onClick={updateHandler}>
                {t("Update")}
              </Button>
            ) : (
              <Button type="primary" onClick={handleSubmit}>
                {t("Save")}
              </Button>
            )}
          </Col>
        </Row>
        {data && (
          <Row gutter={STD_GUTTER}>
            <Col xs={24}>
              <Table
                dataSource={buckets}
                pagination={false}
                columns={columns}
                rowKey={keyExtractor}
              />
            </Col>
          </Row>
        )}
      </Form>
    </div>
  );
};
CreateClientModal.defaultProps = {
  data: undefined,
};
export default CreateClientModal;
