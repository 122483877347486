import React, { useCallback, useMemo, useRef, useState } from "react";
import CustomTooltip, { TooltipData } from "../CustomTooltip";

type HeatmapCellProps = {
  item: TooltipData;
  numberOnly?: boolean;
  maxValue: number;
  title: string;
};
const opacityRange = 0.9;

function calculateOpacity(itemValue: number, maxValue: number) {
  // Calculate the opacity value between 0 and 1
  const opacityValue = itemValue / maxValue;

  // Calculate the actual opacity within the range of 0.3 to 1
  const opacity = opacityValue * opacityRange + 0.1;

  return opacity;
}
const HeatmapCell: React.FC<HeatmapCellProps> = ({
  maxValue,
  numberOnly,
  title,
  item,
}) => {
  const ref = useRef(null);
  const [annotation, setAnnotation] = useState<boolean>(false);
  const onMouseOver = useCallback(() => {
    setAnnotation(true);
  }, []);
  const onMouseOut = useCallback(() => {
    setAnnotation(false);
  }, []);
  const innerCellStyle = useMemo<React.CSSProperties>(
    () => ({
      backgroundColor: numberOnly ? "transparent" : item.color,
      opacity:
        numberOnly || annotation ? 1 : calculateOpacity(item.value, maxValue), // TODO min 20%
    }),
    [numberOnly, item.color, item.value, annotation, maxValue]
  );

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div
        onMouseEnter={onMouseOver}
        onMouseLeave={onMouseOut}
        ref={ref}
        className="heatmap-cell"
        style={innerCellStyle}
      >
        <span>{numberOnly ? item.value : ""}</span>
        {annotation && (
          <div className="heatmap-tooltip">
            <CustomTooltip
              title={item.timestamp}
              data={[item]}
              opacity={numberOnly ? 1 : item.value / maxValue}
            />
          </div>
        )}
      </div>
    </>
  );
};
HeatmapCell.defaultProps = {
  numberOnly: false,
};
export default HeatmapCell;
