import React, { useCallback, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
  Select,
  Typography,
} from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import i18n from "i18next";
import { RootState } from "../redux/store";
import { LanguageEnum } from "../API/_generated";
import { changePw, patchMe } from "../redux";
import { LOCAL_STORAGE_LANG, STD_GUTTER } from "../Util/constants";
import Mainlayout from "../Layout/MainLayout";

const Profile: React.FC = () => {
  const { me } = useSelector<RootState, RootState["userReducer"]>(
    (state) => state.userReducer
  );
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstname: me?.firstname ?? "",
    secondname: me?.firstname ?? "",
    email: me?.email ?? "",
    password: "",
    passwordRepeat: "",
  });
  const dispatch = useDispatch();
  const update = useCallback(() => {
    patchMe(dispatch, {
      firstname: formData.firstname,
      secondname: formData.firstname,
    });
  }, [dispatch, formData.firstname]);
  const changePassword = useCallback(() => {
    if (formData.password === formData.passwordRepeat) {
      changePw(dispatch, {
        password: formData.password,
      }).then((res) => {
        if (res) {
          notification.success({
            message: t("Save sucessful"),
            duration: 5,
          });
        }
      });
    } else {
      notification.error({
        message: t("Password not the same"),
        duration: 5,
      });
    }
  }, [dispatch, formData.password, formData.passwordRepeat, t]);
  const changeLang = useCallback((lang: LanguageEnum) => {
    i18n.changeLanguage(lang).then(() => {
      localStorage.setItem(LOCAL_STORAGE_LANG, lang);
    });
  }, []);
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((fd) => ({ ...fd, [e.target.name]: e.target.value }));
  }, []);
  return (
    <Mainlayout active="settings">
      <div>
        <Typography.Title level={1}>{t("Settings")}</Typography.Title>
        <Typography.Title level={4} style={{ margin: "30px 0" }}>
          {t("Update Profile")}
        </Typography.Title>

        <Form layout="vertical">
          <Divider
            orientation="left"
            style={{ fontSize: "20px", marginBottom: "40px" }}
          >
            {t("Personal Information")}
          </Divider>
          <Row gutter={STD_GUTTER}>
            <Col xs={24} md={12}>
              <Form.Item label={t("First Name")}>
                <Input
                  name="firstname"
                  value={formData?.firstname}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t("Last Name")}>
                <Input
                  name="secondname"
                  value={formData?.secondname}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item name="language" label="Select Language">
                <Select onChange={changeLang}>
                  <Select.Option value="de">{t("German")}</Select.Option>
                  <Select.Option value="it">{t("Italian")}</Select.Option>
                  <Select.Option value="en">{t("English")}</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Button type="primary" onClick={update}>
                {t("Save")}
              </Button>
            </Col>
          </Row>
          <Divider
            orientation="left"
            style={{ fontSize: "20px", margin: "40px 0px 55px 0" }}
          >
            {t("Change Password")}
          </Divider>

          <Row gutter={STD_GUTTER}>
            <Col xs={24} md={12}>
              <Form.Item label={t("New Password")}>
                <Input
                  name="password"
                  value={formData?.password}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item label={t("Repeat Password")}>
                <Input
                  name="passwordRepeat"
                  value={formData?.passwordRepeat}
                  onChange={handleChange}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={24}>
              <Button type="primary" onClick={changePassword}>
                {t("Save")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </Mainlayout>
  );
};

export default Profile;
