import { Reducer } from "redux";
import {
  Restrictions,
  UserActions,
  UserActionTypes,
  UserState,
} from "../types/userTypes";
import { ScopeEnum } from "../../API/_generated";

const initialState: UserState = {
  me: null,
  status: "idle",
  colors: [
    "#025699",
    "#FEC700",
    "#2EC276",
    "#37A1DB",
    "#DD243B",
    "#F99600",
    "#FF33FF",
    "#991AFF",
    "#6666FF",
    "#66664D",
  ],
  barColors: [
    "#025699",
    "#FEC700",
    "#2EC276",
    "#37A1DB",
    "#DD243B",
    "#F99600",
    "#FF33FF",
    "#991AFF",
    "#6666FF",
    "#66664D",
  ],
  popupGraph: null,
  restrictions: null,
  livePageConfig: null,
  displayMask: false,
  displayAnnotation: false,
  displayAnnotationSub: false,
  measNodes: [],
  sites: [],
  isAdmin: false,
};

const userReducer: Reducer<UserState> = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: UserActions
) => {
  const { type } = action;
  switch (type) {
    case UserActionTypes.SET_USER_COLORS:
      return {
        ...state,
        colors: action.payload,
      };
    case UserActionTypes.SET_POPUP_GRAPH:
      return {
        ...state,
        popupGraph: action.payload,
      };
    case UserActionTypes.SET_USER_RESTRICTIONS:
      return {
        ...state,
        restrictions: action.payload as Restrictions,
      };
    case UserActionTypes.DISPLAY_MASK:
      return {
        ...state,
        displayMask: !state.displayMask,
      };
    case UserActionTypes.SET_BAR_COLORS:
      return {
        ...state,
        barColors: action.payload,
      };
    case UserActionTypes.SET_LIVE_PAGE_CONFIG:
      return {
        ...state,
        livePageConfig: action.payload,
      };
    case UserActionTypes.SET_DISPLAY_ANNOTATION: {
      return {
        ...state,
        displayAnnotation: action.payload,
      };
    }
    case UserActionTypes.SET_DISPLAY_ANNOTATION_SUB: {
      return {
        ...state,
        displayAnnotationSub: action.payload,
      };
    }
    case UserActionTypes.SET_MEAN_NODES: {
      return {
        ...state,
        measNodes: action.payload,
      };
    }
    case UserActionTypes.LOGIN: {
      const isAdmin = !!action.payload.scopes.find(
        (scope) => scope === ScopeEnum.ADMIN
      );
      return {
        ...state,
        me: action.payload,
        status: "idle",
        isAdmin,
      };
    }
    case UserActionTypes.LOGIN_FAILED: {
      return {
        ...state,
        me: null,
        status: "failed",
      };
    }
    case UserActionTypes.PATCH_FAILED: {
      return {
        ...state,
        status: "failed",
      };
    }
    case UserActionTypes.GET_SITES: {
      const sites = action.payload.sort((a, b) => a.index - b.index);
      return {
        ...state,
        sites,
      };
    }

    default:
      return state;
  }
};

export default userReducer;
