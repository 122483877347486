import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
  Select,
  Typography,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { DataSeries, Dropdown, Graph, UnitEnum } from "../../API/_generated";
import {
  AdminSiteContext,
  AllGraphTypes,
  BOOLEAN_CUSTOM_CONFIGS,
  GraphCustomConfig,
  GraphType,
  LEGEND_DROPDOWN,
} from "../../Util/types";
import { id, mergeStringArraysIntoObject, sameGraph } from "../../Util/utils";
import AdminDataSeries from "./AdminDataSeries";
import { updateSite } from "../../redux";
import { STD_GUTTER } from "../../Util/constants";
import AdminDataSeriesSmall from "./AdminDataSeriesSmall";
import { RootState } from "../../redux/store";
import Switch from "../Switches";

type SaveFunc<T extends keyof Graph> = (prop?: T, val?: Graph[T]) => void;

const AdminGraph: React.FC<{
  graph: Graph;
  dropdown: Dropdown[];
  cardId: string;
  handleClose: () => void;
}> = ({ dropdown, graph, cardId, handleClose }) => {
  const { t } = useTranslation();
  const [seriesOpenIdx, setSeriesOpenIdx] = useState<number>(-1);
  const dispatch = useDispatch();
  const { site, userId } = useContext(AdminSiteContext);
  const [selectedImportSeries, setSelectedImportSeries] =
    useState<string>("-1");
  const oldCards = useMemo(() => site.cards ?? [], [site]);
  const oldGraphs = useMemo(
    () => oldCards.find((c) => id(c) === cardId)?.graphs ?? [],
    [cardId, oldCards]
  );
  const sitesOfUsers = useSelector<
    RootState,
    RootState["adminReducer"]["sitesOfUser"]
  >((state) => state.adminReducer.sitesOfUser);
  const sites = useMemo(() => {
    if (userId in sitesOfUsers) {
      return sitesOfUsers[userId];
    }
    return [];
  }, [sitesOfUsers, userId]);
  const openSeries = useMemo(
    () =>
      seriesOpenIdx !== -1 && seriesOpenIdx < graph.series.length
        ? graph.series[seriesOpenIdx]
        : undefined,
    [graph.series, seriesOpenIdx]
  );
  const closeSeries = useCallback(() => {
    setSeriesOpenIdx(-1);
  }, []);
  const [formData, setFormData] = useState<Graph>(graph);
  useEffect(() => {
    setFormData(graph);
  }, [graph]);
  const handleSave = useCallback<SaveFunc<keyof Graph>>(
    (prop, val) => {
      if (prop && typeof val !== "undefined") {
        setFormData((fd) => {
          const newG = { ...fd, [prop]: val };
          const newCards = oldCards.map((c) =>
            id(c) === cardId
              ? {
                  ...c,
                  graphs: oldGraphs.map((g) =>
                    id(g) === id(graph) ? newG : g
                  ),
                }
              : c
          );
          updateSite(dispatch, userId, id(site), {
            ...site,
            cards: newCards,
          });
          return newG;
        });
      } else {
        const newCards = oldCards.map((c) =>
          id(c) === cardId
            ? {
                ...c,
                graphs: oldGraphs.map((g) =>
                  id(g) === id(graph) ? formData : g
                ),
              }
            : c
        );
        updateSite(dispatch, userId, id(site), {
          ...site,
          cards: newCards,
        });
      }
    },
    [cardId, dispatch, formData, graph, oldCards, oldGraphs, site, userId]
  );
  const onSave = useCallback(() => {
    handleSave();
  }, [handleSave]);
  const handleChangeType = useCallback(
    (value: string) => {
      handleSave("type", value);
    },
    [handleSave]
  );
  const setSeriesSameLength = useCallback(
    (val: boolean) => {
      handleSave("seriesSameLength", val);
    },
    [handleSave]
  );
  const handleChangeSubType = useCallback(
    (value: string) => {
      handleSave("sub_type", value === "none" ? "" : value);
    },
    [handleSave]
  );
  const addSeries = useCallback(() => {
    handleSave(
      "series",
      graph.series.concat({
        name: "",
        unit: UnitEnum.KWH,
      })
    );
    setSeriesOpenIdx(graph.series.length);
  }, [graph.series, handleSave]);
  const importableSeries = useMemo(
    () =>
      sites.reduce<DataSeries[]>((prev, s) => {
        if (!s.cards) return prev;
        return s.cards.reduce<DataSeries[]>((prev2, c) => {
          if (!c.graphs) return prev2;
          return c.graphs.reduce((prev3, g) => prev3.concat(g.series), prev2);
        }, prev);
      }, []),
    [sites]
  );
  const importSeries = useCallback(() => {
    const series = importableSeries.find((s) => id(s) === selectedImportSeries);
    if (series) {
      handleSave("series", graph.series.concat([series]));
      setSeriesOpenIdx(graph.series.length);
    } else {
      addSeries();
    }
  }, [
    addSeries,
    graph.series,
    handleSave,
    importableSeries,
    selectedImportSeries,
  ]);

  const onDeleteSeries = useCallback(
    (i: number) => {
      handleSave(
        "series",
        graph.series.filter((ds, idx) => idx !== i)
      );
    },
    [graph.series, handleSave]
  );

  const dataChanged = useMemo(
    () => !sameGraph(graph, formData),
    [graph, formData]
  );
  const config = useMemo(
    () => Object.keys(AllGraphTypes[formData.type as GraphType].config),
    [formData.type]
  );
  const booleanConfigs = useMemo(
    () => BOOLEAN_CUSTOM_CONFIGS.filter((cfg) => config.indexOf(cfg) !== -1),
    [config]
  );
  const booleanConfigOptions = useMemo(
    () => booleanConfigs.map((c) => ({ label: t(c), value: c })),
    [booleanConfigs, t]
  );
  const lineConfigOptions = useMemo(
    () => formData.series.map((s) => s.name),
    [formData]
  );
  const parsedConfig = useMemo<GraphCustomConfig>(() => {
    if (!formData.config) return {};
    try {
      return JSON.parse(formData.config);
    } catch (e) {
      return {};
    }
  }, [formData.config]);
  const booleanValues = useMemo(
    () =>
      BOOLEAN_CUSTOM_CONFIGS.filter(
        (cfg) =>
          cfg in parsedConfig && !!parsedConfig[cfg as keyof GraphCustomConfig]
      ),
    [parsedConfig]
  );
  const lineValues = useMemo(
    () =>
      lineConfigOptions.filter(
        (cfg) =>
          parsedConfig.dualAxesType &&
          cfg in parsedConfig.dualAxesType &&
          parsedConfig.dualAxesType[cfg] === "line"
      ),
    [parsedConfig, lineConfigOptions]
  );
  const columnValues = useMemo(
    () => lineConfigOptions.filter((cfg) => lineValues.indexOf(cfg) === -1),
    [lineValues, lineConfigOptions]
  );

  const dashedOptions = useMemo(
    () => formData.series.map((s) => s.name),
    [formData.series]
  );
  const onChangeBooleanConfig = useCallback(
    (checked: CheckboxValueType[]) => {
      const cfg = mergeStringArraysIntoObject(
        booleanConfigs,
        checked as string[]
      );
      handleSave(
        "config",
        JSON.stringify({
          ...parsedConfig,
          ...cfg,
        })
      );
    },
    [booleanConfigs, handleSave, parsedConfig]
  );
  const setDualAxes = useCallback(
    (
      checked: CheckboxValueType[],
      first: "column" | "line",
      second: "column" | "line"
    ) => {
      const dualAxesType: Record<string, "column" | "line"> = {};
      lineConfigOptions.forEach((val) => {
        if (checked.includes(val)) dualAxesType[val] = first;
        else dualAxesType[val] = second;
      });
      handleSave(
        "config",
        JSON.stringify({
          ...parsedConfig,
          dualAxesType,
        })
      );
    },
    [handleSave, lineConfigOptions, parsedConfig]
  );
  const setColumnConfigOption = useCallback(
    (checked: CheckboxValueType[]) => {
      setDualAxes(checked, "column", "line");
    },
    [setDualAxes]
  );
  const setLinesConfigOption = useCallback(
    (checked: CheckboxValueType[]) => {
      setDualAxes(checked, "line", "column");
    },
    [setDualAxes]
  );
  const dashedValues = useMemo(() => {
    if (
      "dashed" in parsedConfig &&
      parsedConfig.dashed &&
      Array.isArray(parsedConfig.dashed)
    )
      return parsedConfig.dashed;
    return [];
  }, [parsedConfig]);
  const setDashedConfigOption = useCallback(
    (checked: CheckboxValueType[]) => {
      handleSave(
        "config",
        JSON.stringify({
          ...parsedConfig,
          dashed: checked,
        })
      );
    },
    [handleSave, parsedConfig]
  );
  const onChangeLegend = useCallback(
    (legend: string) => {
      handleSave(
        "config",
        JSON.stringify({
          ...parsedConfig,
          legend,
        })
      );
    },
    [handleSave, parsedConfig]
  );
  const legendValue = useMemo(() => {
    if ("legend" in parsedConfig && parsedConfig.legend)
      return parsedConfig.legend;
    return "bottom";
  }, [parsedConfig]);
  const dataInvalid = useMemo(() => {
    const errors: string[] = [];

    const dropdownLength = dropdown.length;
    const nameNoDropdowns = formData.series.reduce<false | string>(
      (prev, current) => {
        if (prev) return prev;
        if (current.groupBy) {
          const ds = current.availableGroupBy
            ? JSON.parse(current.availableGroupBy)
            : {};
          if (Object.keys(ds).length !== dropdownLength) return current.name;
        }
        return false;
      },
      false
    );

    const nameNoData = formData.series.reduce<boolean | string>(
      (prev, current) => {
        if (prev) return prev;
        if (!current.data || current.data.length === 0) return current.name;
        return false;
      },
      false
    );
    let unit = "";
    const nameNoUnits = formData.series.reduce<boolean | string>(
      (prev, current) => {
        if (prev) return prev;
        if (unit === "") unit = current.unit;
        else if (unit !== current.unit) return current.name;
        return false;
      },
      false
    );
    let groupBy: string | undefined = "-1";
    const nameHeatMapAllOrNoneGroupBy = formData.series.reduce<
      boolean | string
    >((prev, current) => {
      if (prev) return prev;
      if (groupBy === "-1") groupBy = current.groupBy;
      else if (groupBy !== current.groupBy) return current.name;
      return false;
    }, false);

    if (nameNoData)
      errors.push(`${t("Data series with no Influx Data:")} ${nameNoData}`);
    if (formData.series.length === 0) errors.push(t("No data series"));
    if (nameNoDropdowns)
      errors.push(`${t("Wrong Dropdown-Group-by Config:")} ${nameNoDropdowns}`);
    if (nameNoUnits && formData.type !== "dualAxes")
      errors.push(`${t("Different Units:")} ${nameNoUnits}`);
    if (nameHeatMapAllOrNoneGroupBy && formData.type === "heatmap")
      errors.push(
        `${t(
          "All or none grouping of Heatmap:"
        )} ${nameHeatMapAllOrNoneGroupBy}`
      );
    return errors;
  }, [dropdown.length, formData.series, formData.type, t]);

  return (
    <Col xs={24}>
      <h3>{t("Edit Graph")}</h3>
      {openSeries ? (
        <Row gutter={STD_GUTTER} className="card-container">
          <h4>{t("Edit Data Series")}</h4>
          <AdminDataSeries
            series={openSeries}
            cardId={cardId}
            graphId={id(graph)}
            index={seriesOpenIdx}
            onClose={closeSeries}
            dropdown={dropdown}
          />
        </Row>
      ) : (
        <div className="card-container">
          {dataInvalid.length !== 0 && (
            <Row gutter={STD_GUTTER}>
              <Divider orientation="left">
                <Typography.Title level={5} type="danger">
                  {t("errors")}
                </Typography.Title>
              </Divider>
              {dataInvalid.map((e) => (
                <Col xs={12} key={`error-${e}`}>
                  <Typography.Text type="danger">{e}</Typography.Text>
                </Col>
              ))}
              <Divider orientation="left">
                <Typography.Title level={5} type="danger">
                  {t("errors")}
                </Typography.Title>
              </Divider>
            </Row>
          )}
          <Form layout="vertical">
            <Row gutter={STD_GUTTER}>
              <Col xs={12}>
                <Form.Item label={t("Type")}>
                  <Select
                    id="type"
                    value={formData?.type}
                    onChange={handleChangeType}
                  >
                    {Object.keys(AllGraphTypes).map((key) => (
                      <Select.Option value={key} key={key}>
                        {t(key)}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item label={t("Sub Type")}>
                  <Select
                    id="sub_type"
                    value={formData?.sub_type}
                    onChange={handleChangeSubType}
                  >
                    <Select.Option value="non">{t("None")}</Select.Option>
                    {AllGraphTypes[formData.type as GraphType]?.subTypes.map(
                      (key: string) => (
                        <Select.Option value={key} key={key}>
                          {t(key)}
                        </Select.Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {config.length !== 0 && (
              <Row gutter={[16, 8]}>
                <Col xs={24}>
                  <Form.Item label={t("Additional Config")}>
                    <Checkbox.Group
                      options={booleanConfigOptions}
                      onChange={onChangeBooleanConfig}
                      value={booleanValues}
                    />
                  </Form.Item>
                </Col>
                {config.includes("dualAxesType") && (
                  <>
                    <Col xs={12}>
                      <Form.Item label={t("Columns")}>
                        <Checkbox.Group
                          options={lineConfigOptions}
                          onChange={setColumnConfigOption}
                          value={columnValues}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12}>
                      <Form.Item label={t("Lines")}>
                        <Checkbox.Group
                          options={lineConfigOptions}
                          onChange={setLinesConfigOption}
                          defaultValue={lineValues}
                        />
                      </Form.Item>
                    </Col>
                  </>
                )}
                {config.includes("dashed") && (
                  <Col xs={12}>
                    <Form.Item label={t("Dashed Line")}>
                      <Checkbox.Group
                        options={dashedOptions}
                        onChange={setDashedConfigOption}
                        value={dashedValues}
                      />
                    </Form.Item>
                  </Col>
                )}
                {config.includes("legend") && (
                  <Col xs={12}>
                    <Form.Item label={t("Legend Position")}>
                      <Select onChange={onChangeLegend} value={legendValue}>
                        {LEGEND_DROPDOWN.map((dd) => (
                          <Select.Option value={dd} key={dd}>
                            {t(dd)}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                )}
                <Col xs={12}>
                  <Form.Item label={t("All series same length")}>
                    <Switch
                      value={formData.seriesSameLength ?? false}
                      switchHandler={setSeriesSameLength}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row gutter={STD_GUTTER}>
              <Divider orientation="left">{t("Data")}</Divider>
              {formData.series?.map((series, i) => (
                <AdminDataSeriesSmall
                  series={series}
                  onEdit={setSeriesOpenIdx}
                  onDelete={onDeleteSeries}
                  index={i}
                  key={id(series)}
                />
              ))}
              {(formData.series?.length ?? 0) <
                AllGraphTypes[formData.type as GraphType]?.maxSeries && (
                <Col xs={8}>
                  <Form.Item label={t("Import existing Series")}>
                    <Select
                      onSelect={setSelectedImportSeries}
                      value={selectedImportSeries}
                    >
                      <Select.Option value="-1">{t("Choose...")}</Select.Option>
                      {importableSeries.map((series) => (
                        <Select.Option value={id(series)}>
                          {series.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Button
                    type="primary"
                    onClick={
                      selectedImportSeries !== "-1" ? importSeries : addSeries
                    }
                  >
                    {selectedImportSeries !== "-1"
                      ? t("Import")
                      : t("New Series")}
                  </Button>
                </Col>
              )}
            </Row>
            <Row gutter={STD_GUTTER} justify="end" className="mt-2">
              <Col>
                <Button
                  type="primary"
                  onClick={dataChanged ? onSave : handleClose}
                >
                  {dataChanged ? t("Save Graph") : t("Close")}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </Col>
  );
};

export default AdminGraph;
