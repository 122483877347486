import React, { useEffect, useMemo, useState } from "react";
import {
  Col,
  Dropdown as DropdownItem,
  MenuProps,
  Row,
  Typography,
} from "antd";
import { ItemType } from "antd/es/menu/hooks/useItems";
import { useTranslation } from "react-i18next";
import { BiChevronDown } from "react-icons/bi";
import { Card, Dropdown, RangeEnum } from "../../API/_generated";
import { id, rangeEnumToMS } from "../../Util/utils";
import GraphView from "../charts/GraphView";
import { DEFAULT_DROPDOWN, STD_GUTTER } from "../../Util/constants";

type CardProps = {
  card: Card;
};
const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "1.5rem",
};

const CardView: React.FC<CardProps> = ({ card }) => {
  const [activeDropDown, setActiveDropDown] = useState<Dropdown>(
    card.dropdown
      ? card.dropdown.find((d) => d.standard) ?? card.dropdown[0]
      : DEFAULT_DROPDOWN
  );
  useEffect(() => {
    setActiveDropDown(
      card.dropdown
        ? card.dropdown.find((d) => d.standard) ?? card.dropdown[0]
        : DEFAULT_DROPDOWN
    );
  }, [card.dropdown]);
  const { t } = useTranslation();
  const dropDownMenu = useMemo<MenuProps | undefined>(() => {
    if (!card.dropdown || !activeDropDown) return undefined;
    if (card.dropdown.length === 1) return undefined;
    const dropDowns = [...card.dropdown];
    return {
      activeKey: activeDropDown.start,
      items: dropDowns
        .sort(
          (a, b) =>
            rangeEnumToMS(a.start as RangeEnum) -
            rangeEnumToMS(b.start as RangeEnum)
        )
        .map<ItemType>((drop) => ({
          title: t(drop.label),
          label: t(drop.label),
          onClick: () => {
            setActiveDropDown(drop);
          },
          key: drop.start,
        })),
    };
  }, [activeDropDown, card.dropdown, t]);

  return (
    <Col xs={card.widthSM} md={card.widthLG} className="card-col">
      <div className="card-container ">
        <div style={headerStyle}>
          <Typography.Title
            className="title"
            level={5}
            style={{ fontSize: "16px", fontWeight: "600" }}
          >
            {t(card.title)}
          </Typography.Title>
          {dropDownMenu && (
            <div>
              <DropdownItem menu={dropDownMenu} className="card-dropdown">
                <span>
                  {t(activeDropDown.label)}
                  <BiChevronDown />
                </span>
              </DropdownItem>
            </div>
          )}
        </div>
        <Row gutter={STD_GUTTER}>
          {card.graphs?.map((g) => (
            <Col xs={card.graphs?.length === 1 ? 24 : 12} key={id(g)}>
              <GraphView graph={g} dropdown={activeDropDown} />
            </Col>
          ))}
        </Row>
      </div>
    </Col>
  );
};
export default CardView;

/*
 const id = useMemo(() => randomUUID(), []);
  const [modalData, setModalData] = useState<HeatMapRow[]>();
  const [showModal, setShowModal] = useState<false | string>(false);
  const customData = useRef<{ key: string; data: HeatMapRow[] }[]>([]);
  const [activeDropDown, setActiveDropDown] = useState<string>();
  const displayData = useMemo(() => {
    if (activeDropDown) {
      return customData.current.find((d) => d.key === activeDropDown)?.data;
    }
    return data;
  }, [activeDropDown, data, customData]);
  const options = useMemo(
    () =>
      determineDateTimeOptions(
        displayData
          ? displayData.reduce<HeatMapCell[]>((prev, d) => d.values, [])
          : []
      ),
    [displayData]
  );
  const maxValue = useMemo(
    () =>
      displayData
        ? displayData.reduce((prev, current) => {
            const max = current.values.reduce((prev2, current2) => {
              if (current2.value > prev2) {
                return current2.value;
              }
              return prev;
            }, 0);
            if (max > prev) {
              return max;
            }
            return prev;
          }, 0)
        : 0,
    [displayData]
  );
  const onModalCallback = useCallback(
    (name: string) => {
      if (!modalCallback) return;
      setShowModal(name);
      modalCallback(name).then((innerData) => {
        setModalData(innerData);
      });
    },
    [modalCallback]
  );
  const handleClose = () => {
    setShowModal(false);
    setModalData(undefined);
  };
  const dropDownMenu = useMemo<MenuProps | undefined>(() => {
    if (!dropDown || !dropDownCallback) return undefined;
    return {
      items: dropDown.map<ItemType>((drop) => ({
        title: drop.title,
        onClick: () => {
          setActiveDropDown(drop.key);
          if (!customData.current.find((d) => d.key === drop.key)) {
            dropDownCallback(drop.key).then((custom) => {
              customData.current.push({ key: drop.key, data: custom });
            });
          }
        },
        key: drop.key,
      })),
    };
  }, [dropDown, dropDownCallback]);
 */
