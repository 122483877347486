import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";

const PrivateRoute: React.FC<RouteProps> = ({
  component: PrivateComponent,
  ...rest
}) => {
  const user = useSelector<RootState, RootState["userReducer"]["me"]>(
    (state) => state.userReducer.me
  );
  const isAdmin = useSelector<RootState, RootState["userReducer"]["isAdmin"]>(
    (state) => state.userReducer.isAdmin
  );
  return (
    <Route
      {...rest}
      render={(props) =>
        user && PrivateComponent ? (
          isAdmin ? (
            <Redirect to="/admin" />
          ) : (
            <PrivateComponent {...props} />
          )
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default PrivateRoute;
