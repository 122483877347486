/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../../Styles/pages/adminDynamicSwitches.css";
import { Col, Form, Row, Select, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import {
  addSiteToUser,
  getClients,
  getSitesOfUser,
  removeSiteFromUser,
} from "../../redux";
import { RootState } from "../../redux/store";
import { Site, User } from "../../API/_generated";
import { id } from "../../Util/utils";
import Switch from "../../Components/Switches";
import { AllSites } from "../../Util/types";
import Mainlayout from "../../Layout/MainLayout";
import { STD_GUTTER } from "../../Util/constants";

const { Option } = Select;
const switchStyle = { width: 200 };

const AdminSidebar = () => {
  const users = useSelector<RootState, RootState["adminReducer"]["users"]>(
    (state) => state.adminReducer.users
  );
  const sitesOfUsers = useSelector<
    RootState,
    RootState["adminReducer"]["sitesOfUser"]
  >((state) => state.adminReducer.sitesOfUser);
  const [selectedUser, setSelectedUser] = useState<User>();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    if (users.length === 0) getClients(dispatch);
  }, [dispatch, users]);
  const sites = useMemo(() => {
    if (!selectedUser) return undefined;
    if (id(selectedUser) in sitesOfUsers) {
      const ofUser = sitesOfUsers[id(selectedUser)];
      if (!ofUser) return undefined;
      return AllSites.map((defaultSite) => {
        const site = ofUser.find((ofU) => ofU.icon === defaultSite.icon);
        if (site) return site;
        return defaultSite;
      });
    }
    return undefined;
  }, [sitesOfUsers, selectedUser]);
  const handleChange = useCallback(
    (value: string) => {
      setSelectedUser(users.find((u) => id(u) === value));
    },
    [users]
  );
  useEffect(() => {
    if (!sites && !!selectedUser) getSitesOfUser(dispatch, id(selectedUser));
  }, [selectedUser, sites]);
  const toggleSwitch = useCallback(
    (value: boolean, key?: string) => {
      if (selectedUser && sites) {
        const found = sites.find((s) => s.icon === key);
        if (found) {
          if (value) {
            addSiteToUser(dispatch, id(selectedUser), {
              title: found.title,
              icon: found.icon,
              index: found.index,
            });
          } else {
            removeSiteFromUser(dispatch, id(selectedUser), id(found));
          }
        }
      }
    },
    [selectedUser, sites]
  );
  const columns: ColumnsType<Site> = useMemo(
    () => [
      {
        title: t("Site"),
        dataIndex: "title",
        key: "key",
        render: (val: any, record: Site) => <>{t(record.title)}</>,
      },
      {
        title: t("Active"),
        dataIndex: "_id",
        render: (_, record) => (
          <Switch
            value={id(record) !== "-1"}
            name={record.icon}
            switchHandler={toggleSwitch}
          />
        ),
      },
      {
        title: t("Customize"),
        dataIndex: "operation",
        render: (_, record) =>
          selectedUser ? (
            <Link to={`/admin-sites/${id(selectedUser)}/${id(record)}`}>
              {t("Customize")}
            </Link>
          ) : (
            <Link to="/admin-sites">{t("Customize")}</Link>
          ),
      },
    ],
    [selectedUser]
  );
  const keyExtractor = useCallback((s: Site) => `site-table-${s.icon}`, []);
  return (
    <Mainlayout active="admin-sidebar">
      <div className="admin-container-switches">
        <Row gutter={STD_GUTTER}>
          <Form layout="horizontal">
            <Col xs={12}>
              <Form.Item label={`${t("Client")}:`}>
                <Select
                  defaultValue="-1"
                  style={switchStyle}
                  onChange={handleChange}
                >
                  <Option value="-1">{t("Choose...")}</Option>
                  {users.map((item) => (
                    <Option value={item._id ?? "-1"} key={item._id}>
                      {`${item.firstname} ${item.secondname}`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Form>
        </Row>
        <Row gutter={STD_GUTTER}>
          {sites && (
            <Table dataSource={sites} columns={columns} rowKey={keyExtractor} />
          )}
        </Row>
      </div>
    </Mainlayout>
  );
};

export default AdminSidebar;
