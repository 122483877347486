/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Site } from '../models/Site';
import type { SiteCreate } from '../models/SiteCreate';
import type { SiteUpdate } from '../models/SiteUpdate';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SiteService {

    /**
     * Get Sites
     * @returns Site Successful Response
     * @throws ApiError
     */
    public static getSitesSitesGet(): CancelablePromise<Array<Site>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sites/',
        });
    }

    /**
     * Add Site To User
     * @param userId 
     * @param requestBody 
     * @returns Site Successful Response
     * @throws ApiError
     */
    public static addSiteToUserSitesPost(
userId: string,
requestBody: SiteCreate,
): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/sites/',
            query: {
                'user_id': userId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Sites By User
     * @param userId 
     * @returns Site Successful Response
     * @throws ApiError
     */
    public static getSitesByUserSitesUserUserIdGet(
userId: string,
): CancelablePromise<Array<Site>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sites/user/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Site
     * @param siteId 
     * @returns Site Successful Response
     * @throws ApiError
     */
    public static getSiteSitesSiteIdGet(
siteId: string,
): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/sites/{site_id}',
            path: {
                'site_id': siteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Site
     * @param siteId 
     * @param requestBody 
     * @returns Site Successful Response
     * @throws ApiError
     */
    public static updateSiteSitesSiteIdPut(
siteId: string,
requestBody: SiteUpdate,
): CancelablePromise<Site> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/sites/{site_id}',
            path: {
                'site_id': siteId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Site
     * @param siteId 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static deleteSiteSitesSiteIdDelete(
siteId: string,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/sites/{site_id}',
            path: {
                'site_id': siteId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
