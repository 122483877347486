import * as Sentry from "@sentry/react";
import { DateTimeFormat } from "intl";
import {
  AggregateValuePair,
  Card,
  DataSeries,
  Dropdown,
  Filter,
  Graph,
  GroupByEnum,
  InfluxData,
  RangeEnum,
  ValuePair,
} from "../API/_generated";
import { MongoDocumentType } from "./types";
import { hashDropDown } from "../redux/types/influxDataTypes";
import {
  LAST_FULL_DAY,
  LAST_FULL_MONTH,
  LAST_FULL_WEEK,
  LAST_FULL_YEAR,
  RANGE_DROPDOWN_OPTIONS,
  THIS_DAY,
  THIS_MONTH,
  THIS_WEEK,
  THIS_YEAR,
} from "./constants";

export function id<T extends MongoDocumentType>(elem: T): string {
  return elem._id ?? "-1";
}

export function errorLogging(
  error: object | string,
  type?: "log" | "error" | "info" | "warning" | "fatal" | "debug"
): void {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // eslint-disable-next-line no-console
    console.log(JSON.stringify(error));
    console.trace("Error at: ");
  } else {
    Sentry.addBreadcrumb({
      level: type ?? "warning",
      data: { error },
    });
  }
}

export function dateLabel(
  date: string | number,
  options: Intl.DateTimeFormatOptions
) {
  const d = new Date(date);
  if (Number.isNaN(d.getTime())) return "";
  try {
    return DateTimeFormat("de", options).format(d.getTime());
  } catch (e) {
    return "";
  }
}

export function valuePairLabel(
  item: ValuePair | AggregateValuePair,
  options: Intl.DateTimeFormatOptions,
  groupBy: GroupByEnum | undefined
) {
  const d = new Date(item.timestamp);
  if (Number.isNaN(d.getTime())) return "";
  try {
    return DateTimeFormat("de", options).format(d.getTime());
  } catch (e) {
    return "";
  }
}
/*
export function valuePairInterval(
  item: AggregateValuePair,
  options: Intl.DateTimeFormatOptions
) {
  return `${DateTimeFormat("de", options).format(
    new Date(item.start)
  )} - ${DateTimeFormat("de", options).format(new Date(item.end))}`;
}
*/
export function timeDiffInWords(timeDiff: number) {
  if (timeDiff > YEAR_IN_MS) return "than last year";
  if (timeDiff > MONTH_IN_MS) return "than last month";
  if (timeDiff > WEEK_IN_MS) return "than last week";
  if (timeDiff > DAY_IN_MS) return "than last day";
  return "than last hour";
}
const MIN_IN_MS = 60000;
const HOUR_IN_MS = 3600000;
const DAY_IN_MS = HOUR_IN_MS * 24;
const WEEK_IN_MS = DAY_IN_MS * 7;
const MONTH_IN_MS = DAY_IN_MS * 30;
const YEAR_IN_MS = DAY_IN_MS * 365;

function isRangeEnum(start: string): boolean {
  return !!RANGE_DROPDOWN_OPTIONS.find((opt) => opt.value === start);
}

export function determineDateTimeOptions(
  start: string,
  granularity: RangeEnum,
  groupBy: GroupByEnum | undefined,
  stop?: string
): Intl.DateTimeFormatOptions {
  if (groupBy === GroupByEnum.MONTH_DAY) {
    return {
      day: "numeric",
      timeZone: "UTC",
    };
  }
  if (
    start === GroupByEnum.MONTH_DAY ||
    start === THIS_MONTH ||
    start === LAST_FULL_MONTH
  ) {
    return {
      day: "numeric",
    };
  }
  if (groupBy === GroupByEnum.WEEK_DAY) {
    return {
      weekday: "long",
      hour: "numeric",
      timeZone: "UTC",
    };
  }
  if (start === THIS_WEEK || start === LAST_FULL_WEEK) {
    return {
      weekday: "long",
    };
  }
  if (start === GroupByEnum.WEEK_DAY) {
    return {
      weekday: "long",
      hour: "numeric",
    };
  }
  if (groupBy === GroupByEnum.MONTH) {
    return {
      month: "long",
      timeZone: "UTC",
    };
  }
  if (
    start === GroupByEnum.MONTH ||
    start === THIS_YEAR ||
    start === LAST_FULL_YEAR
  ) {
    return {
      month: "long",
    };
  }
  if (groupBy === GroupByEnum.HOUR) {
    return {
      hour: "numeric",
      minute: "2-digit",
      timeZone: "UTC",
    };
  }
  if (
    start === GroupByEnum.HOUR ||
    start === THIS_DAY ||
    start === LAST_FULL_DAY
  ) {
    return {
      hour: "numeric",
      minute: "2-digit",
    };
  }
  let realStart: string = RangeEnum._1D;
  if (isRangeEnum(start)) {
    realStart = start;
  } else {
    const s = new Date(start);
    const e = new Date(stop ?? "now");
    const diff = Math.abs(e.getTime() - s.getTime());
    if (diff >= YEAR_IN_MS) realStart = RangeEnum._365D;
    else if (diff >= MONTH_IN_MS * 2) realStart = RangeEnum._90D;
    else if (diff >= MONTH_IN_MS) realStart = RangeEnum._30D;
    else if (diff >= WEEK_IN_MS) realStart = RangeEnum._7D;
    else if (diff >= DAY_IN_MS) realStart = RangeEnum._3D;
    else if (diff > HOUR_IN_MS * 12) realStart = RangeEnum._1D;
    else if (diff > HOUR_IN_MS) realStart = RangeEnum._1H;
    else realStart = RangeEnum._30M;
  }
  if (
    granularity === RangeEnum._1M ||
    granularity === RangeEnum._2M ||
    granularity === RangeEnum._5M ||
    granularity === RangeEnum._15M ||
    granularity === RangeEnum._30M
  ) {
    if (
      realStart === RangeEnum._1D ||
      realStart === RangeEnum._2D ||
      realStart === RangeEnum._3D ||
      realStart === RangeEnum._7D ||
      realStart === RangeEnum._30D ||
      realStart === RangeEnum._90D ||
      realStart === RangeEnum._365D ||
      realStart === LAST_FULL_WEEK ||
      realStart === LAST_FULL_MONTH
    )
      return {
        day: "2-digit",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
      };
    return { day: "2-digit", hour: "2-digit", minute: "2-digit" };
  }
  if (
    granularity === RangeEnum._1H ||
    granularity === RangeEnum._2H ||
    granularity === RangeEnum._4H ||
    granularity === RangeEnum._6H ||
    granularity === RangeEnum._12H
  ) {
    if (
      realStart === RangeEnum._1D ||
      realStart === RangeEnum._2D ||
      realStart === RangeEnum._3D ||
      realStart === RangeEnum._7D ||
      realStart === RangeEnum._30D ||
      realStart === RangeEnum._90D ||
      realStart === RangeEnum._365D
    )
      return {
        day: "2-digit",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
      };
    if (realStart === LAST_FULL_WEEK)
      return {
        weekday: "short",
        hour: "2-digit",
        minute: "2-digit",
      };
    if (realStart === LAST_FULL_MONTH)
      return {
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
    if (realStart === LAST_FULL_YEAR)
      return {
        day: "2-digit",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
      };
    return { hour: "2-digit", minute: "2-digit" };
  }
  if (
    granularity === RangeEnum._1D ||
    granularity === RangeEnum._2D ||
    granularity === RangeEnum._3D ||
    granularity === RangeEnum._7D
  ) {
    if (realStart === RangeEnum._90D)
      return {
        day: "2-digit",
        month: "short",
        year: "numeric",
      };
    if (realStart === LAST_FULL_WEEK)
      return {
        weekday: "short",
      };
    if (realStart === LAST_FULL_MONTH)
      return {
        day: "2-digit",
      };
    if (realStart === LAST_FULL_YEAR)
      return {
        day: "2-digit",
        month: "short",
      };
    return { day: "2-digit", month: "short" };
  }
  if (granularity === RangeEnum._30D)
    return { month: "short", year: "numeric" };

  return { month: "short", year: "numeric" };
}

export function generatePW(length = 12, bigCharset = true): string {
  let charset =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ01234567890123456789";
  if (bigCharset) {
    charset += "-_#+*!§$%&/()=?ß{[]}";
  }
  let retVal = "";
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
}

export function toSpongebobCase(text: string): string {
  let j = 0;
  return text
    .split("")
    .map((letter) => {
      if (!letter.match(/[^A-Za-z]/g)) {
        j++;
      }
      return j % 2 ? letter.toUpperCase() : letter.toLowerCase();
    })
    .join("");
}

export function toSnakeCase(text: string): string {
  return text.replace(/([A-Z]{1,2})/g, (match) => `_${match.toLowerCase()}`);
}

export function toCamelCase(text: string): string {
  return text.replace(/_([a-z]{2})/g, (match) =>
    match === "_id"
      ? match.slice(1, 3).toUpperCase()
      : `${match.charAt(1).toUpperCase()}${match.charAt(2)}`
  );
}

export function arrayDiff<T extends string | boolean | number>(
  a: T[],
  b: T[]
): T[] {
  return a.filter((t) => !b.includes(t));
}

export type LanguageISO = "de" | "it" | "en";

export function intlLang(l: LanguageISO): string {
  return l === "de" ? "de-DE" : l === "it" ? "it-IT" : "en-US";
}

export function formatDate(
  date: Date | string,
  l: LanguageISO,
  forInput = false,
  onlyTime = false,
  onlyDate = false,
  long = false
): string {
  const d = new Date(date);
  if (typeof d === "undefined") {
    return "";
  }
  if (forInput) {
    const iso = d.toISOString();
    if (onlyDate) return iso.split("T")[0];
    if (onlyTime) return iso.split("T")[1];
    return iso;
  }
  let options: Intl.DateTimeFormatOptions;
  if (onlyTime) {
    options = {
      hour: "2-digit",
      minute: "2-digit",
    };
    if (long) options.second = "2-digit";
    return d.toLocaleTimeString(intlLang(l), options);
  }
  if (onlyDate) {
    options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    };
    if (long) options.weekday = "long";
  } else {
    options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    };
  }

  return DateTimeFormat(intlLang(l), options).format(d);
}

const mailReg =
  /^[a-zA-Z0-9!#$%&'*+/=?^`{}|~_-]+[.a-zA-Z0-9!#$%&'*+/=?^`{}|~_-]*@[a-zA-Z0-9]+[._a-zA-Z0-9-]*\.[a-zA-Z0-9]+$/i;

export function checkMail(email: string): boolean {
  return mailReg.test(email);
}

export function hexToRgb(hex: string): { r: number; g: number; b: number } {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : { r: 0, g: 0, b: 0 };
}

export function numberedHash(str: string): number {
  let hash = 0;
  let i;
  let chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit in teger
  }
  return hash;
}

export function getClosestDate(dates: Date[]): Date {
  const nowDate = new Date();
  nowDate.setHours(5);
  const now = nowDate.getTime();
  const differences = dates.map((d) => Math.abs(d.getTime() - now));
  const closest = Math.min(...differences);
  const idx = differences.indexOf(closest);
  if (idx === -1) return dates[0];
  return dates[idx];
}

export function setHead(title: string) {
  document.title = `En-Expert - ${title}`;
}

type MongoDocumentArrayType<
  ARR extends MongoDocumentType,
  KEY extends string
> = MongoDocumentType & {
  [V in KEY]?: ARR[] | undefined;
} & Record<string, any>;

export function setArrayValue<
  ARR extends MongoDocumentType,
  KEY extends string,
  FD extends MongoDocumentArrayType<ARR, KEY>
>(oldVal: FD, key: KEY, newVal: ARR): FD {
  return {
    ...oldVal,
    [key]: oldVal[key]
      ? id(newVal) === "-1"
        ? oldVal[key].concat([newVal])
        : oldVal[key].map((val: ARR) => (id(val) === id(newVal) ? newVal : val))
      : [newVal],
  };
}

export function sameFilter(a: Filter, b: Filter): boolean {
  return a.key === b.key && a.value === b.value;
}
export function sameFilters(
  a: Filter[] | undefined,
  b: Filter[] | undefined
): boolean {
  if (!a && !b) return true;
  if (!a) return false;
  if (!b) return false;
  return (
    a.length === b.length &&
    a.reduce<boolean>(
      (prev, current, index) => prev && sameFilter(current, b[index]),
      true
    )
  );
}

export function sameInfluxData(a: InfluxData, b: InfluxData): boolean {
  return (
    a.function === b.function &&
    a.bucket === b.bucket &&
    a.interpolation === b.interpolation &&
    sameFilters(a.filters, b.filters)
  );
}

export function sameInfluxDatas(
  a: InfluxData[] | undefined,
  b: InfluxData[] | undefined
): boolean {
  if (!a && !b) return true;
  if (!a) return false;
  if (!b) return false;
  return (
    a.length === b.length &&
    a.reduce<boolean>(
      (prev, current, index) => prev && sameInfluxData(current, b[index]),
      true
    )
  );
}
export function sameDataSeries(a: DataSeries, b: DataSeries): boolean {
  return (
    a._id === b._id &&
    a.unit === b.unit &&
    a.lambdaFn === b.lambdaFn &&
    a.name === b.name &&
    a.customWindow === b.customWindow &&
    a.color === b.color &&
    a.onClickCardId === b.onClickCardId &&
    sameInfluxDatas(a.data, b.data) &&
    a.groupBy === b.groupBy
  );
}

export function sameDataSeriess(
  a: DataSeries[] | undefined,
  b: DataSeries[] | undefined
): boolean {
  if (!a && !b) return true;
  if (!a) return false;
  if (!b) return false;
  return (
    a.length === b.length &&
    a.reduce<boolean>(
      (prev, current, index) => prev && sameDataSeries(current, b[index]),
      true
    )
  );
}

export function sameGraph(a: Graph, b: Graph): boolean {
  return (
    a._id === b._id &&
    a.seriesSameLength === b.seriesSameLength &&
    a.type === b.type &&
    a.sub_type === b.sub_type &&
    a.config === b.config &&
    sameDataSeriess(a.series, b.series)
  );
}

export function sameGraphs(
  a: Graph[] | undefined,
  b: Graph[] | undefined
): boolean {
  if (!a && !b) return true;
  if (!a) return false;
  if (!b) return false;
  return (
    a.length === b.length &&
    a.reduce<boolean>(
      (prev, current, index) => prev && sameGraph(current, b[index]),
      true
    )
  );
}
export function sameDropdown(a: Dropdown, b: Dropdown) {
  return hashDropDown(a) === hashDropDown(b);
}
export function sameDropdowns(
  a: Dropdown[] | undefined,
  b: Dropdown[] | undefined
) {
  if (!a && !b) return true;
  if (!a) return false;
  if (!b) return false;
  return (
    a.length === b.length &&
    a.reduce<boolean>(
      (prev, current, index) => prev && sameDropdown(current, b[index]),
      true
    )
  );
}
export function sameCard(a: Card, b: Card): boolean {
  return (
    a.index === b.index &&
    a.hidden === b.hidden &&
    a.widthLG === b.widthLG &&
    a.widthSM === b.widthSM &&
    a.title === b.title &&
    a._id === b._id &&
    sameDropdowns(a.dropdown, b.dropdown) &&
    sameGraphs(a.graphs, b.graphs)
  );
}
export function isFullThing(start: string): boolean {
  return (
    start === LAST_FULL_DAY ||
    start === LAST_FULL_WEEK ||
    start === LAST_FULL_MONTH ||
    start === LAST_FULL_YEAR
  );
}
export function alterTime(
  date: Date,
  granularity: RangeEnum | string,
  negative = false
) {
  const l = new Date(granularity);
  if (!Number.isNaN(l.getTime())) {
    date.setTime(l.getTime());
    return;
  }
  switch (granularity) {
    case RangeEnum._1M:
      date.setMinutes(date.getMinutes() + (negative ? -1 : 1));
      break;
    case RangeEnum._2M:
      date.setMinutes(date.getMinutes() + (negative ? -2 : 2));
      break;
    case RangeEnum._5M:
      date.setMinutes(date.getMinutes() + (negative ? -5 : 5));
      break;
    case RangeEnum._15M:
      date.setMinutes(date.getMinutes() + (negative ? -15 : 15));
      break;
    case RangeEnum._30M:
      date.setMinutes(date.getMinutes() + (negative ? -30 : 30));
      break;
    case RangeEnum._1H:
      date.setHours(date.getHours() + (negative ? -1 : 1));
      break;
    case RangeEnum._2H:
      date.setHours(date.getHours() + (negative ? -2 : 2));
      break;
    case RangeEnum._4H:
      date.setHours(date.getHours() + (negative ? -4 : 4));
      break;
    case RangeEnum._6H:
      date.setHours(date.getHours() + (negative ? -6 : 6));
      break;
    case RangeEnum._12H:
      date.setHours(date.getHours() + (negative ? -12 : 12));
      break;
    case RangeEnum._1D:
      date.setDate(date.getDate() + (negative ? -1 : 1));
      break;
    case RangeEnum._3D:
      date.setDate(date.getDate() + (negative ? -3 : 3));
      break;
    case RangeEnum._7D:
      date.setDate(date.getDate() + (negative ? -7 : 7));
      break;
    case RangeEnum._30D:
      date.setDate(date.getDate() + (negative ? -30 : 30));
      break;
    case RangeEnum._90D:
      date.setDate(date.getDate() + (negative ? -90 : 90));
      break;
    case RangeEnum._365D:
      date.setDate(date.getDate() + (negative ? -365 : 365));
      break;
    default:
      date.setDate(date.getDate() + (negative ? -1 : 1));
      break;
  }
}

export function rangeEnumToMS(r: RangeEnum): number {
  if (r === RangeEnum.NOW_) return 1;
  const num = parseInt(r, 10);
  if (r.toUpperCase().indexOf("D") !== -1) return num * DAY_IN_MS;
  if (r.toUpperCase().indexOf("M") !== -1) return num * MIN_IN_MS;
  if (r.toUpperCase().indexOf("H") !== -1) return num * HOUR_IN_MS;
  return 1;
}

export function numPoints(
  start: string,
  granularity: RangeEnum,
  groupBy?: GroupByEnum
): number {
  if (groupBy === GroupByEnum.MONTH_DAY) {
    return 31;
  }
  if (groupBy === GroupByEnum.WEEK_DAY) {
    return 7;
  }
  if (groupBy === GroupByEnum.MONTH) {
    return 12;
  }
  if (groupBy === GroupByEnum.HOUR) {
    return 24;
  }

  const l = new Date(start);
  const realStart = new Date();
  const now = new Date();
  if (!Number.isNaN(l.getTime())) {
    realStart.setTime(l.getTime());
  } else if (start === "Last full day") {
    return Math.floor(DAY_IN_MS / rangeEnumToMS(granularity));
  } else {
    realStart.setTime(now.getTime() - rangeEnumToMS(start as RangeEnum));
  }
  const diff = now.getTime() - realStart.getTime();
  return Math.floor(diff / rangeEnumToMS(granularity));
}
export function mergeStringArraysIntoObject(
  array1: string[],
  array2: string[]
): Record<string, boolean> {
  const result: Record<string, boolean> = {};

  // Combine all unique strings from both arrays
  const allStrings = [...new Set([...array1, ...array2])];

  // Set boolean properties based on occurrence in both arrays
  allStrings.forEach((str) => {
    result[str] = array1.includes(str) && array2.includes(str);
  });

  return result;
}

export function categoryName(s: DataSeries): string {
  if (s.name.indexOf("#:") === -1) {
    return "No Category";
  }
  return s.name.split("#:")[0];
}

export function seriesWithoutCategoryName(s: DataSeries): string {
  return s.name.replace(categoryName(s), "").replace("#:", "").trim();
}
