import { Reducer } from "redux";
import {
  AdminActions,
  AdminActionTypes,
  AdminState,
} from "../types/adminTypes";
import { id } from "../../Util/utils";

const initialState: AdminState = {
  users: [],
  sitesOfUser: {},
  bucketsOfUser: {},
  status: "idle",
  keywords: {
    de: {},
    it: {},
    en: {},
  },
  filterOfBuckets: {},
  currentSiteModifying: undefined,
};

const adminReducer: Reducer<AdminState> = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: AdminActions
) => {
  let found = false;
  const { type } = action;
  switch (type) {
    case AdminActionTypes.GET_USERS:
      return {
        ...state,
        status: "idle",
        users: action.payload,
      };
    case AdminActionTypes.SET_MODIFYING_SITE:
      return {
        ...state,
        status: "idle",
        currentSiteModifying: action.payload,
      };
    case AdminActionTypes.USER_PATCH:
      found = false;
      return {
        ...state,
        status: "idle",
        users: state.users
          .map((us) => {
            if (us._id === action.payload._id) {
              found = true;
              return action.payload;
            }
            return us;
          })
          .concat(found ? [] : [action.payload]),
      };
    case AdminActionTypes.GET_BUCKETS:
      return {
        ...state,
        status: "idle",
        bucketsOfUser: { ...state.bucketsOfUser, ...action.payload },
      };
    case AdminActionTypes.GET_FILTERS:
      return {
        ...state,
        status: "idle",
        filterOfBuckets: { ...state.filterOfBuckets, ...action.payload },
      };
    case AdminActionTypes.GET_FAILED:
      return {
        ...state,
        status: "failed",
      };
    case AdminActionTypes.GET_KEYWORDS:
      return {
        ...state,
        status: "idle",
        keywords: action.payload,
      };
    case AdminActionTypes.GET_SITES:
      return {
        ...state,
        status: "idle",
        sitesOfUser: { ...state.sitesOfUser, ...action.payload },
      };
    case AdminActionTypes.PATCH_SITE:
      return {
        ...state,
        status: "idle",
        sitesOfUser: {
          ...state.sitesOfUser,
          [action.payload.userId]: state.sitesOfUser[action.payload.userId]
            ? state.sitesOfUser[action.payload.userId]
                .map((oldS) => {
                  if (id(oldS) === id(action.payload.site)) {
                    found = true;
                    return action.payload.site;
                  }
                  return oldS;
                })
                .concat(found ? [] : [action.payload.site])
            : [action.payload.site],
        },
        currentSiteModifying: action.payload.site,
      };
    case AdminActionTypes.PATCH_KEYWORD:
      return {
        ...state,
        status: "idle",
        keywords: {
          ...state.keywords,
          [action.payload.lang]: {
            ...state.keywords[action.payload.lang],
            [action.payload.key]: action.payload.value,
          },
        },
      };
    default:
      return state;
  }
};

export default adminReducer;
