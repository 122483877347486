import { Dispatch } from "redux";
import {
  CancelablePromise,
  DataSeries,
  Dropdown,
  GroupByEnum,
  InfluxData,
} from "../../API/_generated";
import client from "../../API";
import { AdminActionTypes } from "../types/adminTypes";
import {
  hashInfluxData,
  InfluxDataActionTypes,
  MeasurementHash,
  MeasurementResponse,
  MeasurementStore,
} from "../types/influxDataTypes";
import { id } from "../../Util/utils";

export function getMultipleData(
  dispatch: Dispatch,
  from: DataSeries[],
  dropdown: Dropdown,
  hashed: MeasurementHash[]
): Promise<void> {
  dispatch({ type: InfluxDataActionTypes.GET_DATA });
  return new Promise((outerResolve) => {
    const promises: Promise<MeasurementResponse[]>[] = [];
    // GET EACH DATA SERIES
    from.forEach((data) => {
      const groupBys = data.availableGroupBy
        ? JSON.parse(data.availableGroupBy)
        : {};
      const groupBy = groupBys[dropdown.start] ?? data.groupBy;
      promises.push(
        new Promise((resolve) => {
          const innerPromises: Promise<MeasurementResponse>[] = [];
          // GET EACH INFLUX DATA
          data.data?.forEach((iData) => {
            const hash = hashInfluxData(
              iData,
              dropdown,
              data.customWindow,
              groupBy,
              id(data)
            );
            if (!hashed.includes(hash))
              innerPromises.push(
                getData(iData, dropdown, data.customWindow, hash, groupBy)
              );
          });
          Promise.allSettled(innerPromises).then((results) => {
            const retval: MeasurementResponse[] = [];
            results.forEach((res) => {
              if (res.status !== "rejected") {
                retval.push(res.value);
              }
            });
            resolve(retval);
          });
        })
      );
    });
    Promise.allSettled(promises).then(
      (results) => {
        const retval: Record<MeasurementHash, MeasurementStore> = {};
        results.forEach((res) => {
          if (res.status !== "rejected") {
            res.value.forEach((measRes) => {
              retval[measRes.hash] = {
                value: measRes.value,
                fetchDate: measRes.fetchDate,
              };
            });
          }
        });
        dispatch({
          type: InfluxDataActionTypes.GET_DATA_MULTIPLE,
          payload: retval,
        });
        outerResolve();
      },
      () => {
        outerResolve();
      }
    );
  });
}

export function getData(
  from: InfluxData,
  dropdown: Dropdown,
  customWindow: boolean | undefined,
  hash: MeasurementHash,
  groupBy: GroupByEnum | undefined,
  dispatch?: Dispatch
) {
  return new CancelablePromise<MeasurementResponse>((resolve) => {
    client()
      .getAggregateMeasurement(from, dropdown, customWindow, groupBy)
      .then(
        (value) => {
          const payload: MeasurementResponse = {
            hash,
            value,
            fetchDate: new Date().getTime(),
          };
          if (dispatch)
            dispatch({
              type: InfluxDataActionTypes.GET_DATA_SUCCESS,
              payload,
            });
          resolve(payload);
        },
        () => {
          const payload: MeasurementResponse = {
            hash,
            value: [],
            fetchDate: new Date().getTime(),
          };

          if (dispatch)
            dispatch({
              type: InfluxDataActionTypes.GET_DATA_SUCCESS,
              payload,
            });

          resolve(payload);
        }
      );
  });
}

export function getFilters(dispatch: Dispatch, bucket: string): void {
  client()
    .getFiltersOfBucket(bucket)
    .then((filters) => {
      dispatch({
        type: AdminActionTypes.GET_FILTERS,
        payload: { [bucket]: filters },
      });
    });
}
