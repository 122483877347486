import {
  LogoutOutlined,
  MenuUnfoldOutlined,
  PicLeftOutlined,
  TranslationOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { AiFillControl, AiOutlineHome } from "react-icons/ai";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { RiChargingPileLine } from "react-icons/ri";
import { BiLineChart, BiWorld } from "react-icons/bi";
import { FiSettings } from "react-icons/fi";
import { VscGitCompare } from "react-icons/vsc";
import { RootState } from "../redux/store";
import { IconType } from "../Util/types";
import { LayoutProps } from "./types";

const energy = require("../Assets/vectors/energy.png");

const co2 = require("../Assets/vectors/co2.png");

function getIcon(key: string | IconType) {
  switch (key) {
    case "dashboard":
      return <AiOutlineHome className="icon-size-22" />;
    case "analysis":
      return <AiFillControl className="icon-size-22" />;
    case "energy-production":
      return (
        <img
          src={energy}
          width="22px"
          height="22px"
          title="energy-production"
          alt="energy-production-icon"
        />
      );
    case "digitalization":
      return <BiWorld className="icon-size-22" />;
    case "emobility":
      return <RiChargingPileLine className="icon-size-22" />;
    case "co2emission":
      return (
        <img
          src={co2}
          width="22px"
          height="22px"
          title="co2emission"
          alt="co2emission-icon"
        />
      );
    case "load-peak":
      return <BiLineChart className="icon-size-22" />;
    default:
      return null;
  }
}

const MainMenu: React.FC<LayoutProps> = ({ active }) => {
  const { sites, isAdmin } = useSelector<RootState, RootState["userReducer"]>(
    (state) => state.userReducer
  );

  /* const dispatch = useDispatch();
   useEffect(() => {
     if (user && !isAdmin) getMySites(dispatch).then();
   }, [dispatch, isAdmin, user]);*/
  const { t } = useTranslation();
  const onLogout = useCallback(() => {
    localStorage.clear();
    window.location.reload();
  }, []);
  const activeKeys = useMemo(() => [active], [active]);
  return (
    <div>
      <Menu
        mode="inline"
        defaultSelectedKeys={activeKeys}
        style={{
          height: "100vh",
          background: "var(--lightBackground)",
          padding: "10px 10px 10px 0",
        }}
      >
        {isAdmin ? (
          <>
            <Menu.Item
              key="dashboard"
              icon={<UserOutlined className="icon-size-22" />}
            >
              <Link to="/admin-dashboard">{t("Clients Information")}</Link>
            </Menu.Item>
            <Menu.Item
              key="admin-language"
              icon={<TranslationOutlined className="icon-size-22" />}
            >
              <Link to="/admin-language">{t("Language Manager")}</Link>
            </Menu.Item>

            {/* <Menu.Item
              key="admin-subnodes"
              icon={<ControlOutlined className="icon-size-22" />}
            >
              <Link to="/admin-subnodes">{t("SubNodes Control")}</Link>
            </Menu.Item>*/}
            <Menu.Item
              key="admin-sidebar"
              icon={<MenuUnfoldOutlined className="icon-size-22" />}
            >
              <Link to="/admin-sidebar">{t("Users Sidebar")}</Link>
            </Menu.Item>
            <Menu.Item
              key="admin-sites"
              icon={<PicLeftOutlined className="icon-size-22" />}
            >
              <Link to="/admin-sites">{t("Dynamic Graphs")}</Link>
            </Menu.Item>
            <Menu.Item
              key="admin-details"
              icon={<VscGitCompare className="icon-size-22" />}
            >
              <Link to="/admin-details">{t("Admin Details")}</Link>
            </Menu.Item>
          </>
        ) : (
          <>
            {sites.map((site) => (
              <Menu.Item key={site.icon} icon={getIcon(site.icon)}>
                <Link to={`/${site.icon}`}>{t(site.title)}</Link>
              </Menu.Item>
            ))}
            <Menu.Item
              key="details"
              icon={<VscGitCompare className="icon-size-22" />}
            >
              <Link to="/details">{t("Details")}</Link>
            </Menu.Item>

            <Menu.Item
              key="settings"
              icon={<FiSettings className="icon-size-22" />}
            >
              <Link to="/settings">{t("Settings")}</Link>
            </Menu.Item>
          </>
        )}

        <Menu.Item
          key="logout"
          icon={<LogoutOutlined className="icon-size-22" />}
        >
          <Link to="/login" onClick={onLogout}>
            {t("Logout")}
          </Link>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default MainMenu;
