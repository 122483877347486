/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "../../Styles/pages/servicesDashboard.css";
import { Button, Modal, Table } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { ColumnsType } from "antd/es/table";
import { RootState } from "../../redux/store";
import { getClients } from "../../redux/actions/adminDashboardActions";
import CreateClientModal from "../../Components/admin/CreateClientModal";
import { User } from "../../API/_generated";
import { id } from "../../Util/utils";
import Mainlayout from "../../Layout/MainLayout";

const AdminDashboard = () => {
  const users = useSelector<RootState, RootState["adminReducer"]["users"]>(
    (state) => state.adminReducer.users
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<User>();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    getClients(dispatch);
  }, [dispatch]);

  const showModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleOk = useCallback(() => {
    setIsModalOpen(false);
    setIsUpdateModalOpen(false);
  }, []);

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
    setIsUpdateModalOpen(false);
  }, []);

  const deleteHandler = useCallback((val: User) => {
    /* await deleteClient({ id: val?._id });
        await deleteDynamic({ id: val?._id });
        await deleteSidebar({ id: val?._id });
        TODO endpoints
        */
  }, []);

  const renderButtons = useCallback(
    (val: any, record: User) => (
      <div>
        <Button
          type="link"
          onClick={() => {
            setIsUpdateModalOpen(true);
            setCurrentUser(record);
          }}
        >
          {t("Update")}
        </Button>
        <Button
          type="link"
          onClick={() => {
            deleteHandler(record);
          }}
        >
          {t("Delete")}
        </Button>
      </div>
    ),
    [deleteHandler, t]
  );

  const columns = useMemo<ColumnsType<User>>(
    () => [
      {
        title: t("First Name"),
        dataIndex: "firstname",
        key: "firstname",
      },
      {
        title: t("Last Name"),
        dataIndex: "secondname",
        key: "secondname",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: t("Action"),
        dataIndex: "action",
        key: "action",
        render: renderButtons,
      },
    ],
    [renderButtons, t]
  );

  const keyExtractor = useCallback(
    (row: User) => `user-table-row-${id(row)}`,
    []
  );
  return (
    <Mainlayout active="dashboard">
      <div>
        <div className="home-main">
          <Modal
            title={t("Create Client")}
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            width={800}
          >
            <CreateClientModal handleClose={handleCancel} />
          </Modal>
          <Modal
            title={t("Update Client")}
            open={isUpdateModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
            width={800}
          >
            <CreateClientModal data={currentUser} handleClose={handleCancel} />
          </Modal>

          <div className="services-dashboard-create">
            <Button onClick={showModal}>{t("Create Client")}</Button>
          </div>

          <Table columns={columns} dataSource={users} rowKey={keyExtractor} />
        </div>
      </div>
    </Mainlayout>
  );
};

export default AdminDashboard;
