/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum GroupByEnum {
    HOUR = 'hour',
    MONTH_DAY = 'monthDay',
    WEEK_DAY = 'weekDay',
    MONTH = 'month',
}
