import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  LinearScale,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useMemo } from "react";
import { Pie, PieConfig, PlotEvent } from "@ant-design/plots";
import { RootState } from "../../redux/store";
import {
  applyLambda,
  DataSeriesResponse,
  getSeriesColor,
} from "../../redux/types/influxDataTypes";
import CustomTooltip, { TooltipData } from "./CustomTooltip";
import { ModalActionTypes } from "../../redux/types/modalTypes";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Filler,
  ArcElement,
  Tooltip
);

type DoughnutProps = {
  series: DataSeriesResponse[];
};

const AntdDonut: React.FC<DoughnutProps> = ({ series }) => {
  const colors = useSelector<RootState, RootState["userReducer"]["colors"]>(
    (state) => state.userReducer.colors
  );
  const RenderCustomTooltip = useCallback(
    (title: string, data: TooltipData[]) => (
      <CustomTooltip title={title} data={data} />
    ),
    []
  );
  const config = useMemo<PieConfig>(() => {
    const parsed: TooltipData[] = [];
    let totalSum = 0;
    let unitName = "";
    series.forEach((s, index) => {
      unitName = s.unit;
      const data = applyLambda(s);
      if (data.length !== 0) {
        const sum = Math.max(data[0].value ?? 0, 0);
        parsed.push({
          name: s.name,
          color: getSeriesColor(s.color, index, colors),
          timestamp: s.name,
          unitName: s.unit,
          value: sum,
        });
        totalSum += sum;
      }
    });
    return {
      data: parsed,
      height: 340,
      autoFit: true,
      angleField: "value",
      colorField: "name",
      legend: {
        position: "bottom",
        max: 10,
        itemValue: {
          spacing: 10,
          formatter: (text, item, idx) =>
            `${parsed[idx].value.toFixed(2)} ${unitName}`,
        },
      },
      label: false,
      radius: 1,
      color: parsed.map((p) => p.color),
      innerRadius: 0.8,
      tooltip: {
        domStyles: {
          "g2-tooltip": {
            background: "transparent",
            borderRadius: 0,
            padding: "0",
            boxShadow: "none",
          },
        },
        customContent: RenderCustomTooltip,
      },
      statistic: {
        title: false,
        content: {
          content: `${totalSum.toFixed(2)} ${unitName}`,
        },
      },
    };
  }, [series, RenderCustomTooltip, colors]);
  const dispatch = useDispatch();
  const eventHandler = useCallback(
    (graph: any, event: PlotEvent) => {
      if (event && event.type === "legend:click") {
        const key = event?.target?.cfg?.delegateObject?.item?.id;
        series.forEach((s) => {
          if (s.onClickCardId && key === s.name) {
            dispatch({
              type: ModalActionTypes.SHOW_MODAL,
              payload: s.onClickCardId,
            });
          }
        });
      }
    },
    [dispatch, series]
  );

  return <Pie {...config} onEvent={eventHandler} animation={false} />;
};

export default AntdDonut;
