import {
  AuthResponse,
  AuthService,
  BucketsService,
  CancelablePromise,
  CardCreate,
  CardService,
  CardUpdate,
  Credentials,
  Dropdown,
  GroupByEnum,
  InfluxData,
  LanguageEnum,
  MeasurmentService,
  OpenAPI,
  PasswordChange,
  SiteCreate,
  SiteService,
  SiteUpdate,
  TranslationService,
  TranslationUpdate,
  User,
  UserCreate,
  UserPatch,
  UserService,
} from "./_generated";
import { errorLogging, formatDate } from "../Util/utils";
import {
  LAST_FULL_DAY,
  LAST_FULL_MONTH,
  LAST_FULL_WEEK,
  LAST_FULL_YEAR,
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_TOKEN,
  PROD_ENDPOINT,
  THIS_DAY,
  THIS_MONTH,
  THIS_WEEK,
  THIS_YEAR,
} from "../Util/constants";

class Client {
  public static token: string | undefined = undefined;

  private static JWT_STORAGE = "jwt_token";

  private static JWT_REFRESH_STORAGE = "jwt_refresh_token";

  private static refreshToken: string | undefined = undefined;

  private static __instance: Client | null = null;

  private endpoint = `https://${PROD_ENDPOINT}`;

  private constructor(endpoint: string) {
    this.endpoint = `https://${endpoint}`;
    OpenAPI.TOKEN = Client.getToken;
    OpenAPI.BASE = this.endpoint;
  }

  public static getInstance(endpoint?: string): Client {
    if (Client.__instance === null) {
      if (!endpoint) endpoint = PROD_ENDPOINT;
      errorLogging(`first client call with ${endpoint}`);
      Client.__instance = new Client(endpoint);
    }
    return Client.__instance;
  }

  public static getToken(): Promise<string> {
    return new Promise<string>((resolve) => {
      if (Client.token) resolve(Client.token);

      const val = window.localStorage.getItem(LOCAL_STORAGE_TOKEN);
      if (val) {
        Client.token = val;
        resolve(val);
      }
      resolve("");
    });
  }

  public static getRefreshToken(): Promise<string> {
    return new Promise<string>((resolve) => {
      if (Client.refreshToken) resolve(Client.refreshToken);
      const val = window.localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);
      if (val) {
        Client.refreshToken = val;
        resolve(val);
      }
      resolve("");
    });
  }

  public static setToken(response: AuthResponse) {
    Client.token = response.access_token;
    Client.refreshToken = response.refresh_token;
    window.localStorage.setItem(LOCAL_STORAGE_TOKEN, response.access_token);
    window.localStorage.setItem(
      LOCAL_STORAGE_REFRESH_TOKEN,
      response.refresh_token
    );
  }

  public static async removeToken() {
    Client.token = undefined;
    Client.refreshToken = undefined;
    window.localStorage.removeItem(LOCAL_STORAGE_TOKEN);
    window.localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
  }

  setEndpoint(endpoint: string) {
    this.endpoint = `https://${endpoint}`;
    OpenAPI.BASE = this.endpoint;
  }

  getEndpoint(): string {
    return this.endpoint.replace("https://", "");
  }

  public getAggregateMeasurement(
    from: InfluxData,
    dropdown: Dropdown,
    customWindow: boolean | undefined,
    groupBy: GroupByEnum | undefined
  ) {
    const fullThing =
      dropdown.start === LAST_FULL_DAY ||
      dropdown.start === LAST_FULL_WEEK ||
      dropdown.start === LAST_FULL_MONTH ||
      dropdown.start === LAST_FULL_YEAR ||
      dropdown.start === THIS_DAY ||
      dropdown.start === THIS_WEEK ||
      dropdown.start === THIS_MONTH ||
      dropdown.start === THIS_YEAR;
    const thisThing =
      dropdown.start === THIS_DAY ||
      dropdown.start === THIS_WEEK ||
      dropdown.start === THIS_MONTH ||
      dropdown.start === THIS_YEAR;
    const now = new Date();
    const startDate = new Date(now.getTime());
    const endDate = new Date(now.getTime());
    if (dropdown.start === LAST_FULL_DAY) {
      startDate.setDate(now.getDate() - 1);
    } else if (dropdown.start === LAST_FULL_WEEK) {
      startDate.setDate(
        startDate.getDate() - ((startDate.getDay() + 6) % 7) - 8
      );
      endDate.setDate(endDate.getDate() - ((endDate.getDay() + 6) % 7) - 1);
    } else if (dropdown.start === LAST_FULL_MONTH) {
      endDate.setDate(0);
      startDate.setMonth(startDate.getMonth() - 1);
      startDate.setDate(0);
    } else if (dropdown.start === LAST_FULL_YEAR) {
      startDate.setFullYear(startDate.getFullYear() - 1, 0, 1);
      endDate.setMonth(0, 1);
    } else if (dropdown.start === THIS_WEEK) {
      startDate.setDate(now.getDate() - ((now.getDay() + 6) % 7));
    } else if (dropdown.start === THIS_MONTH) {
      startDate.setDate(1);
    } else if (dropdown.start === THIS_YEAR) {
      startDate.setMonth(0);
      startDate.setDate(1);
    }
    startDate.setHours(0, 0, 0); // -endDate.getTimezoneOffset()
    if (thisThing) {
      endDate.setMinutes(0, 0);
    } else {
      endDate.setHours(0, 0, 0); // -endDate.getTimezoneOffset()
    }
    return MeasurmentService.getAggregateValuesMeasurmentGetAggregateValuesPost(
      from.bucket ?? "",
      fullThing
        ? startDate.toISOString()
        : dropdown.start.length > 10
        ? dropdown.start
        : `-${dropdown.start.toLowerCase()}`,
      fullThing && !thisThing ? endDate.toISOString() : dropdown.stop,
      dropdown.granularity.toLowerCase(),
      from.function ?? "mean",
      "mean",
      from.filters ?? [],
      false,
      customWindow,
      groupBy
    );
  }

  public getNearestMeasurement(
    node: string,
    timestamp: Date,
    measurement: string
  ) {
    return MeasurmentService.getNearestValueMeasurmentGetNearestValueGet(
      node,
      measurement,
      formatDate(timestamp, "de", true)
    );
  }

  logout() {
    return Client.removeToken();
  }

  me() {
    return UserService.getUserMeUserMeGet();
  }

  users() {
    return new CancelablePromise<User[]>((resolve, reject) => {
      UserService.getUsersUserGet().then((users) => {
        resolve(users);
      }, reject);
    });
  }

  sitesOfUser(id: string) {
    return SiteService.getSitesByUserSitesUserUserIdGet(id);
  }

  mySites() {
    return SiteService.getSitesSitesGet();
  }

  userCreate(user: UserCreate) {
    return UserService.createUserUserPost(user);
  }

  userPatch(id: string, user: UserPatch) {
    return UserService.patchUserUserUserIdPatch(id, user);
  }

  changePw(body: PasswordChange) {
    return AuthService.changePasswordAuthChangePasswordPost(body);
  }

  changeUserPw(userId: string, body: PasswordChange) {
    return AuthService.changeUserPasswordAuthChangeUserPasswordUserIdPost(
      userId,
      body
    );
  }

  getBuckets() {
    return BucketsService.getMyBucketsBucketGet();
  }

  getBucketsOfUser(id: string) {
    return BucketsService.getBucketByUserBucketUserIdGet(id);
  }

  addBucketToUser(id: string, bucket: string) {
    return BucketsService.addBucketToUserBucketUserIdPut(id, bucket);
  }

  removeBucketFromUser(id: string, bucket: string) {
    return BucketsService.removeBucketFromUserBucketUserIdDelete(id, bucket);
  }

  // eslint-disable-next-line camelcase
  login(creds: Credentials) {
    return new Promise<User>((resolve, reject) => {
      AuthService.loginAuthLoginPost(creds).then((response) => {
        Client.setToken(response);
        UserService.getUserMeUserMeGet().then((user) => {
          resolve(user);
        }, reject);
      }, reject);
    });
  }

  createUser(user: UserCreate) {
    return UserService.createUserUserPost(user);
  }

  patchUser(id: string, user: UserPatch) {
    return UserService.patchUserUserUserIdPatch(id, user);
  }

  patchMe(user: UserPatch) {
    return UserService.patchUserMeUserMePatch(user);
  }

  addSiteToUser(id: string, site: SiteCreate) {
    return SiteService.addSiteToUserSitesPost(id, site);
  }

  removeSiteFromUser(siteId: string) {
    return SiteService.deleteSiteSitesSiteIdDelete(siteId);
  }

  getKeywords(lang: LanguageEnum) {
    return TranslationService.getTranslationsTranslationLangGet(lang);
  }

  deleteKeyword(keyword: string) {
    return TranslationService.deleteTranslationTranslationKeyDelete(keyword);
  }

  patchKeyword(body: TranslationUpdate) {
    return TranslationService.updateTranslationTranslationPut(body);
  }

  getFiltersOfBucket(bucket: string) {
    return BucketsService.getBucketFiltersBucketFiltersBucketGet(bucket);
  }

  saveCard(siteId: string, card: CardCreate) {
    return CardService.addCardToSiteCardPost(siteId, card);
  }

  updateCard(cardId: string, card: CardUpdate) {
    return CardService.updateCardCardCardIdPut(cardId, card);
  }

  updateSite(siteId: string, body: SiteUpdate) {
    return SiteService.updateSiteSitesSiteIdPut(siteId, body);
  }
}

const client = Client.getInstance;

export default client;
