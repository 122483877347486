import React, { useCallback, useMemo } from "react";
import { Line, LineConfig, PlotEvent } from "@ant-design/plots";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  applyLambda,
  DataSeriesResponse,
  getSeriesColor,
  sameLength,
} from "../../redux/types/influxDataTypes";
import { RootState } from "../../redux/store";
import CustomTooltip, { TooltipData } from "./CustomTooltip";
import { determineDateTimeOptions, valuePairLabel } from "../../Util/utils";
import { GroupByEnum, RangeEnum } from "../../API/_generated";
import { GraphCustomConfig } from "../../Util/types";
import { ModalActionTypes } from "../../redux/types/modalTypes";

type LineProps = {
  series: DataSeriesResponse[];
  start: string;
  stop: string;
  granularity: RangeEnum;
  customConfig?: string;
  seriesSameLength: boolean | undefined;
};

const AntdLine: React.FC<LineProps> = ({
  series,
  customConfig,
  start,
  stop,
  seriesSameLength,
  granularity,
}) => {
  const colors = useSelector<RootState, RootState["userReducer"]["colors"]>(
    (state) => state.userReducer.colors
  );
  const RenderCustomTooltip = useCallback(
    (title: string, data: TooltipData[]) => (
      <CustomTooltip title={title} data={data} />
    ),
    []
  );
  const configParsed = useMemo<GraphCustomConfig>(() => {
    if (!customConfig) return {};
    try {
      return JSON.parse(customConfig);
    } catch (e) {
      return {};
    }
  }, [customConfig]);
  const smooth = useMemo(() => {
    if ("smooth" in configParsed) return configParsed.smooth;
    return false;
  }, [configParsed]);
  const slider = useMemo(() => {
    if ("slider" in configParsed) return configParsed.slider;
    return false;
  }, [configParsed]);
  const gridLines = useMemo(() => {
    if ("gridLines" in configParsed) return configParsed.gridLines;
    return false;
  }, [configParsed]);
  const gridColumns = useMemo(() => {
    if ("gridColumns" in configParsed) return configParsed.gridColumns;
    return false;
  }, [configParsed]);
  const bigDots = useMemo(() => {
    if ("bigDot" in configParsed) return configParsed.bigDot;
    return false;
  }, [configParsed]);
  const dashed = useMemo(() => {
    if (
      "dashed" in configParsed &&
      configParsed.dashed &&
      Array.isArray(configParsed.dashed)
    )
      return configParsed.dashed;
    return [];
  }, [configParsed]);
  const legend = useMemo(() => {
    if ("legend" in configParsed && configParsed.legend)
      return configParsed.legend;
    return "bottom";
  }, [configParsed]);

  const stacked = useMemo(() => {
    if ("stacked" in configParsed) return configParsed.stacked;
    return false;
  }, [configParsed]);
  const { t } = useTranslation();
  const config = useMemo<LineConfig>(() => {
    const parsed: TooltipData[] = [];
    let unitName = "";
    const colorsSeries: string[] = [];
    series.forEach((s, index) => {
      const groupBys = s.availableGroupBy ? JSON.parse(s.availableGroupBy) : {};
      const groupBy = groupBys[start] ?? s.groupBy;
      unitName = t(s.unit);
      colorsSeries.push(getSeriesColor(s.color, index, colors));
      const options = determineDateTimeOptions(
        s.lambdaFn && s.lambdaFn.indexOf("onlyLastDay") !== -1
          ? GroupByEnum.HOUR
          : s.lambdaFn && s.lambdaFn.indexOf("onlyLastWeek") !== -1
          ? GroupByEnum.WEEK_DAY
          : s.lambdaFn && s.lambdaFn.indexOf("onlyLastMonth") !== -1
          ? GroupByEnum.MONTH_DAY
          : start,
        granularity,
        groupBy,
        stop
      );
      const data = applyLambda(s);
      data.forEach((r) => {
        parsed.push({
          name: s.name,
          color: getSeriesColor(s.color, index, colors),
          timestamp: valuePairLabel(r, options, groupBy),
          unitName,
          value: r.value,
        });
      });
    });
    return {
      data: seriesSameLength ? sameLength(parsed, series.length) : parsed,
      smooth,
      isStack: stacked,
      height: 340,
      xField: "timestamp",
      yField: "value",
      color: colorsSeries,
      seriesField: "name",
      autoFit: true,
      slider: slider
        ? {
            start: 0,
            end: 1,
          }
        : undefined,
      lineStyle: (datum) =>
        dashed.indexOf(datum.name) === -1 ? undefined : { lineDash: [10, 10] },
      point: bigDots
        ? {
            size: 7,
            shape: "circle",
            style: (datum) => {
              const idx = series.findIndex((s) => s.name === datum.name);
              return {
                fill: "white",
                stroke:
                  idx === -1
                    ? undefined
                    : getSeriesColor(series[idx].color, idx, colorsSeries),
                lineWidth: 4,
              };
            },
          }
        : undefined,
      tooltip: {
        domStyles: {
          "g2-tooltip": {
            background: "transparent",
            borderRadius: 0,
            padding: "0",
            boxShadow: "none",
          },
        },
        customContent: RenderCustomTooltip,
      },
      yAxis: {
        label: {
          formatter: (v: any) =>
            `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
        },
        title: {
          text: unitName,
          style: {
            fontWeight: 400,
            fontSize: 14,
          },
        },
        grid: {
          line: {
            style: {
              stroke: "black",
              lineWidth: gridLines ? 0.1 : 0,
            },
          },
          alignTick: true,
          closed: true,
        },
      },
      xAxis: {
        grid: {
          line: {
            style: {
              stroke: "black",
              lineWidth: gridColumns ? 0.1 : 0,
            },
          },
          alignTick: true,
          closed: true,
        },
      },
      legend: {
        position: legend,
      },
    };
  }, [
    RenderCustomTooltip,
    bigDots,
    colors,
    dashed,
    granularity,
    gridColumns,
    gridLines,
    legend,
    series,
    seriesSameLength,
    slider,
    smooth,
    stacked,
    start,
    stop,
    t,
  ]);
  const dispatch = useDispatch();
  const eventHandler = useCallback(
    (_: any, event: PlotEvent) => {
      const key = event?.target?.cfg?.delegateObject?.item?.id;
      if (
        (event.type === "legend:click" || event.type === "element:click") &&
        key
      ) {
        series.forEach((s) => {
          if (s.onClickCardId && key === s.name) {
            dispatch({
              type: ModalActionTypes.SHOW_MODAL,
              payload: s.onClickCardId,
            });
          }
        });
      }
    },
    [dispatch, series]
  );
  // console.log("data  d ", data);
  return (
    <div className="pv-graph">
      <Line {...config} onEvent={eventHandler} />
    </div>
  );
};

export default AntdLine;
