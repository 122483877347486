/* eslint-disable react/no-array-index-key */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputRef,
  Modal,
  Radio,
  Row,
  Select,
} from "antd";
import "../../Styles/pages/servicesDashboard.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { HexColorPicker } from "react-colorful";
import {
  DataSeries,
  Dropdown,
  GroupByEnum,
  InfluxData,
  InterpolationEnum,
  UnitEnum,
} from "../../API/_generated";
import { getBuckets } from "../../redux/actions/adminDashboardActions";
import { AdminSiteContext } from "../../Util/types";
import { id, sameDataSeries } from "../../Util/utils";
import Switch from "../Switches";
import AdminInfluxData from "./AdminInfluxData";
import { updateSite } from "../../redux";
import { RootState } from "../../redux/store";
import { GROUP_BY_OPTIONS, STD_GUTTER } from "../../Util/constants";

const autosize = { minRows: 15 };
type AdminDataSeriesProps = {
  series: DataSeries;
  cardId: string;
  dropdown: Dropdown[];
  graphId: string;
  index: number;
  onClose: () => void;
};
const AdminDataSeries: React.FC<AdminDataSeriesProps> = ({
  series,
  cardId,
  graphId,
  index,
  dropdown,
  onClose,
}) => {
  const [formData, setFormData] = useState<DataSeries>(series);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { site, userId, userSeries } = useContext(AdminSiteContext);
  const [showColorModal, setShowColorModal] = useState(false);
  const innerColorInput = useRef<InputRef>(null);
  const oldCards = useMemo(() => site.cards ?? [], [site]);
  const oldGraphs = useMemo(
    () => oldCards.find((c) => id(c) === cardId)?.graphs ?? [],
    [cardId, oldCards]
  );
  const oldSeries = useMemo(
    () => oldGraphs.find((g) => id(g) === graphId)?.series ?? [],
    [graphId, oldGraphs]
  );
  const buckets = useSelector<
    RootState,
    RootState["adminReducer"]["bucketsOfUser"]
  >((state) => state.adminReducer.bucketsOfUser);
  const bucketsOfUser = useMemo(() => {
    if (userId in buckets) return buckets[userId];
    return undefined;
  }, [buckets, userId]);
  useEffect(() => {
    if (!bucketsOfUser) getBuckets(dispatch, userId);
  }, [dispatch, userId, bucketsOfUser]);
  const onHideColorModal = useCallback(() => {
    setShowColorModal(false);
  }, []);
  const onShowColorModal = useCallback(() => {
    if (innerColorInput.current) innerColorInput.current.focus();
    setShowColorModal(true);
  }, []);
  useEffect(() => {
    setFormData(series);
  }, [series]);
  const hiddenCards = useMemo(() => {
    if (!site.cards) return [];
    return site.cards.filter((c) => c.hidden);
  }, [site.cards]);

  const onSave = useCallback(() => {
    const newCards = oldCards.map((c) =>
      id(c) === cardId
        ? {
            ...c,
            graphs: oldGraphs.map((g) =>
              id(g) === graphId
                ? {
                    ...g,
                    series: oldSeries.map((s, i) =>
                      i === index ? formData : s
                    ),
                  }
                : g
            ),
          }
        : c
    );
    updateSite(dispatch, userId, id(site), {
      ...site,
      cards: newCards,
    });
  }, [
    cardId,
    dispatch,
    formData,
    graphId,
    index,
    oldCards,
    oldGraphs,
    oldSeries,
    site,
    userId,
  ]);

  const handleChange = useCallback(
    (
      e:
        | React.ChangeEvent<HTMLInputElement>
        | React.ChangeEvent<HTMLTextAreaElement>
    ) => {
      setFormData((fd) => ({ ...fd, [e.target.name]: e.target.value }));
    },
    []
  );
  const handleChangeUnit = useCallback((value: UnitEnum) => {
    setFormData((fd) => ({ ...fd, unit: value }));
  }, []);
  const handleChangeOnClick = useCallback((value: string) => {
    setFormData((fd) => ({ ...fd, onClickCardId: value }));
  }, []);
  const handleChangeColor = useCallback((value: string) => {
    setFormData((fd) => ({ ...fd, color: value }));
  }, []);
  const onSaveData = useCallback((data: InfluxData, idx2: number) => {
    let found = false;
    setFormData((fd) => ({
      ...fd,
      data: fd.data
        ? fd.data
            .map((d, idx) => {
              if (idx === idx2) {
                found = true;
                return data;
              }
              return d;
            })
            .concat(found ? [] : [data])
        : [data],
    }));
  }, []);
  const onDeleteData = useCallback((idx2: number) => {
    setFormData((fd) => ({
      ...fd,
      data: fd.data ? fd.data.filter((d, idx) => idx !== idx2) : [],
    }));
  }, []);
  const toggleSwitch = useCallback(() => {
    setFormData((fd) => ({ ...fd, customWindow: !fd.customWindow }));
  }, []);
  const dataChanged = useMemo(
    () => !sameDataSeries(formData, series),
    [series, formData]
  );
  const onAddInfluxData = useCallback(() => {
    const data: InfluxData = {
      bucket: bucketsOfUser?.length ? bucketsOfUser[0] : "",
      filters: [
        {
          key: "_measurement",
          value: "Power",
        },
        { key: "MeasNode", value: "" },
        { key: "Unit", value: "" },
      ],
      function: "last",
      interpolation: InterpolationEnum.LINEAR,
    };
    setFormData((fd) => ({
      ...fd,
      data: fd.data ? fd.data.concat([data]) : [data],
    }));
  }, [bucketsOfUser]);
  const availableGroupBys = useMemo<Record<string, string>>(
    () =>
      formData.availableGroupBy ? JSON.parse(formData.availableGroupBy) : {},
    [formData.availableGroupBy]
  );
  const onChangeGroupBy = useCallback(
    (val: string, start: string) => {
      const newAvailableGroupBy = {
        ...availableGroupBys,
        [start]: val,
      };
      if (val === "") delete newAvailableGroupBy[start];
      setFormData((fd) => ({
        ...fd,
        groupBy: val === "" ? undefined : (val as GroupByEnum),
        availableGroupBy: JSON.stringify(newAvailableGroupBy),
      }));
    },
    [availableGroupBys]
  );
  const groupByOptions = useMemo(
    () =>
      GROUP_BY_OPTIONS.map((g) => ({
        label: t(g.label),
        value: g.value,
      })),
    [t]
  );
  return (
    <Col xs={24}>
      <Divider orientation="center">{t("Config")}</Divider>
      <Form layout="vertical">
        <Row gutter={STD_GUTTER}>
          <Col span={8}>
            <Form.Item label={t("Series Name")}>
              <Input
                name="name"
                value={formData.name}
                onChange={handleChange}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("Unit")}>
              <Select
                id="unit"
                value={formData.unit}
                onChange={handleChangeUnit}
              >
                <Select.Option value={UnitEnum.KWH}>
                  {t(UnitEnum.KWH)}
                </Select.Option>
                <Select.Option value={UnitEnum.KW}>
                  {t(UnitEnum.KW)}
                </Select.Option>
                <Select.Option value={UnitEnum.HZ}>
                  {t(UnitEnum.HZ)}
                </Select.Option>
                <Select.Option value={UnitEnum.V}>
                  {t(UnitEnum.V)}
                </Select.Option>
                <Select.Option value={UnitEnum.EUR}>
                  {t(UnitEnum.EUR)}
                </Select.Option>
                <Select.Option value={UnitEnum.PERCENT}>
                  {t(UnitEnum.PERCENT)}
                </Select.Option>
                <Select.Option value={UnitEnum.THDU}>
                  {t(UnitEnum.THDU)}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={8}>
            <Row gutter={STD_GUTTER}>
              {dropdown.map((d) => (
                <Form.Item label={`${t("Group By")} ${t(d.label)}`}>
                  <Radio.Group
                    options={groupByOptions}
                    onChange={(e) => {
                      onChangeGroupBy(e.target.value as GroupByEnum, d.start);
                    }}
                    defaultValue={availableGroupBys[d.start] ?? ""}
                  />
                </Form.Item>
              ))}
            </Row>
          </Col>
        </Row>
        <Row gutter={STD_GUTTER}>
          <Col span={8}>
            <Form.Item label={t("Custom Window")}>
              <Switch
                value={formData.customWindow ?? false}
                name="customWindow"
                switchHandler={toggleSwitch}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("Color")}>
              <Input
                name="color"
                value={formData.color ?? "#025699"}
                onChange={handleChange}
                onClick={onShowColorModal}
              />
              <Modal
                open={showColorModal}
                onCancel={onHideColorModal}
                onOk={onHideColorModal}
              >
                <Row gutter={STD_GUTTER}>
                  <Col xs={12}>
                    <HexColorPicker
                      color={formData.color ?? "#025699"}
                      onChange={handleChangeColor}
                    />
                  </Col>
                  <Col xs={12}>
                    <span>{t("Color")}</span>
                    <Input
                      name="color"
                      ref={innerColorInput}
                      value={formData.color ?? "#025699"}
                      onChange={handleChange}
                      onPressEnter={onHideColorModal}
                    />
                  </Col>
                </Row>
              </Modal>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={t("On Click")}>
              <Select
                id="unit"
                value={formData.onClickCardId ?? "-1"}
                onChange={handleChangeOnClick}
              >
                {hiddenCards.length === 0 ? (
                  <Select.Option value="-2">
                    {t("No hidden Cards available")}
                  </Select.Option>
                ) : (
                  <Select.Option value="-2">{t("None")}</Select.Option>
                )}
                {hiddenCards.map((hc) => (
                  <Select.Option value={id(hc)} key={`hidden-card-${id(hc)}`}>
                    {hc.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Divider orientation="center">{t("Influx Data")}</Divider>
      <Row gutter={STD_GUTTER} justify="center">
        {!!formData.data &&
          formData.data.map((data, i) => (
            <Col xs={24} key={`influx-data-${i}-${id(formData)}`}>
              <AdminInfluxData
                data={data}
                bucketsOfUser={bucketsOfUser ?? []}
                onSave={onSaveData}
                onDelete={onDeleteData}
                index={i}
              />
            </Col>
          ))}
        <Col>
          <Button onClick={onAddInfluxData} type="primary">
            {t("Add Influx Data")}
          </Button>
        </Col>
      </Row>
      {(formData.data?.length ?? 0) > 3 && (
        <Row gutter={STD_GUTTER}>
          <Col xs={24}>
            <span className="text-error">
              {t("Caution! Too many data sources can hurt the performance!")}
            </span>
          </Col>
        </Row>
      )}
      <Divider orientation="center">{t("Lambda Function")}</Divider>
      <Form layout="vertical">
        <Row gutter={STD_GUTTER}>
          <Col xs={12}>
            <Form.Item label={t("Function Code")}>
              <Input.TextArea
                name="lambdaFn"
                value={formData?.lambdaFn}
                onChange={handleChange}
                autoSize={autosize}
                placeholder="return safeAdd(data1, data2, data3);"
              />
            </Form.Item>
          </Col>
          <Col xs={12}>
            <b>{t("Lambda Explained:")}</b>
            <p>
              {t(
                "Write the function body of your function in this textarea, each Influx Data Result will be passed into this function as data1, data2, data3..."
              )}
            </p>
            <p>{t("Following predefined functions are available:")}</p>
            <b>{t("Adds up all elements from each dataset:")}</b>
            <pre>return safeAdd(data1, data2, data3...);</pre>
            <b>{t("Substract up all elements of data2 from data1:")}</b>
            <pre>return safeDiff(data1, data2);</pre>
            <b>{t("Multiply up all elements from 2 datasets:")}</b>
            <pre>return safeMul(data1, data2);</pre>
            <b>{t("Divides each element: data1[0] / data2[0]:")}</b>
            <pre>return safeDiv(data1, data2);</pre>
            <b>
              {t(
                "Takes the value of the last element and substracts the value of the first element from it:"
              )}
            </b>
            <pre>return lastMinusFirst(data1);</pre>
            <b>{t("For Two Value Percent Graph, multiplier is a constant:")}</b>
            <pre>return twoValuePercentDiff(data1, ?multiplier);</pre>
            <b>{t("Returns only the last element:")}</b>
            <pre>return last(data1);</pre>
          </Col>
        </Row>
        <Row gutter={STD_GUTTER} align="bottom" justify="end">
          <Col>
            <Button type="primary" onClick={dataChanged ? onSave : onClose}>
              {dataChanged ? t("Save Data Series") : t("Close")}
            </Button>
          </Col>
        </Row>
      </Form>
    </Col>
  );
};

export default AdminDataSeries;
