/* eslint-disable react/no-array-index-key */
import React, { useCallback, useMemo } from "react";
import { Button, Col, Row } from "antd";
import "../../Styles/pages/servicesDashboard.css";
import { useTranslation } from "react-i18next";
import { DataSeries } from "../../API/_generated";
import { id } from "../../Util/utils";
import { STD_GUTTER } from "../../Util/constants";

type AdminDataSeriesSmallProps = {
  series: DataSeries;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  index: number;
};
const AdminDataSeriesSmall: React.FC<AdminDataSeriesSmallProps> = ({
  series,
  onEdit,
  onDelete,
  index,
}) => {
  const handleDelete = useCallback(() => {
    onDelete(index);
  }, [index, onDelete]);
  const handleEdit = useCallback(() => {
    onEdit(index);
  }, [index, onEdit]);
  const { t } = useTranslation();
  const style = useMemo(
    () => ({
      color: series.color,
    }),
    [series.color]
  );
  return (
    <Col xs={8} key={id(series)}>
      <Row gutter={STD_GUTTER} justify="space-between">
        <Col xs={24}>
          <span style={style}>
            {series.name} [{series.unit}]
          </span>
        </Col>
        <Col xs={24}>
          <span>
            {series.data?.length ?? 0} {t("Influx Data")}
          </span>
        </Col>
        <Col>
          <Button type="link" onClick={handleDelete}>
            {t("Delete Series")}
          </Button>
        </Col>
        <Col>
          <Button onClick={handleEdit} type="ghost">
            {t("Edit Series")}
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

export default AdminDataSeriesSmall;
