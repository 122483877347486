/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BucketsService {

    /**
     * Get My Buckets
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getMyBucketsBucketGet(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bucket/',
        });
    }

    /**
     * Get Bucket By User
     * @param userId 
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getBucketByUserBucketUserIdGet(
userId: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bucket/{user_id}',
            path: {
                'user_id': userId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Bucket To User
     * @param userId 
     * @param bucket 
     * @returns string Successful Response
     * @throws ApiError
     */
    public static addBucketToUserBucketUserIdPut(
userId: string,
bucket: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/bucket/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'bucket': bucket,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Bucket From User
     * @param userId 
     * @param bucket 
     * @returns string Successful Response
     * @throws ApiError
     */
    public static removeBucketFromUserBucketUserIdDelete(
userId: string,
bucket: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/bucket/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'bucket': bucket,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Bucket Filters
     * @param bucket 
     * @returns string Successful Response
     * @throws ApiError
     */
    public static getBucketFiltersBucketFiltersBucketGet(
bucket: string,
): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/bucket/filters/{bucket}',
            path: {
                'bucket': bucket,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
