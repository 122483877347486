import React from "react";
import { DataSeries, Site } from "../API/_generated";

export declare type IconType =
  | "dashboard"
  | "analysis"
  | "emobility"
  | "co2emission"
  | "energy-production"
  | "load-peak"
  | "digitalization";

export declare type RoutesType = Record<IconType, undefined>;
export declare type MongoDocumentType = { _id?: string };

export const AllSites: Site[] = [
  { icon: "dashboard", _id: "-1", index: 0, cards: [], title: "Dashboard" },
  { icon: "analysis", _id: "-1", index: 1, cards: [], title: "Analysis" },
  { icon: "emobility", _id: "-1", index: 2, cards: [], title: "E-Mobility" },
  {
    icon: "digitalization",
    _id: "-1",
    index: 3,
    cards: [],
    title: "Digitalization",
  },
  { icon: "load-peak", _id: "-1", index: 4, cards: [], title: "Load Peak" },
  {
    icon: "energy-production",
    _id: "-1",
    index: 5,
    cards: [],
    title: "Energy Production",
  },
  {
    icon: "co2emission",
    _id: "-1",
    index: 6,
    cards: [],
    title: "Co2 Emission",
  },
];

export declare type GraphType =
  | "line"
  | "dualAxes"
  | "bar"
  | "heatmap"
  | "doughnut"
  | "twoValPercent";

type LEGEND_OPTIONS =
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "bottom-right"
  | "bottom-left"
  | "top-left"
  | "top-right"
  | "left-top"
  | "left-bottom"
  | "right-top"
  | "right-bottom";

export const LEGEND_DROPDOWN = [
  "top",
  "bottom",
  "left",
  "right",
  "bottom-right",
  "bottom-left",
  "top-left",
  "top-right",
  "left-top",
  "left-bottom",
  "right-top",
  "right-bottom",
];
export declare type GraphCustomConfig = {
  dashed?: string[];
  dualAxesType?: Record<string, "column" | "line">;
  bigDot?: boolean;
  legend?: LEGEND_OPTIONS;
  smooth?: boolean;
  slider?: boolean;
  gridLines?: boolean;
  gridColumns?: boolean;
  data1IsSum?: boolean;
  stacked?: boolean;
  seriesSameColor?: boolean;
  hideSum?: boolean;
  hideSum2?: boolean;
};
export declare type GraphConfig = {
  maxSeries: number;
  minSeries: number;
  subTypes: string[];
  config: GraphCustomConfig;
};
export const BOOLEAN_CUSTOM_CONFIGS: (keyof GraphCustomConfig)[] = [
  "smooth",
  "bigDot",
  "slider",
  "gridLines",
  "stacked",
  "seriesSameColor",
  "gridColumns",
  "data1IsSum",
  "hideSum",
  "hideSum2",
];
export const AllGraphTypes: Record<GraphType, GraphConfig> = {
  line: {
    maxSeries: 10,
    minSeries: 1,
    subTypes: ["area"],
    config: {
      dashed: [],
      bigDot: false,
      legend: "bottom",
      smooth: false,
      slider: true,
      gridLines: true,
      stacked: false,
      gridColumns: false,
    },
  },
  doughnut: {
    maxSeries: 100,
    minSeries: 2,
    subTypes: [],
    config: {
      legend: "bottom",
    },
  },
  dualAxes: {
    maxSeries: 100,
    minSeries: 2,
    subTypes: [],
    config: {
      legend: "bottom",
      smooth: false,
      dualAxesType: {},
      slider: true,
      gridLines: true,
      stacked: false,
      dashed: [],
      gridColumns: false,
    },
  },
  bar: {
    maxSeries: 5,
    minSeries: 1,
    subTypes: ["horizontal", "vertical"],
    config: {
      legend: "bottom",
      gridLines: true,
      gridColumns: false,
      stacked: false,
    },
  },
  heatmap: {
    maxSeries: 10,
    minSeries: 2,
    subTypes: [],
    config: {
      seriesSameColor: true,
    },
  },
  twoValPercent: {
    maxSeries: 10,
    minSeries: 3,
    subTypes: ["circle"],
    config: {
      legend: "bottom",
      data1IsSum: false,
      hideSum: false,
      hideSum2: false,
    },
  },
};
export declare type GraphSubType =
  (typeof AllGraphTypes)[GraphType]["subTypes"];
export declare type TempFilter = { key: string; value: string[] };

export declare type AdminSiteContextType = {
  userId: string;
  userSeries: DataSeries[];
  site: Site;
};
export const AdminSiteContext = React.createContext<AdminSiteContextType>({
  userId: "",
  userSeries: [],
  site: {} as unknown as Site,
});

export declare type CustomSeries = {
  series: DataSeries;
  card: string;
  site: string;
};
export declare type AdminDetailsContextType = {
  availSeries: CustomSeries[];
};
export const AdminDetailsContext = React.createContext<AdminDetailsContextType>(
  {
    availSeries: [],
  }
);
