import {
  AggregateValuePair,
  ApiError,
  DataSeries,
  Dropdown,
  Filter,
  GroupByEnum,
  InfluxData,
} from "../../API/_generated";
import { TooltipData } from "../../Components/charts/CustomTooltip";

export declare type MeasurementStore = {
  value: AggregateValuePair[];
  fetchDate: number;
};

export declare type MeasurementResponse = MeasurementStore & {
  hash: MeasurementHash;
};

export declare type InfluxDataResponse = InfluxData & {
  response: AggregateValuePair[];
};
export declare type MeasurementHash = string;
export declare type DataSeriesResponse = Omit<DataSeries, "data"> & {
  data: InfluxDataResponse[];
};

export type InfluxDataState = {
  readonly data: Record<MeasurementHash, MeasurementStore>;
  readonly status: "idle" | "failed" | "loading";
  readonly error: ApiError | undefined;
};

export enum InfluxDataActionTypes {
  GET_DATA = "@@influxdata/GET_DATA",
  PURGE_DATA = "@@influxdata/PURGE_DATA",
  GET_DATA_SUCCESS = "@@influxdata/GET_DATA_SUCCESS",
  GET_DATA_MULTIPLE = "@@influxdata/GET_DATA_MULTIPLE",
  PURGE_SOME_DATA = "@@influxdata/PURGE_SOME_DATA",
  GET_DATA_FAILED = "@@influxdata/GET_DATA_FAILED",
}

export type InfluxDataActions =
  | {
      type: InfluxDataActionTypes.GET_DATA | InfluxDataActionTypes.PURGE_DATA;
    }
  | {
      type: InfluxDataActionTypes.GET_DATA_SUCCESS;
      payload: MeasurementResponse;
    }
  | {
      type: InfluxDataActionTypes.GET_DATA_MULTIPLE;
      payload: Record<MeasurementHash, MeasurementStore>;
    }
  | {
      type: InfluxDataActionTypes.PURGE_SOME_DATA;
      payload: MeasurementHash[];
    }
  | {
      type: InfluxDataActionTypes.GET_DATA_FAILED;
      payload: ApiError;
    };

export function hashDropDown(dropdown: Dropdown) {
  return `${dropdown.start}-${dropdown.stop}-${dropdown.granularity}`;
}
export function hashFilter(filter: Filter) {
  return `${filter.key}-${filter.value}`;
}
export function hashFilters(filters: Filter[] | undefined) {
  if (!filters) return "";
  return filters.map(hashFilter).join("-");
}
export function hashInfluxData(
  data: InfluxData,
  dropdown: Dropdown,
  customWindow: boolean | undefined,
  groupBy: GroupByEnum | undefined,
  seriesId: string
) {
  return `${hashDropDown(dropdown)}-${customWindow ? "true" : "false"}-${
    data.function
  }-${data.interpolation ? "true" : "false"}-${data.bucket}-${hashFilters(
    data.filters
  )}-${groupBy}-${seriesId}`;
}
export function inTime(data: MeasurementStore): boolean {
  const now = new Date();
  now.setHours(now.getHours() - 1);
  const ms = now.getTime();
  return data.fetchDate > ms;
}

export function getSeriesColor(
  col: string | undefined,
  index: number,
  colors: string[]
): string {
  if (col && col !== "") {
    if (col.indexOf("#") !== -1) return col;
    return `#${col}`;
  }
  return colors[index % colors.length];
}
export function sameLength(
  tooltipData: TooltipData[],
  threshold: number
): TooltipData[] {
  const timestampCountMap: { [timestamp: string]: number } = {};

  // Zähle die Anzahl der Vorkommen für jeden Timestamp
  for (const data of tooltipData) {
    if (data.timestamp.substring(0, 16) in timestampCountMap) {
      timestampCountMap[data.timestamp.substring(0, 16)]++;
    } else {
      timestampCountMap[data.timestamp.substring(0, 16)] = 1;
    }
  }
  const filteredData: TooltipData[] = [];
  for (const data of tooltipData) {
    if (timestampCountMap[data.timestamp.substring(0, 16)] === threshold) {
      filteredData.push(data);
    }
  }
  return filteredData;
}

function safeAdd(...data: AggregateValuePair[][]) {
  const result: AggregateValuePair[] = [];
  if (data.length) {
    for (let i = 0; i < data[0].length; i++) {
      const idx = result.findIndex(
        (r) =>
          r.timestamp.substring(0, 16) === data[0][i].timestamp.substring(0, 16)
      );
      if (idx === -1) {
        result.push({ ...data[0][i] });
      }
    }
    for (let i = 1; i < data.length; i++) {
      result.forEach((d, idx) => {
        const newVal = data[i].find(
          (r) => r.timestamp.substring(0, 16) === d.timestamp.substring(0, 16)
        );
        if (newVal) {
          result[idx].value += newVal.value;
        }
      });
    }
  }
  return result;
}

function safeDiff(...data: AggregateValuePair[][]) {
  const result: AggregateValuePair[] = [];
  if (data.length === 2) {
    for (let i = 0; i < data[0].length; i++) {
      const idx = result.findIndex(
        (r) =>
          r.timestamp.substring(0, 16) === data[0][i].timestamp.substring(0, 16)
      );
      if (idx === -1) {
        result.push({ ...data[0][i] });
      }
    }
    data[1].forEach((d) => {
      const idx = result.findIndex(
        (r) => r.timestamp.substring(0, 16) === d.timestamp.substring(0, 16)
      );
      if (idx !== -1) {
        result[idx].value -= d.value;
      }
    });
  }
  return result;
}

function safeMulScalar(data: AggregateValuePair[], scalar = 1) {
  const result: AggregateValuePair[] = [];
  if (typeof scalar !== "number") return data;
  for (let i = 0; i < data.length; i++) {
    const idx = result.findIndex(
      (r) => r.timestamp.substring(0, 16) === data[i].timestamp.substring(0, 16)
    );
    if (idx === -1) {
      result.push({ ...data[i], value: data[i].value * scalar });
    }
  }
  return result;
}

function safeMul(...data: AggregateValuePair[][]) {
  const result: AggregateValuePair[] = [];
  if (data.length) {
    for (let i = 0; i < data[0].length; i++) {
      const idx = result.findIndex(
        (r) =>
          r.timestamp.substring(0, 16) === data[0][i].timestamp.substring(0, 16)
      );
      if (idx === -1) {
        result.push({ ...data[0][i] });
      }
    }
    for (let i = 1; i < data.length; i++) {
      result.forEach((d, idx) => {
        const newVal = data[i].find(
          (r) => r.timestamp.substring(0, 16) === d.timestamp.substring(0, 16)
        );
        if (newVal) {
          result[idx].value *= newVal.value;
        }
      });
    }
  }
  return result;
}

function safeDiv(data1: AggregateValuePair[], data2: AggregateValuePair[]) {
  const result: AggregateValuePair[] = [];
  data1.forEach((d) => {
    const idx = result.findIndex(
      (r) => r.timestamp.substring(0, 16) === d.timestamp.substring(0, 16)
    );
    if (idx === -1) {
      result.push({ ...d });
    }
  });
  result.forEach((d, idx) => {
    const newVal = data2.find(
      (r) => r.timestamp.substring(0, 16) === d.timestamp.substring(0, 16)
    );
    if (newVal) {
      result[idx].value /= newVal.value;
    }
  });

  return result;
}

function safeClip(
  data1: AggregateValuePair[],
  upperLimit: number,
  lowerLimit: number
) {
  const result: AggregateValuePair[] = [];
  if (typeof upperLimit !== "number" || typeof lowerLimit !== "number")
    return data1;
  data1.forEach((d) => {
    result.push({
      ...d,
      value: Math.max(lowerLimit, Math.min(upperLimit, d.value)),
    });
  });

  return result;
}

function lastMinusFirst(data: AggregateValuePair[]): AggregateValuePair[] {
  if (data.length === 0) return [];
  if (data.length === 1) return data;
  return [
    {
      ...data[data.length - 1],
      value: data[data.length - 1].value - data[0].value,
    },
  ];
}

function twoValuePercentDiff(
  data: AggregateValuePair[],
  multiplier = 1
): AggregateValuePair[] {
  if (data.length !== 3) {
    return [];
  }
  const start = data[0];
  const first = data[1];
  const second = data[2];
  return [
    {
      ...start,
      value: (first.value - start.value) * multiplier,
    },
    {
      ...second,
      value: (second.value - first.value) * multiplier,
    },
  ];
}

function safeFirstLast(
  data: AggregateValuePair[],
  multiplier = 1
): AggregateValuePair[] {
  if (data.length === 0) return [];
  if (data.length === 1) return data;

  const first = data[0];
  const second = data[data.length - 1];
  return [
    { ...first, value: first.value * multiplier },
    { ...second, value: second.value * multiplier },
  ];
}

function last(data: AggregateValuePair[]): AggregateValuePair[] {
  if (data.length === 0) return [];
  if (data.length === 1) return data;
  return [data[data.length - 1]];
}
const now = new Date();
function onlyLastDay(data: AggregateValuePair[]): AggregateValuePair[] {
  if (data.length === 0) return [];
  return data.filter((d) => {
    const date = new Date(d.timestamp);

    return (
      date.getDate() === now.getDate() &&
      date.getMonth() === now.getMonth() &&
      date.getFullYear() === now.getFullYear()
    );
  });
}
function onlyLastWeek(data: AggregateValuePair[]): AggregateValuePair[] {
  if (data.length === 0) return [];
  const firstOfThisWeek = new Date(now.getTime());
  firstOfThisWeek.setDate(now.getDate() - ((now.getDay() + 6) % 7));
  firstOfThisWeek.setHours(0, 0, 0, 0);
  return data.filter((d) => {
    const date = new Date(d.timestamp);
    return date.getTime() >= firstOfThisWeek.getTime();
  });
}

function safeKWHDiff(data: AggregateValuePair[]): AggregateValuePair[] {
  if (data.length === 0) return [];
  return data
    .map((d, i) => ({
      ...d,
      value: i === 0 ? d.value : d.value - data[i - 1].value,
    }))
    .filter((d, i) => i !== 0);
}

export function applyLambda(series: DataSeriesResponse): AggregateValuePair[] {
  try {
    if (
      series.lambdaFn &&
      series.lambdaFn.length > 4 &&
      series.lambdaFn.indexOf("return") !== -1
    ) {
      const args = series.data
        .map((_, i) => `data${i + 1}`)
        .concat([
          "safeAdd",
          "safeMul",
          "safeDiv",
          "lastMinusFirst",
          "last",
          "twoValuePercentDiff",
          "safeFirstLast",
          "onlyLastDay",
          "onlyLastWeek",
          "safeDiff",
          "safeMulScalar",
          "safeClip",
          "safeKWHDiff",
        ]);
      // eslint-disable-next-line no-new-func
      const fn = new Function(...args, series.lambdaFn);
      const dataArgs = series.data.map((d) => d.response);
      return fn(
        ...dataArgs,
        safeAdd,
        safeMul,
        safeDiv,
        lastMinusFirst,
        last,
        twoValuePercentDiff,
        safeFirstLast,
        onlyLastDay,
        onlyLastWeek,
        safeDiff,
        safeMulScalar,
        safeClip,
        safeKWHDiff
      );
    }
    if (series.data && series.data.length && series.data[0].response)
      return series.data[0].response;
    return [];
  } catch (err: any) {
    /* notification.warn({
          type: "warning",
          duration: 5,
          message: err.toString(),
        });*/
    if (series.data.length) return series.data[0].response;
    return [];
  }
}
