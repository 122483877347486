import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { Graph } from "../../API/_generated";
import { STD_GUTTER } from "../../Util/constants";

const AdminGraphSmall: React.FC<{
  xs: number;
  graph: Graph;
  onEdit: (index: number) => void;
  onDelete: (index: number) => void;
  index: number;
}> = ({ onDelete, index, onEdit, graph, xs }) => {
  const { t } = useTranslation();

  const handleDelete = useCallback(() => {
    onDelete(index);
  }, [onDelete, index]);
  const handleEdit = useCallback(() => {
    onEdit(index);
  }, [onEdit, index]);

  return (
    <Col xs={xs}>
      <div className="card-container">
        <Row gutter={STD_GUTTER}>
          <Col xs={24}>{`${graph.type} ${graph.sub_type}`}</Col>
        </Row>
        <Row gutter={STD_GUTTER} justify="space-between">
          <Col>
            <Button onClick={handleDelete} type="link">
              {t("Delete Graph")}
            </Button>
          </Col>
          <Col>
            <Button type="ghost" onClick={handleEdit}>
              {t("Edit Graph")}
            </Button>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default AdminGraphSmall;
