import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { useDispatch } from "react-redux";
import { Card, Graph, RangeEnum } from "../../API/_generated";
import { id, sameCard } from "../../Util/utils";
import AdminGraph from "./AdminGraph";
import {
  AGGREGATE_DROPDOWN_OPTIONS,
  DEFAULT_DROPDOWN,
  GRANULARITY,
  RANGE_DROPDOWN_OPTIONS,
  STD_GUTTER,
} from "../../Util/constants";
import AdminGraphSmall from "./AdminGraphSmall";
import { AdminSiteContext, AllGraphTypes } from "../../Util/types";
import { updateSite } from "../../redux";
import Switch from "../Switches";
import AdminGraphPreview from "./AdminGraphPreview";

const newGraph: Graph = {
  type: "line",
  sub_type: "",
  series: [],
  config: JSON.stringify(AllGraphTypes.line.config),
};

const AdminCard: React.FC<{
  card: Card;
  onClose: () => void;
  maxIndex: number;
}> = ({ card, onClose, maxIndex }) => {
  const { t } = useTranslation();
  const { site, userId } = useContext(AdminSiteContext);
  const oldCards = useMemo(() => site.cards ?? [], [site]);
  const [formData, setFormData] = useState<Card>({ ...card });
  const [selectedGraphIdx, setSelectedGraphIdx] = useState<number>(-1);
  const dispatch = useDispatch();
  useEffect(() => {
    setFormData(card);
  }, [card]);
  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((fd) => ({ ...fd, [e.target.name]: e.target.value }));
  }, []);
  const handleChangeWidthSM = useCallback((value: number) => {
    setFormData((fd) => ({ ...fd, widthSM: value }));
  }, []);
  const handleChangeWidthLG = useCallback((value: number) => {
    setFormData((fd) => ({ ...fd, widthLG: value }));
  }, []);
  const decrIndex = useCallback(() => {
    setFormData((fd) => ({ ...fd, index: Math.max(fd.index - 1, 0) }));
  }, []);
  const incrIndex = useCallback(() => {
    setFormData((fd) => ({ ...fd, index: Math.min(fd.index + 1, maxIndex) }));
  }, [maxIndex]);
  const onSave = useCallback(() => {
    updateSite(dispatch, userId, id(site), {
      ...site,
      cards: oldCards.map((c) => (id(c) === id(card) ? formData : c)),
    });
  }, [dispatch, userId, site, oldCards, card, formData]);

  const amountGraphs = useMemo(
    () => Math.max(formData.graphs?.length ?? 1, 1),
    [formData.graphs]
  );
  const onChangeRange = useCallback((checked: CheckboxValueType[]) => {
    setFormData((fd) => ({
      ...fd,
      dropdown: checked.map((val) => ({
        start: val as RangeEnum,
        stop: "now()",
        label: `last ${val}`,
        standard: false,
        granularity: GRANULARITY[val as keyof typeof GRANULARITY],
      })),
    }));
  }, []);
  const onChangeAggregate = useCallback((val: RangeEnum, index: number) => {
    setFormData((fd) => ({
      ...fd,
      dropdown: fd.dropdown?.map((d, i) => ({
        ...d,
        granularity: i === index ? val : d.granularity,
      })),
    }));
  }, []);
  const onChangeStandard = useCallback((val: boolean, index: number) => {
    setFormData((fd) => ({
      ...fd,
      dropdown: fd.dropdown?.map((d, i) => {
        let { standard } = d;
        if (standard && val && i !== index) standard = false;
        if (i === index && val) standard = true;
        return {
          ...d,
          standard,
        };
      }),
    }));
  }, []);
  const onDecrIdxDropdown = useCallback((index: number) => {
    setFormData((fd) => {
      if (index === 0) return fd;
      const old = [...(fd.dropdown ?? [])];
      const oldSingle = old[index - 1];
      old.fill(old[index], index - 1, index);
      old.fill(oldSingle, index, index + 1);
      return {
        ...fd,
        dropdown: old,
      };
    });
  }, []);
  const handleDeleteGraph = useCallback((idx: number) => {
    setFormData((fd) => ({
      ...fd,
      graphs: fd.graphs ? fd.graphs.filter((g, i) => i !== idx) : [],
    }));
  }, []);
  const addGraph = useCallback(() => {
    setFormData((fd) => {
      setSelectedGraphIdx(fd.graphs?.length ?? 0);
      const newFd = {
        ...fd,
        graphs: fd.graphs ? fd.graphs.concat([newGraph]) : [newGraph],
      };
      updateSite(dispatch, userId, id(site), {
        ...site,
        cards: oldCards.map((c) => (id(c) === id(card) ? newFd : c)),
      });
      return newFd;
    });
  }, [card, dispatch, oldCards, site, userId]);
  const dataChanged = useMemo(
    () => !sameCard(card, formData),
    [card, formData]
  );
  const selectedGraph = useMemo(
    () =>
      formData.graphs && selectedGraphIdx !== -1
        ? formData.graphs[selectedGraphIdx]
        : undefined,
    [formData.graphs, selectedGraphIdx]
  );
  const closeGraph = useCallback(() => {
    setSelectedGraphIdx(-1);
  }, []);
  const dropdown = useMemo(
    () => formData.dropdown ?? [DEFAULT_DROPDOWN],
    [formData.dropdown]
  );
  const rangeDropdownOptions = useMemo(
    () =>
      RANGE_DROPDOWN_OPTIONS.map((r) => ({
        label: t(`last ${r.label}`),
        value: r.value,
      })),
    [t]
  ); // TODO dublicate card
  return (
    <Col sm={24} lg={24} className="card-col">
      <h2>{`${t("Card")}:  ${t(card.title)}`}</h2>
      {selectedGraph ? (
        <AdminGraph
          graph={selectedGraph}
          dropdown={dropdown}
          cardId={id(card)}
          handleClose={closeGraph}
        />
      ) : (
        <div className="card-container">
          <Form layout="vertical">
            <Row gutter={STD_GUTTER}>
              <Col xs={12}>
                <Form.Item label={t("Title")}>
                  <Input
                    name="title"
                    value={formData?.title}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col xs={4}>
                <Form.Item label={t("Width Mobile")}>
                  <Select
                    value={formData.widthSM}
                    id="widthSM"
                    key="widthSM"
                    onChange={handleChangeWidthSM}
                  >
                    <Select.Option value={8}>{t("Small")}</Select.Option>
                    <Select.Option value={16}>{t("Large")}</Select.Option>
                    <Select.Option value={12}>{t("Half")}</Select.Option>
                    <Select.Option value={24}>{t("Full")}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={4}>
                <Form.Item label={t("Width Desktop")}>
                  <Select
                    value={formData.widthLG}
                    id="widthLG"
                    key="widthLG"
                    onChange={handleChangeWidthLG}
                  >
                    <Select.Option value={8}>{t("Small")}</Select.Option>
                    <Select.Option value={16}>{t("Large")}</Select.Option>
                    <Select.Option value={12}>{t("Half")}</Select.Option>
                    <Select.Option value={24}>{t("Full")}</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={4}>
                <Form.Item label={`${t("Index")}: ${formData.index + 1}`}>
                  <Button onClick={decrIndex}>
                    <MinusOutlined />
                  </Button>
                  <Button onClick={incrIndex}>
                    <PlusOutlined />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            <Divider orientation="left">{t("Dropdown")}</Divider>
            <Row gutter={STD_GUTTER}>
              <Col xs={18}>
                <Form.Item label={t("Ranges")}>
                  <Checkbox.Group
                    options={rangeDropdownOptions}
                    onChange={onChangeRange}
                    defaultValue={formData.dropdown?.map((d) => d.start)}
                  />
                </Form.Item>
              </Col>
            </Row>
            {formData.dropdown?.map((dropDown, index) => (
              <Row gutter={STD_GUTTER} key={`aggregate-${dropDown.start}`}>
                <Col xs={12}>
                  <Form.Item
                    label={`${t("Aggregate Window")} ${t(dropDown.label)}`}
                  >
                    <Radio.Group
                      options={AGGREGATE_DROPDOWN_OPTIONS}
                      onChange={(checked) => {
                        onChangeAggregate(checked.target.value, index);
                      }}
                      defaultValue={dropDown.granularity}
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Form.Item label={t("Default")}>
                    <Switch
                      value={dropDown.standard}
                      switchHandler={(val) => {
                        onChangeStandard(val, index);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={6}>
                  <Button
                    onClick={() => {
                      onDecrIdxDropdown(index);
                    }}
                    disabled={index === 0}
                  >
                    <MinusOutlined />
                  </Button>
                </Col>
              </Row>
            ))}
          </Form>
          <Divider orientation="left">
            {t(amountGraphs === 1 ? "Graph" : "Graphs")}
          </Divider>
          <Row gutter={STD_GUTTER} justify="center">
            {formData.graphs?.map((graph, index) => (
              <AdminGraphSmall
                xs={Math.floor(24 / (amountGraphs + 1))}
                graph={graph}
                index={index}
                onEdit={setSelectedGraphIdx}
                onDelete={handleDeleteGraph}
                key={`graphs-${id(graph)}`}
              />
            ))}
            <Col xs={Math.floor(24 / (amountGraphs + 1))}>
              <Button onClick={addGraph}>{t("Add New Graph")}</Button>
            </Col>
          </Row>
          <Row gutter={STD_GUTTER}>
            <AdminGraphPreview card={formData} />
          </Row>
          <Row gutter={STD_GUTTER} justify="end">
            <Col>
              <Button type="primary" onClick={dataChanged ? onSave : onClose}>
                {dataChanged ? t("Save Card") : t("Close")}
              </Button>
            </Col>
          </Row>
        </div>
      )}
    </Col>
  );
};

export default AdminCard;
