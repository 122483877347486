import React, { useEffect, useState } from "react";
import { Button, Form, Input, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { clientLogin } from "../redux";
import { Credentials } from "../API/_generated";
import { RootState } from "../redux/store";

const logo = require("../Assets/logo2.png");

const Login: React.FC = () => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  // const [demoLoading, setDemoLoading] = useState(false);
  const { t } = useTranslation();
  const { me, isAdmin } = useSelector<RootState, RootState["userReducer"]>(
    (state) => state.userReducer
  );
  useEffect(() => {
    if (me) {
      if (isAdmin) history.replace("/admin");
      else history.replace("/");
    }
  }, [history, me, isAdmin]);
  const clientLoginFnc = async (values: Credentials) => {
    setLoading(true);
    await clientLogin(dispatch, values);
    setLoading(false);
  };

  return (
    <div className="temp-login-main">
      <div className="login-main">
        <img src={logo} alt="" />
        <p>{t("Sign in to your account to continue")}</p>
        <Form
          layout="vertical"
          onFinish={clientLoginFnc}
          className="login-form"
        >
          <Typography.Title level={2}>{t("Welcome!")}</Typography.Title>

          <Form.Item name="email" label={t("E-Mail")}>
            <Input />
          </Form.Item>
          <Form.Item name="password" label={t("Password")}>
            <Input type="password" />
          </Form.Item>
          <div className="remember-check">
            <span />
            {/* <Checkbox>Remember Me</Checkbox>
            <Link to="/login">{t("Forgot Password?")}</Link>*/}
          </div>
          <Form.Item>
            <Button type="primary" loading={loading} htmlType="submit">
              {t("Login")}
            </Button>
          </Form.Item>
          {/*
          <div>
            <Divider>OR</Divider>
            <Button
              type="primary"
              loading={demoLoading}
              onClick={() => {
                clientLoginFnc(
                  {
                    email: "demoaccount4@gmail.com",
                    password: "12345678",
                  },
                  true
                );
              }}
            >
              Login to Demo Dashboard - Hotel
            </Button>
          </div>

          <div>
            <Divider>OR</Divider>
            <Button
              type="primary"
              loading={demoLoading}
              onClick={() => {
                clientLoginFnc(
                  {
                    email: "demoaccount7@gmail.com",
                    password: "12345678",
                  },
                  true
                );
              }}
            >
              Login to Demo Dashboard - Industry
            </Button>
          </div>
          <div className="signup">
            Don’t have an account?{" "}
            <Link to="/signup">
              <span>Sign up</span>
            </Link>
          </div>*/}
        </Form>
      </div>
    </div>
  );
};

export default Login;
